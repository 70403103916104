import { useState } from 'react';
import SearchBar from './ui/blocks/SearchBar';
import LefCenter from './ui/partials/LeftCenter';
import Right from './ui/partials/Right';

const Outlets: React.FC = () => {
  const [selectedOutletId, setSelectedOutletId] = useState<string>();
  const [searchText, setSearchText] = useState<string>();
  const [active, setActive] = useState<boolean | null>(null);

  const onSearchChange = (newSearchText: string) => {
    setSearchText(newSearchText);

    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    const newSearchTextAlphanumeric = newSearchText
      .split('')
      .filter(char => chars.includes(char))
      .join('');

    if (newSearchTextAlphanumeric.length === 20) {
      setSelectedOutletId(newSearchTextAlphanumeric);
    }
  };

  return (
    <div className="w-full h-full flex justify-center">
      <SearchBar onSearchChange={onSearchChange} onActiveChange={setActive} />
      <LefCenter
        searchText={searchText}
        active={active}
        setSelectedOutletId={setSelectedOutletId}
      />
      {selectedOutletId && (
        <Right selectedOutletId={selectedOutletId} setSelectedOutletId={setSelectedOutletId} />
      )}
    </div>
  );
};

export default Outlets;
