import { ClusterType } from '../../../../common/types/Cluster';

interface ClusterProps {
  cluster: ClusterType;
}

const Cluster: React.FC<ClusterProps> = ({ cluster }) => (
  <div className="font-thin text-center flex justify-between my-4 uppercase">
    <h3>{cluster.name}</h3>
    <span className="font-bold">&#183;</span>
    <h3>{cluster.territory.name}</h3>
    <span className="font-bold">&#183;</span>
    <h3>{cluster.region.name}</h3>
  </div>
);

export default Cluster;
