import { User } from 'firebase/auth';
import { createContext } from 'react';
import { RoleType } from '../types';

interface AuthContextProps {
  id: string | null | undefined;
  user: User | null | undefined;
  roles: RoleType[] | null | undefined;
  loading: boolean;
  error: Error | undefined;
  typesenseKey: string | null | undefined;
}

const AuthContext = createContext<AuthContextProps>({
  id: undefined,
  user: undefined,
  roles: [],
  loading: true,
  error: undefined,
  typesenseKey: undefined,
});

export default AuthContext;
