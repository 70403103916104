import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import router from './routes';

import './index.css';
import configureFilterStore from './common/store/activeFilter';
import 'react-toastify/dist/ReactToastify.css';

configureFilterStore();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <ToastContainer position="top-center" limit={3} />
  </React.StrictMode>
);
