import OutletsList from '../blocks/OutletsList';

interface LeftCenterProps {
  active: boolean | null;
  searchText?: string;
  setSelectedOutletId: (id: string) => void;
}

const LefCenter: React.FC<LeftCenterProps> = ({ active, searchText, setSelectedOutletId }) => (
  <div className="w-1/2 flex flex-col items-center">
    <OutletsList
      searchText={searchText}
      active={active}
      setSelectedOutletId={setSelectedOutletId}
    />
  </div>
);

export default LefCenter;
