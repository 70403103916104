import { useState } from 'react';
import { RoleType } from '../../common/types';
import SearchBar from './ui/blocks/SearchBar';
import LefCenter from './ui/partials/LeftCenter';
import Right from './ui/partials/Right';

const Users: React.FC = () => {
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [searchText, setSearchText] = useState<string>();
  const [roles, setRoles] = useState<RoleType[]>([]);

  const onSearchChange = (newSearchText: string) => {
    setSearchText(newSearchText);
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    const newSearchTextAlphanumeric = newSearchText
      .split('')
      .filter(char => chars.includes(char))
      .join('');

    if (newSearchTextAlphanumeric.length === 20) {
      setSelectedUserId(newSearchTextAlphanumeric);
    }
  };

  return (
    <div className="w-full h-full flex justify-center">
      <SearchBar onSearchChange={onSearchChange} onRolesChange={setRoles} />
      <LefCenter searchText={searchText} roles={roles} setSelectedUserId={setSelectedUserId} />
      {selectedUserId && (
        <Right selectedUserId={selectedUserId} setSelectedUserId={setSelectedUserId} />
      )}
    </div>
  );
};

export default Users;
