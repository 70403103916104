import { doc, FirestoreError, onSnapshot } from 'firebase/firestore';
import { useEffect, useMemo } from 'react';
import { OutletChanges, OutletType } from '../types';
import { firestore, docToJSON } from '../utils/firebase';
import { useLoadingValue } from './utils';
import updateOutlet from '../db/outlets';

const useOutlet = (outletId: string) => {
  const { error, loading, setLoading, setError, setValue, value } = useLoadingValue<
    OutletType | null,
    FirestoreError
  >();

  useEffect(() => {
    setLoading(true);
    const ref = doc(firestore, 'outlets', outletId);
    const unsubscribe = onSnapshot(
      ref,
      snapshot => {
        setValue(docToJSON(snapshot));
        setLoading(false);
      },
      setError
    );

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [outletId]);

  const update = async (changes: OutletChanges) => {
    if (!outletId) return;
    try {
      setLoading(true);
      await updateOutlet(outletId, changes);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err instanceof FirestoreError) setError(err);
    }
  };

  return useMemo(
    () => ({
      outlet: value,
      error,
      loading,
      update,
    }),
    [value, loading, error]
  );
};

export default useOutlet;
