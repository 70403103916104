import { Auth, AuthError, signInWithPopup, browserPopupRedirectResolver } from 'firebase/auth';
import { useMemo, useState } from 'react';
import { googleAuthProvider } from '../../utils/firebase';

export type GoogleActionHook = [() => Promise<void>, boolean, AuthError | undefined];

/**
 *
 * @param auth Firebase auth instance
 * @returns Array containing sign in with google function, loading state and error
 */
export const useSignInWithGoogle = (auth: Auth): GoogleActionHook => {
  const [error, setError] = useState<AuthError>();
  const [loading, setLoading] = useState<boolean>(false);

  const signInWithGoogle = async () => {
    try {
      setLoading(true);
      await signInWithPopup(auth, googleAuthProvider, browserPopupRedirectResolver);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err as AuthError);
    }
  };

  const resArray: GoogleActionHook = [signInWithGoogle, loading, error];
  return useMemo(() => resArray, resArray);
};
