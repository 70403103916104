import logoSmall from '../../../../common/assets/images/loadingLogo.png';

interface LogoProps {
  open: boolean;
}

const Logo: React.FC<LogoProps> = ({ open }) => {
  if (open) {
    return <img alt="logo" src={logoSmall} className="h-10 my-8" />;
  }
  return <img alt="logo" src={logoSmall} className="h-10 my-8" />;
};

export default Logo;
