import { getDocs, Query } from 'firebase/firestore';

const getAnalyticsData = async (query: Query, queryLastPeriod: Query) => {
  const data: any[] = [];
  const snap = await getDocs(query);
  snap.forEach(doc => {
    const docData = doc.data();
    // only if region is west,east,lagos,north
    if (
      docData?.cluster?.region?.id === 'ABy89onIhjw83RGqYYzO' ||
      docData?.cluster?.region?.id === 'btrX2khDWIegvT3XCICR' ||
      docData?.cluster?.region?.id === 'tueVqSWqCmx2wt7Hkcpm' ||
      docData?.cluster?.region?.id === 'y5lG5IpWSORvj18oaOOI'
    ) {
      data.push({
        ...docData,
        id: doc.id,
      });
    }
  });
  const docSnapFSALastPeriod = await getDocs(queryLastPeriod);
  docSnapFSALastPeriod.forEach(doc => {
    const docData = doc.data();
    // only if region is west,east,lagos,north
    if (
      docData.cluster.region.id === 'ABy89onIhjw83RGqYYzO' ||
      docData.cluster.region.id === 'btrX2khDWIegvT3XCICR' ||
      docData.cluster.region.id === 'tueVqSWqCmx2wt7Hkcpm' ||
      docData.cluster.region.id === 'y5lG5IpWSORvj18oaOOI'
    ) {
      data.push({
        ...doc.data(),
        id: doc.id,
      });
    }
  });
  return data;
};

export default getAnalyticsData;
