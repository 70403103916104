import { useState } from 'react';
import { toast } from 'react-toastify';
import { ClusterTargetType } from '../../../../common/types/Target';
import { lockTargets, unlockTarget } from '../../../../common/utils/firebase';
import Card from '../../../../components/Card';
import Switch from '../../../../components/Switch';

interface TargetItemProps {
  target: ClusterTargetType;
}

const TargetItem: React.FC<TargetItemProps> = ({ target }) => {
  const [locked, setLocked] = useState<boolean>(target.locked);

  const toggleEnabled = () => {
    if (locked) {
      unlockTarget({
        clusterIds: [target.cluster.id],
        month: target.month,
        year: target.year,
      }).catch(err => {
        toast.error(err?.message);
      });
    } else {
      lockTargets({
        clusterIds: [target.cluster.id],
        month: target.month,
        year: target.year,
      }).catch(err => {
        toast.error(err?.message);
      });
    }
    setLocked(enabled => !enabled);
  };

  return (
    <Card className="md:w-full border-none shadow-none flex justify-between flex-wrap">
      <div className="flex justify-between flex-wrap flex-3/4">
        <h1 className="flex-1/2 font-semibold font-outfit text-2xl">{target.cluster?.name}</h1>
        <h2 className="flex-1/2 text-right font-medium text-lg uppercase">
          <span className="font-thin">SOP: </span>
          {target.sop?.name}
        </h2>
        <h3 className="flex-1/2 font-normal">{target.cluster?.territory.name}</h3>
        <h3 className="flex-1/2 font-normal text-right">{target.cluster?.region.name}</h3>
      </div>
      <div className="flex flex-1/4 items-center justify-center">
        <Switch onClick={toggleEnabled} isChecked={locked} />
      </div>
    </Card>
  );
};

export default TargetItem;
