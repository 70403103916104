import { Navigate, useLocation } from 'react-router-dom';
import { useAuth, useCheckAccess } from '../../common/hooks/auth';
import { RoleType } from '../../common/types';

interface ProtectedRouteProps {
  roles?: RoleType[];
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ roles, children }) => {
  const { user, loading, roles: userRoles } = useAuth();
  const location = useLocation();
  const checkAccess = useCheckAccess();
  if (!loading && user === null) {
    return <Navigate to="/enter" state={{ from: location }} />;
  }
  if (
    !loading &&
    userRoles &&
    userRoles.length > 0 &&
    roles &&
    roles.length &&
    !checkAccess(roles)
  ) {
    return <Navigate to="/home" state={{ from: location }} />;
  }
  return children;
};

export default ProtectedRoute;
