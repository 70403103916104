import SiteDetails from '../blocks/SiteDetails';

interface RightProps {
  selectedSiteId: string;
  setSelectedSiteId: (id: string | undefined) => void;
}

const Right: React.FC<RightProps> = ({ selectedSiteId, setSelectedSiteId }) => (
  <div className="w-1/2 px-8 flex items-center">
    <SiteDetails selectedSiteId={selectedSiteId} setSelectedSiteId={setSelectedSiteId} />
  </div>
);

export default Right;
