import { FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import useSignOut from '../../../../common/hooks/auth/useSignOut';
import Button from '../../../Button';

type Props = {
  open: boolean;
};

const SignOut: React.FC<Props> = ({ open }) => {
  const navigate = useNavigate();
  const signOut = useSignOut();

  const onClickHandler = async () => {
    await signOut();
    navigate('/', { replace: true });
  };

  return (
    <Button
      onClick={onClickHandler}
      className={`flex rounded-md cursor-pointer items-center gap-x-4 mt-auto mb-8 ${
        open ? 'w-40' : ''
      }`}
    >
      <FaSignOutAlt />
      <span className={`${!open && 'hidden'} origin-left duration-200`}>Sign out</span>
    </Button>
  );
};

export default SignOut;
