import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import useTargets from '../../../../common/hooks/useTargets';
import { ClusterTargetType } from '../../../../common/types/Target';
import { List } from '../../../../components/List';
import TargetItem from '../components/TargetItem';
import Switch from '../../../../components/Switch';
import { lockTargets, unlockTarget } from '../../../../common/utils/firebase';
import useClusters from '../../../../common/hooks/useClusters';
import { ClusterType } from '../../../../common/types/Cluster';

interface TargetsListProps {
  month: number;
  year: number;
  targetTypes?: string[];
}

interface SelectOption {
  label: string;
  value: string;
  default: boolean;
}

const TargetsList: React.FC<TargetsListProps> = ({ month, year, targetTypes }) => {
  const [selectedClusters, setSelectedClusters] = useState<ClusterType[]>();
  const [regionOptions, setRegionOptions] = useState<SelectOption[]>([]);
  const [territoriesOptions, setTerritoriesOptions] = useState<SelectOption[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<SelectOption[]>([]);
  const [selectedTerritories, setSelectedTerritories] = useState<SelectOption[]>([]);
  const [toggleAllChecked, setToggleAllChecked] = useState<boolean>(false);

  const regionIds = useMemo(() => selectedRegions.map(r => r.value), [selectedRegions]);
  const territoryIds = useMemo(() => selectedTerritories.map(r => r.value), [selectedTerritories]);

  const { clusters } = useClusters();

  const { error, hasMore, load, loading, targets, reset } = useTargets({
    month,
    year,
    targetTypes,
    regionIds,
    territoryIds,
  });

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  // INITIALIZE SELECT OPTIONS
  useEffect(() => {
    // set all territories and regions options from clusters
    if (selectedRegions.length === 0 && selectedTerritories.length === 0 && clusters) {
      setSelectedClusters(clusters);
    }

    const territories: SelectOption[] = clusters
      ? clusters
          .map(cluster => {
            const clusterTarget = cluster as ClusterType;
            return {
              label: clusterTarget.territory.name,
              value: clusterTarget.territory.id,
              default: false,
            };
          })
          .filter(
            (territory, index, self) => index === self.findIndex(t => t.value === territory.value)
          )
      : [];

    // get all unique regions from territories
    const regions: SelectOption[] = clusters
      ? clusters
          .map(cluster => {
            const clusterTarget = cluster as ClusterType;
            return {
              label: clusterTarget.region.name,
              value: clusterTarget.region.id,
              default: false,
            };
          })
          .filter((region, index, self) => index === self.findIndex(r => r.value === region.value))
      : [];

    setTerritoriesOptions(territories);
    setRegionOptions(regions);
  }, [clusters]);

  useEffect(() => {
    // set selectedTerritories based on selectedRegions
    if (selectedRegions.length > 0) {
      const territories: SelectOption[] = clusters
        ? clusters
            .filter(cluster => selectedRegions.some(region => region.value === cluster.region.id))
            .map(cluster => {
              const clusterTarget = cluster as ClusterType;
              return {
                label: clusterTarget.territory.name,
                value: clusterTarget.territory.id,
                default: false,
              };
            })
            .filter(
              (territory, index, self) => index === self.findIndex(t => t.value === territory.value)
            )
        : [];
      setTerritoriesOptions(territories);
    } else {
      // set selectedTerritories to all territories
      const territories: SelectOption[] = clusters
        ? clusters
            .map(cluster => {
              const clusterTarget = cluster as ClusterType;
              return {
                label: clusterTarget.territory.name,
                value: clusterTarget.territory.id,
                default: false,
              };
            })
            .filter(
              (territory, index, self) => index === self.findIndex(t => t.value === territory.value)
            )
        : [];
      setTerritoriesOptions(territories);
    }
  }, [selectedRegions]);

  useEffect(() => {
    // if targets change, set selectedClusters
    if (targets) {
      const newSelectedclusters = targets.map(target => target.cluster);
      setSelectedClusters(newSelectedclusters);
    }
  }, [targets]);

  function toggleAll() {
    if (toggleAllChecked) {
      for (const target of targets as ClusterTargetType[]) {
        unlockTarget({
          clusterIds: [target.cluster.id],
          month: target.month,
          year: target.year,
        })
          .then(() => {
            setToggleAllChecked(!toggleAllChecked);
            reset();
          })
          .catch(err => {
            toast.error(err?.message);
          });
      }
    } else {
      for (const target of targets as ClusterTargetType[]) {
        lockTargets({
          clusterIds: [target.cluster.id],
          month: target.month,
          year: target.year,
        })
          .then(() => {
            setToggleAllChecked(!toggleAllChecked);
            reset();
          })
          .catch(err => {
            toast.error(err?.message);
          });
      }
    }
  }

  return (
    <div className="flex flex-col w-full pt-24">
      <Select
        id="selectRegions"
        defaultValue={regionOptions.filter(region => region.default)}
        options={regionOptions}
        isMulti
        placeholder="Select Regions..."
        onChange={selectedOptions => {
          const newSelectedRegions = selectedOptions as SelectOption[];
          setSelectedRegions(newSelectedRegions);
        }}
        className="mb-2"
      />
      <Select
        defaultValue={territoriesOptions.filter(territory => territory.default)}
        options={territoriesOptions}
        isMulti
        placeholder="Select Territories..."
        onChange={selectedOptions => {
          const newSelectedTerritories = selectedOptions as SelectOption[];
          setSelectedTerritories(newSelectedTerritories);
        }}
        className="mb-2"
      />
      {selectedClusters && (
        <div className="flex justify-between mb-2">
          <p className="font-semibold font-nunito text-xl">Clusters ({selectedClusters.length})</p>
          <div>
            <p className="font-semibold font-nunito text-xl">Set all selected clusters</p>
            <Switch
              onClick={() => {
                toggleAll();
              }}
              isChecked={toggleAllChecked}
            />
          </div>
        </div>
      )}
      <List
        load={load}
        hasMore={hasMore}
        loading={loading}
        className="overflow-y-scroll no-scrollbar w-full md:w-full"
      >
        {targets &&
          targets.map(target => (
            <TargetItem key={target.id} target={target as ClusterTargetType} />
          ))}
      </List>
    </div>
  );
};

export default TargetsList;
