import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../common/hooks/auth';
import Button from '../../components/Button';

const Denied: React.FC = () => {
  const { roles } = useAuth();
  const navigate = useNavigate();

  const text = useMemo(() => {
    if (!roles || roles.length === 0) {
      return (
        <p className="text-center mb-6">
          Only registered users have access to the App. Please login with another account or contact{' '}
          <span className="font-thin">help@mcomtech.ch</span> for help.
        </p>
      );
    }
    return (
      <p className="text-center mb-6">
        You do not have access to this page. Please contact your administrator.
      </p>
    );
  }, [roles]);

  const buttonText = useMemo(() => {
    if (!roles || roles.length === 0) {
      return 'Login';
    }
    return 'Go to Home';
  }, [roles]);

  const buttonLink = useMemo(() => {
    if (!roles || roles.length === 0) {
      return '/enter';
    }
    return '/home';
  }, [roles]);

  return (
    <div className="flex justify-center items-center flex-col py-16 w-full">
      {roles && roles.length === 0 ? (
        <>
          <h1 className="text-xl text-secondary font-bold">Access denied!</h1>
          {text}
          <Button onClick={() => navigate(buttonLink)}>{buttonText}</Button>
        </>
      ) : (
        <>
          <h1 className="text-xl text-secondary font-bold">Access denied!</h1>
          {text}
          <Button onClick={() => navigate(buttonLink)}>{buttonText}</Button>
        </>
      )}
    </div>
  );
};

export default Denied;
