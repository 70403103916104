import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useUsers } from '../../../../common/hooks';
import { RoleType } from '../../../../common/types';
import { List } from '../../../../components/List';
import UserItem from '../components/UserItem';

interface UsersListProps {
  roles: RoleType[];
  searchText?: string;
  setSelectedUserId: (id: string) => void;
}

const UsersList: React.FC<UsersListProps> = ({ searchText, setSelectedUserId, roles }) => {
  const { error, hasMore, load, loading, users } = useUsers({
    searchText,
    roles,
  });

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <List
      load={load}
      hasMore={hasMore}
      loading={loading}
      className="overflow-y-scroll no-scrollbar px-4 pt-48 w-full md:w-full"
    >
      {users &&
        users.map(user => <UserItem key={user.id} user={user} onClick={setSelectedUserId} />)}
    </List>
  );
};

export default UsersList;
