import GroupsList from './ui/blocks/GroupsList';

const Groups: React.FC = () => {
  console.log('Groups');

  return (
    <div className="w-full h-full flex justify-center">
      <GroupsList />
    </div>
  );
};

export default Groups;
