import dayjs from 'dayjs';
import { AnalyticsType, FilterOptions, PerformanceData } from '../../../types/Analytics';
import {
  calculatePercentageChange,
  checkIfDataIsInFilter,
  convertTimestampToDate,
  getCompareId,
} from '../helperFunctions';

type CementData = {
  id: string;
  name: string;
  fulfilled: {
    classic: number;
    supaset: number;
    total: number;
  };
  target: number;
};

const computeCement = (analyticsData: AnalyticsType[], filter: FilterOptions) => {
  if (analyticsData.length > 0) {
    const cementDataPreviousPeriod: CementData[] = [];
    const cementDataCurrentPeriod: CementData[] = [];
    const cementHeaderDataPreviousPeriod: any = [];
    const cementHeaderDataCurrentPeriod: any = [];
    let targetCurrentPeriod: number = 0;
    let targetPreviousPeriod: number = 0;
    let actualCurrentPeriod: number = 0;
    let actualPreviousPeriod: number = 0;

    const currentPeriodStartDate = filter.dateRange1.startDate;
    const currentPeriodEndDate = dayjs(filter.dateRange1.endDate).add(1, 'day').toDate();
    const previousPeriodStartDate = filter.dateRange2.startDate;
    const previousPeriodEndDate = dayjs(filter.dateRange2.endDate).add(1, 'day').toDate();

    const fsaAdded: string[] = [];
    const fsaAddedPrevious: string[] = [];

    const clusterAdded: string[] = [];
    const clusterAddedPrevious: string[] = [];

    analyticsData.forEach(data => {
      const isInFilter = checkIfDataIsInFilter(data, filter);

      if (isInFilter) {
        const date = convertTimestampToDate(data.date);
        const compareId = getCompareId(data, filter);

        if (date >= currentPeriodStartDate && date <= currentPeriodEndDate) {
          actualCurrentPeriod += data.volumeFulfilled.chatbot.classic
            ? data.volumeFulfilled.chatbot.classic
            : 0;
          actualCurrentPeriod += data.volumeFulfilled.chatbot.supaset
            ? data.volumeFulfilled.chatbot.supaset
            : 0;
          actualCurrentPeriod += data.volumeFulfilled.app.classic
            ? data.volumeFulfilled.app.classic
            : 0;
          actualCurrentPeriod += data.volumeFulfilled.app.supaset
            ? data.volumeFulfilled.app.supaset
            : 0;

          if (
            filter.groupBy.toLowerCase() !== 'fst' &&
            !clusterAdded.includes(data.cluster.id + dayjs(data.date.toDate()).format('MM'))
          ) {
            targetCurrentPeriod += data.target.cementTarget ? data.target.cementTarget : 0;
          } else if (
            filter.groupBy.toLowerCase() === 'fst' &&
            !fsaAdded.includes(data.fsa.id + dayjs(data.date.toDate()).format('MM'))
          ) {
            targetCurrentPeriod += data.target.cementTarget ? data.target.cementTarget : 0;
          }

          // if cementData is not empty, check if id exists
          let idExists = false;
          for (const element of cementDataCurrentPeriod) {
            if (element.id === compareId) {
              // if id exists, update entry
              element.fulfilled.classic += data.volumeFulfilled.chatbot.classic
                ? data.volumeFulfilled.chatbot.classic
                : 0;
              element.fulfilled.supaset += data.volumeFulfilled.chatbot.supaset
                ? data.volumeFulfilled.chatbot.supaset
                : 0;
              element.fulfilled.classic += data.volumeFulfilled.app.classic
                ? data.volumeFulfilled.app.classic
                : 0;
              element.fulfilled.supaset += data.volumeFulfilled.app.supaset
                ? data.volumeFulfilled.app.supaset
                : 0;
              element.fulfilled.total +=
                (data.volumeFulfilled.chatbot.classic ? data.volumeFulfilled.chatbot.classic : 0) +
                (data.volumeFulfilled.chatbot.supaset ? data.volumeFulfilled.chatbot.supaset : 0) +
                (data.volumeFulfilled.app.classic ? data.volumeFulfilled.app.classic : 0) +
                (data.volumeFulfilled.app.supaset ? data.volumeFulfilled.app.supaset : 0);

              if (
                filter.groupBy.toLowerCase() !== 'fst' &&
                !clusterAdded.includes(data.cluster.id + dayjs(data.date.toDate()).format('MM'))
              ) {
                element.target += data.target.cementTarget ? data.target.cementTarget : 0;
                clusterAdded.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
              } else if (
                filter.groupBy.toLowerCase() === 'fst' &&
                !fsaAdded.includes(data.fsa.id + dayjs(data.date.toDate()).format('MM'))
              ) {
                element.target += data.target.cementTarget ? data.target.cementTarget : 0;
                fsaAdded.push(data.fsa.id + dayjs(data.date.toDate()).format('MM'));
              }
              idExists = true;
            }
          }
          if (!idExists) {
            let classic = 0;
            let supaset = 0;
            classic += data.volumeFulfilled.chatbot.classic
              ? data.volumeFulfilled.chatbot.classic
              : 0;
            classic += data.volumeFulfilled.app.classic ? data.volumeFulfilled.app.classic : 0;
            supaset += data.volumeFulfilled.chatbot.supaset
              ? data.volumeFulfilled.chatbot.supaset
              : 0;
            supaset += data.volumeFulfilled.app.supaset ? data.volumeFulfilled.app.supaset : 0;

            const fulfilled = {
              classic,
              supaset,
              total: classic + supaset,
            };

            // if id does not exist, push new entry
            if (filter.groupBy.toLowerCase() === 'region') {
              cementDataCurrentPeriod.push({
                id: data.cluster.region.id,
                name: data.cluster.region.name,
                fulfilled,
                target: data.target.cementTarget ? data.target.cementTarget : 0,
              });
              clusterAdded.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
            }
            if (filter.groupBy.toLowerCase() === 'territory') {
              cementDataCurrentPeriod.push({
                id: data.cluster.territory.id,
                name: data.cluster.territory.name,
                fulfilled,
                target: data.target.cementTarget ? data.target.cementTarget : 0,
              });
              clusterAdded.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
            }
            if (filter.groupBy.toLowerCase() === 'cluster') {
              cementDataCurrentPeriod.push({
                id: data.cluster.id,
                name: data.cluster.name,
                fulfilled,
                target: data.target.cementTarget ? data.target.cementTarget : 0,
              });
              clusterAdded.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
            }
            if (filter.groupBy.toLowerCase() === 'fst') {
              cementDataCurrentPeriod.push({
                id: data.fsa.id,
                name: data.fsa.name,
                fulfilled,
                target: data.target.cementTarget,
              });
              fsaAdded.push(data.fsa.id + dayjs(data.date.toDate()).format('MM'));
            }
          }
        } else if (date >= previousPeriodStartDate && date <= previousPeriodEndDate) {
          // compute header data
          actualPreviousPeriod += data.volumeFulfilled.chatbot.classic
            ? data.volumeFulfilled.chatbot.classic
            : 0;
          actualPreviousPeriod += data.volumeFulfilled.chatbot.supaset
            ? data.volumeFulfilled.chatbot.supaset
            : 0;
          actualPreviousPeriod += data.volumeFulfilled.app.classic
            ? data.volumeFulfilled.app.classic
            : 0;
          actualPreviousPeriod += data.volumeFulfilled.app.supaset
            ? data.volumeFulfilled.app.supaset
            : 0;

          if (
            filter.groupBy.toLowerCase() !== 'fst' &&
            !clusterAddedPrevious.includes(data.cluster.id + dayjs(data.date.toDate()).format('MM'))
          ) {
            targetPreviousPeriod += data.target.cementTarget ? data.target.cementTarget : 0;
          } else if (
            filter.groupBy.toLowerCase() === 'fst' &&
            !fsaAddedPrevious.includes(data.fsa.id + dayjs(data.date.toDate()).format('MM'))
          ) {
            targetPreviousPeriod += data.target.cementTarget ? data.target.cementTarget : 0;
          }

          // if cementData is not empty, check if id exists
          let idExists = false;
          for (const element of cementDataPreviousPeriod) {
            if (element.id === compareId) {
              // if id exists, update entry
              element.fulfilled.classic += data.volumeFulfilled.chatbot.classic
                ? data.volumeFulfilled.chatbot.classic
                : 0;
              element.fulfilled.supaset += data.volumeFulfilled.chatbot.supaset
                ? data.volumeFulfilled.chatbot.supaset
                : 0;
              element.fulfilled.classic += data.volumeFulfilled.app.classic
                ? data.volumeFulfilled.app.classic
                : 0;
              element.fulfilled.supaset += data.volumeFulfilled.app.supaset
                ? data.volumeFulfilled.app.supaset
                : 0;
              element.fulfilled.total +=
                (data.volumeFulfilled.chatbot.classic ? data.volumeFulfilled.chatbot.classic : 0) +
                (data.volumeFulfilled.chatbot.supaset ? data.volumeFulfilled.chatbot.supaset : 0) +
                (data.volumeFulfilled.app.classic ? data.volumeFulfilled.app.classic : 0) +
                (data.volumeFulfilled.app.supaset ? data.volumeFulfilled.app.supaset : 0);

              if (
                filter.groupBy.toLowerCase() !== 'fst' &&
                !clusterAddedPrevious.includes(
                  data.cluster.id + dayjs(data.date.toDate()).format('MM')
                )
              ) {
                element.target += data.target.cementTarget ? data.target.cementTarget : 0;
                clusterAddedPrevious.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
              } else if (
                filter.groupBy.toLowerCase() === 'fst' &&
                !fsaAddedPrevious.includes(data.fsa.id + dayjs(data.date.toDate()).format('MM'))
              ) {
                element.target += data.target.cementTarget ? data.target.cementTarget : 0;
                fsaAddedPrevious.push(data.fsa.id + dayjs(data.date.toDate()).format('MM'));
              }
              idExists = true;
            }
          }
          if (!idExists) {
            // if id does not exist, push new entry
            let classic = 0;
            let supaset = 0;
            classic += data.volumeFulfilled.chatbot.classic
              ? data.volumeFulfilled.chatbot.classic
              : 0;
            classic += data.volumeFulfilled.app.classic ? data.volumeFulfilled.app.classic : 0;
            supaset += data.volumeFulfilled.chatbot.supaset
              ? data.volumeFulfilled.chatbot.supaset
              : 0;
            supaset += data.volumeFulfilled.app.supaset ? data.volumeFulfilled.app.supaset : 0;

            const fulfilled = {
              classic,
              supaset,
              total: classic + supaset,
            };

            if (filter.groupBy.toLowerCase() === 'region') {
              cementDataPreviousPeriod.push({
                id: data.cluster.region.id,
                name: data.cluster.region.name,
                fulfilled,
                target: data.target.cementTarget,
              });
              clusterAddedPrevious.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
            }
            if (filter.groupBy.toLowerCase() === 'territory') {
              cementDataPreviousPeriod.push({
                id: data.cluster.territory.id,
                name: data.cluster.territory.name,
                fulfilled,
                target: data.target.cementTarget,
              });
              clusterAddedPrevious.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
            }
            if (filter.groupBy.toLowerCase() === 'cluster') {
              cementDataPreviousPeriod.push({
                id: data.cluster.id,
                name: data.cluster.name,
                fulfilled,
                target: data.target.cementTarget,
              });
              clusterAddedPrevious.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
            }
            if (filter.groupBy.toLowerCase() === 'fst') {
              cementDataPreviousPeriod.push({
                id: data.fsa.id,
                name: data.fsa.name,
                fulfilled,
                target: data.target.cementTarget,
              });
              fsaAddedPrevious.push(data.fsa.id + dayjs(data.date.toDate()).format('MM'));
            }
          }
        }
      }
    });

    cementHeaderDataCurrentPeriod.push(
      {
        name: 'Target',
        value: Math.round(targetCurrentPeriod),
      },
      {
        name: 'Actual',
        value: Math.round(actualCurrentPeriod),
      },
      {
        name: 'ACH',
        value: `${Math.round((actualCurrentPeriod / targetCurrentPeriod) * 100 * 100) / 100}%`,
      }
    );

    cementHeaderDataPreviousPeriod.push(
      {
        name: 'Target',
        value: Math.round(targetPreviousPeriod),
      },
      {
        name: 'Actual',
        value: Math.round(actualPreviousPeriod),
      },
      {
        name: 'ACH',
        value: `${Math.round((actualPreviousPeriod / targetPreviousPeriod) * 100 * 100) / 100}%`,
      }
    );

    const performance: PerformanceData[] = [
      {
        name: 'Target',
        value: calculatePercentageChange(targetCurrentPeriod, targetPreviousPeriod),
      },
      {
        name: 'Actual',
        value: calculatePercentageChange(actualCurrentPeriod, actualPreviousPeriod),
      },
    ];

    for (let i = 0; i < cementDataCurrentPeriod.length; i++) {
      cementDataCurrentPeriod[i].fulfilled.classic = Math.round(
        cementDataCurrentPeriod[i].fulfilled.classic
      );
      cementDataCurrentPeriod[i].fulfilled.supaset = Math.round(
        cementDataCurrentPeriod[i].fulfilled.supaset
      );
      cementDataCurrentPeriod[i].fulfilled.total = Math.round(
        cementDataCurrentPeriod[i].fulfilled.total
      );
    }

    for (let i = 0; i < cementDataPreviousPeriod.length; i++) {
      cementDataPreviousPeriod[i].fulfilled.classic = Math.round(
        cementDataPreviousPeriod[i].fulfilled.classic
      );
      cementDataPreviousPeriod[i].fulfilled.supaset = Math.round(
        cementDataPreviousPeriod[i].fulfilled.supaset
      );
      cementDataPreviousPeriod[i].fulfilled.total = Math.round(
        cementDataPreviousPeriod[i].fulfilled.total
      );
    }

    return [
      cementHeaderDataCurrentPeriod,
      cementDataCurrentPeriod,
      cementHeaderDataPreviousPeriod,
      cementDataPreviousPeriod,
      performance,
    ];
  }
};

export default computeCement;
