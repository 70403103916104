import { useMemo } from 'react';
import { SearchParams } from 'typesense/lib/Typesense/Documents';
import { OutletTypeTypesense } from '../types';
import { useAuth } from './auth';
import { usePaginationTypesense } from './utils/usePaginationTypesense';

interface UseOutletsOptions {
  searchText?: string;
  pageSize?: number;
  active?: boolean | null;
}

const useOutlets = ({ searchText, pageSize = 10, active }: UseOutletsOptions) => {
  const { typesenseKey } = useAuth();

  const outletsQuery: SearchParams | undefined = useMemo(() => {
    if (!searchText) return undefined;

    const query = {
      q: searchText,
      query_by: 'name, retailer.phone, retailer.name, retailer.id, cluster.id, cluster.name',
      filter_by: '',
    };

    if (active !== null) query.filter_by = `active: [${active}]`;

    return query;
  }, [searchText, active]);

  const { error, hasMore, load, loading, reset, values } =
    usePaginationTypesense<OutletTypeTypesense>(outletsQuery, pageSize, 'outlets', typesenseKey);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      outlets: values ? values.map(v => ({ ...v.document, id: v.document.id })) : [],
    }),
    [error, hasMore, loading, values]
  );
};

export default useOutlets;
