import { useEffect, useState } from 'react';

type GlobalState = {
  [key: string]: any;
};

type Actions = {
  [key: string]: (state: any, payload: any) => any;
};

let globalState: GlobalState = {};
let listeners: ((state: any) => void)[] = [];
let actions: Actions = {};

export type GlobalStore = [
  globalState: any,
  dispatch: (actionIdentifier: string, payload: any) => void
];

type GlobalStoreOptions = {
  shouldListen?: boolean;
};

const useStore = (options: GlobalStoreOptions = { shouldListen: true }): GlobalStore => {
  const setState = useState(globalState)[1];

  const dispatch = (actionIdentifier: string, payload: any) => {
    const newState = actions[actionIdentifier](globalState, payload);
    globalState = { ...globalState, ...newState };

    for (const listener of listeners) {
      listener(globalState);
    }
  };

  useEffect(() => {
    if (options.shouldListen) {
      listeners.push(setState);
    }

    return () => {
      if (options.shouldListen) {
        listeners = listeners.filter(li => li !== setState);
      }
    };
  }, [setState, options]);

  return [globalState, dispatch];
};

export default useStore;

export const initStore = (userActions: Actions, initialState: any) => {
  if (initialState) {
    globalState = { ...globalState, ...initialState };
  }
  actions = { ...actions, ...userActions };
};
