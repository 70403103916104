import { ProtectedRoute } from '../../components/Routing';
import Sites from './Sites';

const SitesRoute = {
  path: 'sites',
  element: (
    <ProtectedRoute roles={['admin']}>
      <Sites />
    </ProtectedRoute>
  ),
};

export default SitesRoute;
