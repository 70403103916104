import dayjs from 'dayjs';
import { FilterOptions, PerformanceData, TAEAnalyticsType } from '../../../types/Analytics';
import {
  calculatePercentageChange,
  checkIfDataIsInFilter,
  convertTimestampToDate,
  getTaeCompareId,
} from '../helperFunctions';

type Data = {
  id: string;
  name: string;
  target: number;
  leadsCreated: number;
  leadsFulfilled: number;
};

const computeTotalLeads = (analyticsData: TAEAnalyticsType[], filter: FilterOptions) => {
  if (analyticsData.length > 0) {
    const dataPreviousPeriod: Data[] = [];
    const dataCurrentPeriod: Data[] = [];
    const headerDataPreviousPeriod: any = [];
    const headerDataCurrentPeriod: any = [];
    let targetCurrentPeriod: number = 0;
    let targetPreviousPeriod: number = 0;
    let leadsCurrentPeriod: number = 0;
    let leadsPreviousPeriod: number = 0;
    let fulfilledCurrentPeriod: number = 0;
    let fulfilledPreviousPeriod: number = 0;

    const currentPeriodStartDate = filter.dateRange1.startDate;
    const currentPeriodEndDate = dayjs(filter.dateRange1.endDate).add(1, 'day').toDate();
    const previousPeriodStartDate = filter.dateRange2.startDate;
    const previousPeriodEndDate = dayjs(filter.dateRange2.endDate).add(1, 'day').toDate();

    const clusterAdded: string[] = [];
    const clusterAddedPrevious: string[] = [];

    analyticsData.forEach(data => {
      const isInFilter = checkIfDataIsInFilter(data, filter);

      if (isInFilter) {
        const date = convertTimestampToDate(data.date);
        const compareId = getTaeCompareId(data, filter);

        if (data.leadsCreated === undefined || data.leadsFulfilled === undefined) {
          return;
        }

        if (date >= currentPeriodStartDate && date <= currentPeriodEndDate) {
          leadsCurrentPeriod += data.leadsCreated.classic ? data.leadsCreated.classic : 0;
          leadsCurrentPeriod += data.leadsCreated.supaset ? data.leadsCreated.supaset : 0;
          leadsCurrentPeriod += data.leadsCreated.supafixc0 ? data.leadsCreated.supafixc0 : 0;
          leadsCurrentPeriod += data.leadsCreated.supafixc1 ? data.leadsCreated.supafixc1 : 0;
          fulfilledCurrentPeriod += data.leadsFulfilled.classic ? data.leadsFulfilled.classic : 0;
          fulfilledCurrentPeriod += data.leadsFulfilled.supaset ? data.leadsFulfilled.supaset : 0;
          fulfilledCurrentPeriod += data.leadsFulfilled.supafixc0
            ? data.leadsFulfilled.supafixc0
            : 0;
          fulfilledCurrentPeriod += data.leadsFulfilled.supafixc1
            ? data.leadsFulfilled.supafixc1
            : 0;

          if (
            filter.groupBy.toLowerCase() !== 'fst' &&
            !clusterAdded.includes(data.cluster.id + dayjs(data.date.toDate()).format('MM'))
          ) {
            targetCurrentPeriod += data.target.leadCementTarget ? data.target.leadCementTarget : 0;
          }

          // if cementData is not empty, check if id exists
          let idExists = false;
          for (const element of dataCurrentPeriod) {
            if (element.id === compareId) {
              // if id exists, update entry

              if (
                filter.groupBy.toLowerCase() !== 'fst' &&
                !clusterAdded.includes(data.cluster.id + dayjs(data.date.toDate()).format('MM'))
              ) {
                element.target += data.target.leadCementTarget ? data.target.leadCementTarget : 0;
                element.leadsCreated += data.leadsCreated.classic ? data.leadsCreated.classic : 0;
                element.leadsCreated += data.leadsCreated.supaset ? data.leadsCreated.supaset : 0;
                element.leadsCreated += data.leadsCreated.supafixc0
                  ? data.leadsCreated.supafixc0
                  : 0;
                element.leadsCreated += data.leadsCreated.supafixc1
                  ? data.leadsCreated.supafixc1
                  : 0;
                element.leadsFulfilled += data.leadsFulfilled.classic
                  ? data.leadsFulfilled.classic
                  : 0;
                element.leadsFulfilled += data.leadsFulfilled.supaset
                  ? data.leadsFulfilled.supaset
                  : 0;
                element.leadsFulfilled += data.leadsFulfilled.supafixc0
                  ? data.leadsFulfilled.supafixc0
                  : 0;
                element.leadsFulfilled += data.leadsFulfilled.supafixc1
                  ? data.leadsFulfilled.supafixc1
                  : 0;
                clusterAdded.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
              } else {
                element.leadsCreated += data.leadsCreated.classic ? data.leadsCreated.classic : 0;
                element.leadsCreated += data.leadsCreated.supaset ? data.leadsCreated.supaset : 0;
                element.leadsCreated += data.leadsCreated.supafixc0
                  ? data.leadsCreated.supafixc0
                  : 0;
                element.leadsCreated += data.leadsCreated.supafixc1
                  ? data.leadsCreated.supafixc1
                  : 0;
                element.leadsFulfilled += data.leadsFulfilled.classic
                  ? data.leadsFulfilled.classic
                  : 0;
                element.leadsFulfilled += data.leadsFulfilled.supaset
                  ? data.leadsFulfilled.supaset
                  : 0;
                element.leadsFulfilled += data.leadsFulfilled.supafixc0
                  ? data.leadsFulfilled.supafixc0
                  : 0;
                element.leadsFulfilled += data.leadsFulfilled.supafixc1
                  ? data.leadsFulfilled.supafixc1
                  : 0;
              }
              idExists = true;
            }
          }
          if (!idExists) {
            let leadsCreated = 0;
            let leadsFulfilled = 0;

            leadsCreated += data.leadsCreated.classic ? data.leadsCreated.classic : 0;
            leadsCreated += data.leadsCreated.supaset ? data.leadsCreated.supaset : 0;
            leadsCreated += data.leadsCreated.supafixc0 ? data.leadsCreated.supafixc0 : 0;
            leadsCreated += data.leadsCreated.supafixc1 ? data.leadsCreated.supafixc1 : 0;
            leadsFulfilled += data.leadsFulfilled.classic ? data.leadsFulfilled.classic : 0;
            leadsFulfilled += data.leadsFulfilled.supaset ? data.leadsFulfilled.supaset : 0;
            leadsFulfilled += data.leadsFulfilled.supafixc0 ? data.leadsFulfilled.supafixc0 : 0;
            leadsFulfilled += data.leadsFulfilled.supafixc1 ? data.leadsFulfilled.supafixc1 : 0;

            const leads = {
              leadsCreated,
              leadsFulfilled,
            };
            // if id does not exist, push new entry
            if (filter.groupBy.toLowerCase() === 'region') {
              dataCurrentPeriod.push({
                id: data.cluster.region.id,
                name: data.cluster.region.name,
                target: data.target.leadCementTarget ? data.target.leadCementTarget : 0,
                ...leads,
              });
              clusterAdded.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
            }
            if (filter.groupBy.toLowerCase() === 'territory') {
              dataCurrentPeriod.push({
                id: data.cluster.territory.id,
                name: data.cluster.territory.name,
                target: data.target.leadCementTarget ? data.target.leadCementTarget : 0,
                ...leads,
              });
              clusterAdded.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
            }
            if (filter.groupBy.toLowerCase() === 'cluster') {
              dataCurrentPeriod.push({
                id: data.cluster.id,
                name: data.cluster.name,
                target: data.target.leadCementTarget ? data.target.leadCementTarget : 0,
                ...leads,
              });
              clusterAdded.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
            }
            if (filter.groupBy.toLowerCase() === 'fst') {
              targetCurrentPeriod += data.target.leadCementTarget
                ? data.target.leadCementTarget
                : 0;
              dataCurrentPeriod.push({
                id: data.tae.id,
                name: data.tae.name,
                target: data.target.leadCementTarget ? data.target.leadCementTarget : 0,
                ...leads,
              });
            }
          }
        } else if (date >= previousPeriodStartDate && date <= previousPeriodEndDate) {
          // compute header data
          leadsPreviousPeriod += data.leadsCreated.classic ? data.leadsCreated.classic : 0;
          leadsPreviousPeriod += data.leadsCreated.supaset ? data.leadsCreated.supaset : 0;
          leadsPreviousPeriod += data.leadsCreated.supafixc0 ? data.leadsCreated.supafixc0 : 0;
          leadsPreviousPeriod += data.leadsCreated.supafixc1 ? data.leadsCreated.supafixc1 : 0;
          fulfilledPreviousPeriod += data.leadsFulfilled.classic ? data.leadsFulfilled.classic : 0;
          fulfilledPreviousPeriod += data.leadsFulfilled.supaset ? data.leadsFulfilled.supaset : 0;
          fulfilledPreviousPeriod += data.leadsFulfilled.supafixc0
            ? data.leadsFulfilled.supafixc0
            : 0;
          fulfilledPreviousPeriod += data.leadsFulfilled.supafixc1
            ? data.leadsFulfilled.supafixc1
            : 0;

          if (
            filter.groupBy.toLowerCase() !== 'fst' &&
            !clusterAddedPrevious.includes(data.cluster.id + dayjs(data.date.toDate()).format('MM'))
          ) {
            targetPreviousPeriod += data.target.leadCementTarget ? data.target.leadCementTarget : 0;
          }

          // if cementData is not empty, check if id exists
          let idExists = false;
          for (const element of dataPreviousPeriod) {
            if (element.id === compareId) {
              // if id exists, update entry
              if (
                filter.groupBy.toLowerCase() !== 'fst' &&
                !clusterAddedPrevious.includes(
                  data.cluster.id + dayjs(data.date.toDate()).format('MM')
                )
              ) {
                element.target += data.target.leadCementTarget ? data.target.leadCementTarget : 0;
                element.leadsCreated += data.leadsCreated.classic ? data.leadsCreated.classic : 0;
                element.leadsCreated += data.leadsCreated.supaset ? data.leadsCreated.supaset : 0;
                element.leadsCreated += data.leadsCreated.supafixc0
                  ? data.leadsCreated.supafixc0
                  : 0;
                element.leadsCreated += data.leadsCreated.supafixc1
                  ? data.leadsCreated.supafixc1
                  : 0;
                element.leadsFulfilled += data.leadsFulfilled.classic
                  ? data.leadsFulfilled.classic
                  : 0;
                element.leadsFulfilled += data.leadsFulfilled.supaset
                  ? data.leadsFulfilled.supaset
                  : 0;
                element.leadsFulfilled += data.leadsFulfilled.supafixc0
                  ? data.leadsFulfilled.supafixc0
                  : 0;
                element.leadsFulfilled += data.leadsFulfilled.supafixc1
                  ? data.leadsFulfilled.supafixc1
                  : 0;
                clusterAddedPrevious.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
              } else {
                element.leadsCreated += data.leadsCreated.classic ? data.leadsCreated.classic : 0;
                element.leadsCreated += data.leadsCreated.supaset ? data.leadsCreated.supaset : 0;
                element.leadsCreated += data.leadsCreated.supafixc0
                  ? data.leadsCreated.supafixc0
                  : 0;
                element.leadsCreated += data.leadsCreated.supafixc1
                  ? data.leadsCreated.supafixc1
                  : 0;
                element.leadsFulfilled += data.leadsFulfilled.classic
                  ? data.leadsFulfilled.classic
                  : 0;
                element.leadsFulfilled += data.leadsFulfilled.supaset
                  ? data.leadsFulfilled.supaset
                  : 0;
                element.leadsFulfilled += data.leadsFulfilled.supafixc0
                  ? data.leadsFulfilled.supafixc0
                  : 0;
                element.leadsFulfilled += data.leadsFulfilled.supafixc1
                  ? data.leadsFulfilled.supafixc1
                  : 0;
              }
              idExists = true;
            }
          }
          if (!idExists) {
            let leadsCreated = 0;
            let leadsFulfilled = 0;

            leadsCreated += data.leadsCreated.classic ? data.leadsCreated.classic : 0;
            leadsCreated += data.leadsCreated.supaset ? data.leadsCreated.supaset : 0;
            leadsCreated += data.leadsCreated.supafixc0 ? data.leadsCreated.supafixc0 : 0;
            leadsCreated += data.leadsCreated.supafixc1 ? data.leadsCreated.supafixc1 : 0;
            leadsFulfilled += data.leadsFulfilled.classic ? data.leadsFulfilled.classic : 0;
            leadsFulfilled += data.leadsFulfilled.supaset ? data.leadsFulfilled.supaset : 0;
            leadsFulfilled += data.leadsFulfilled.supafixc0 ? data.leadsFulfilled.supafixc0 : 0;
            leadsFulfilled += data.leadsFulfilled.supafixc1 ? data.leadsFulfilled.supafixc1 : 0;

            const leads = {
              leadsCreated,
              leadsFulfilled,
            };
            // if id does not exist, push new entry
            if (filter.groupBy.toLowerCase() === 'region') {
              dataPreviousPeriod.push({
                id: data.cluster.region.id,
                name: data.cluster.region.name,
                target: data.target.leadCementTarget ? data.target.leadCementTarget : 0,
                ...leads,
              });
              clusterAddedPrevious.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
            }
            if (filter.groupBy.toLowerCase() === 'territory') {
              dataPreviousPeriod.push({
                id: data.cluster.territory.id,
                name: data.cluster.territory.name,
                target: data.target.leadCementTarget ? data.target.leadCementTarget : 0,
                ...leads,
              });
              clusterAddedPrevious.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
            }
            if (filter.groupBy.toLowerCase() === 'cluster') {
              dataPreviousPeriod.push({
                id: data.cluster.id,
                name: data.cluster.name,
                target: data.target.leadCementTarget ? data.target.leadCementTarget : 0,
                ...leads,
              });
              clusterAddedPrevious.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
            }
            if (filter.groupBy.toLowerCase() === 'fst') {
              dataPreviousPeriod.push({
                id: data.tae.id,
                name: data.tae.name,
                target: data.target.leadCementTarget ? data.target.leadCementTarget : 0,
                ...leads,
              });
              targetPreviousPeriod += data.target.leadCementTarget
                ? data.target.leadCementTarget
                : 0;
            }
          }
        }
      }
    });

    headerDataCurrentPeriod.push(
      {
        name: 'Generated',
        value: Math.round(leadsCurrentPeriod),
      },
      {
        name: 'Fulfilled',
        value: Math.round(fulfilledCurrentPeriod),
      },
      {
        name: 'ACH',
        value: `${Math.round((fulfilledCurrentPeriod / leadsCurrentPeriod) * 100 * 100) / 100}%`,
      },
      {
        name: 'Target',
        value: Math.round(targetCurrentPeriod),
      }
    );

    headerDataPreviousPeriod.push(
      {
        name: 'Generated',
        value: Math.round(leadsPreviousPeriod),
      },
      {
        name: 'Fulfilled',
        value: Math.round(fulfilledPreviousPeriod),
      },
      {
        name: 'ACH',
        value: `${Math.round((fulfilledPreviousPeriod / leadsPreviousPeriod) * 100 * 100) / 100}%`,
      },
      {
        name: 'Target',
        value: Math.round(targetPreviousPeriod),
      }
    );

    const performance: PerformanceData[] = [
      {
        name: 'Generated',
        value: calculatePercentageChange(leadsCurrentPeriod, leadsPreviousPeriod),
      },
      {
        name: 'Fulfilled',
        value: calculatePercentageChange(fulfilledCurrentPeriod, fulfilledPreviousPeriod),
      },
    ];

    // round all values to full numbers
    for (let i = 0; i < dataCurrentPeriod.length; i++) {
      dataCurrentPeriod[i].leadsCreated = Math.round(dataCurrentPeriod[i].leadsCreated);
      dataCurrentPeriod[i].leadsFulfilled = Math.round(dataCurrentPeriod[i].leadsFulfilled);
    }

    for (let i = 0; i < dataPreviousPeriod.length; i++) {
      dataPreviousPeriod[i].leadsCreated = Math.round(dataPreviousPeriod[i].leadsCreated);
      dataPreviousPeriod[i].leadsFulfilled = Math.round(dataPreviousPeriod[i].leadsFulfilled);
    }

    return [
      headerDataCurrentPeriod,
      dataCurrentPeriod,
      headerDataPreviousPeriod,
      dataPreviousPeriod,
      performance,
    ];
  }
};

export default computeTotalLeads;
