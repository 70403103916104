import { doc, updateDoc } from 'firebase/firestore';
import { UserChanges } from '../types/User';
import { firestore } from '../utils/firebase';

const updateUser = async (userId: string, data: UserChanges) => {
  const ref = doc(firestore, 'users', userId);
  await updateDoc(ref, data);
};

export default updateUser;
