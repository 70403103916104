import { doc, FirestoreError, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { useEffect, useMemo } from 'react';
import updateUser from '../db/users';
import { UserChanges, UserType } from '../types';
import { firestore, docToJSON } from '../utils/firebase';
import { useAuth } from './auth';
import { useLoadingValue } from './utils';

const useUser = (userId?: string) => {
  const { id } = useAuth();
  const { error, loading, setLoading, setError, setValue, value } = useLoadingValue<
    UserType | null,
    FirestoreError
  >();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    if (id) {
      const ref = doc(firestore, 'users', userId || id);
      unsubscribe = onSnapshot(
        ref,
        snapshot => {
          setValue(docToJSON(snapshot));
          setLoading(false);
        },
        setError
      );
    } else {
      setValue(null);
      setLoading(false);
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [id, userId]);

  const update = async (changes: UserChanges) => {
    if (!id) return;
    try {
      setLoading(true);
      await updateUser(id, changes);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err instanceof FirestoreError) setError(err);
    }
  };

  return useMemo(
    () => ({
      user: value,
      error,
      loading,
      update,
    }),
    [value, loading, error]
  );
};

export default useUser;
