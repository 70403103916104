import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../utils/firebase';
import { OutletChanges } from '../types';

const updateOutlet = async (outletId: string, data: OutletChanges) => {
  const ref = doc(firestore, 'outlets', outletId);
  await updateDoc(ref, data);
};

export default updateOutlet;
