import { RoleType } from '../../../../common/types';
import UsersList from '../blocks/UsersList';

interface LeftCenterProps {
  roles: RoleType[];
  searchText?: string;
  setSelectedUserId: (id: string) => void;
}

const LefCenter: React.FC<LeftCenterProps> = ({ roles, searchText, setSelectedUserId }) => (
  <div className="w-1/2 flex flex-col items-center">
    <UsersList searchText={searchText} roles={roles} setSelectedUserId={setSelectedUserId} />
  </div>
);

export default LefCenter;
