import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';

interface MonthSelectorProps {
  onDateChange: (date: dayjs.Dayjs) => void;
}

const MonthSelector: React.FC<MonthSelectorProps> = ({ onDateChange }) => {
  const [date, setDate] = useState(dayjs());

  const showNextMonthButtonStyle = useMemo(
    () =>
      clsx(
        'text-2xl font-bold ml-8 font-nunito',
        !date.isBefore(dayjs().add(1, 'months'), 'month') && 'text-white cursor-arrow'
      ),
    [date]
  );

  const handlePreviousMonth = () => {
    setDate(date.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setDate(date.add(1, 'month'));
  };

  useEffect(() => {
    onDateChange(date);
  }, [date, onDateChange]);

  return (
    <div className="fixed z-10 top-16 flex justify-center items-center font-outfit">
      <button
        className="text-2xl font-bold mr-8 font-nunito"
        onClick={handlePreviousMonth}
        type="button"
      >
        &lt;
      </button>
      <h1 className="text-2xl font-bold mx-4">{date.format('MMM YY')}</h1>
      <button
        className={showNextMonthButtonStyle}
        onClick={handleNextMonth}
        type="button"
        disabled={!date.isBefore(dayjs().add(1, 'months'), 'month')}
      >
        &gt;
      </button>
    </div>
  );
};

export default MonthSelector;
