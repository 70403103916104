import { NavLink } from 'react-router-dom';

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
}

/**
 * Nav link that changes it's style when it is active.
 */
const StyledNavLink: React.FC<NavLinkProps> = ({ to, children }) => (
  <NavLink
    className={({ isActive }) =>
      `p-4 no-underline rounded-md ${isActive ? 'bg-secondary text-primary' : 'border-white '}`
    }
    to={to}
  >
    {children}
  </NavLink>
);

export default StyledNavLink;
