import { Outlet } from 'react-router-dom';
import { AuthProvider } from '../../common/context';
import Content from './ui/partials/Content';
import Footer from './ui/partials/Footer';
import Sidebar from './ui/partials/Sidebar';

const Layout: React.FC = () => (
  <AuthProvider>
    <div data-testid="layout" className="h-screen w-screen flex bg-primary">
      <Sidebar />
      <Content>
        <Outlet />
      </Content>
      <Footer />
    </div>
  </AuthProvider>
);

export default Layout;
