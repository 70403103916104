import { FirebaseOptions, initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  GoogleAuthProvider,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth';
import {
  DocumentSnapshot,
  getFirestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const auth = initializeAuth(app, {
  persistence: [indexedDBLocalPersistence, browserLocalPersistence],
});

export const googleAuthProvider = new GoogleAuthProvider();

export const firestore = getFirestore(app);

const functions = getFunctions(app, 'europe-west6');

export const getScopedTypesenseKeyAdmin = httpsCallable(functions, 'getScopedTypesenseKeyAdmin');

export const changePrimaryFSA = httpsCallable(functions, 'changePrimaryFSA');
export const changePrimaryTAE = httpsCallable(functions, 'changePrimaryTAE');
export const lockTargets = httpsCallable(functions, 'lockTargets');
export const unlockTarget = httpsCallable(functions, 'unlockTargets');
export const reassignOutlets = httpsCallable(functions, 'reassignOutlets');
export const reassignSites = httpsCallable(functions, 'reassignSites');
export const changeGroupFSA = httpsCallable(functions, 'changeGroupFSA');

/**
 * Converts a firestore document to JSON
 * @param  {DocumentSnapshot} doc
 */
export function docToJSON<T>(doc: DocumentSnapshot | QueryDocumentSnapshot) {
  const data = doc.data();
  if (!data) return null;
  Object.keys(data).forEach(key => {
    if (data[key] instanceof Timestamp) {
      data[key] = data[key].toMillis();
    }
  });
  return {
    id: doc.id,
    ...data,
  } as any as T;
}
