import useStore from '../../common/hooks/useStore';
import { TAEAnalyticsType } from '../../common/types/Analytics';
import SiteVisitsChart from './SiteVisitsChart';
import loadingLogo from '../../common/assets/images/loadingLogo.png';
import LeadsChart from './LeadsChart';
import CementLeadsChart from './CementLeadsChart';
import MortarLeadsChart from './MortarLeadsChart';
import TotalLeadsChart from './TotalLeadsChart';
import { useAnalytics } from '../../common/hooks';

interface TAEChartsProps {}

const TAECharts: React.FC<TAEChartsProps> = () => {
  const [state] = useStore();
  const { data, error, loading } = useAnalytics<TAEAnalyticsType>({
    type: 'tae',
    dateRange1: state.filter.dateRange1,
    dateRange2: state.filter.dateRange2,
    groupBy: state.filter.groupBy,
    regions: state.filter.regions,
    territories: state.filter.territories,
    clusters: state.filter.clusters,
  });

  if (data && data?.length > 0 && !loading) {
    return (
      <>
        <div className="shadow bg-white p-5 mb-5">
          <h1 className="font-outfit font-medium text-2xl mb-5 text-center">Site Visits</h1>
          <SiteVisitsChart data={data} filter={state.filter} />
        </div>
        {/* <div className="shadow bg-white p-5 mb-5">
          <h1 className="font-outfit font-medium text-2xl mb-5">
            Sites
          </h1>
          <SitesChart data={analyticsData} filter={state.filter}/>
        </div> */}
        <div className="shadow bg-white p-5 mb-5">
          <h1 className="font-outfit font-medium text-2xl mb-5 text-center">Leads</h1>
          <LeadsChart data={data} filter={state.filter} />
        </div>
        <div className="shadow bg-white p-5 mb-5">
          <h1 className="font-outfit font-medium text-2xl mb-5">Cement Leads Fulfilment (tons)</h1>
          <CementLeadsChart data={data} filter={state.filter} />
        </div>
        <div className="shadow bg-white p-5 mb-5">
          <h1 className="font-outfit font-medium text-2xl mb-5 text-center">
            Mortar Leads Fulfilment (tons)
          </h1>
          <MortarLeadsChart data={data} filter={state.filter} />
        </div>
        <div className="shadow bg-white p-5 mb-5">
          <h1 className="font-outfit font-medium text-2xl mb-5 text-center">
            Total Leads Fulfilment (tons)
          </h1>
          <TotalLeadsChart data={data} filter={state.filter} />
        </div>
      </>
    );
  }
  if (loading) {
    return (
      <img src={loadingLogo} alt="loading logo" className="m-auto h-10 w-10 animate-pulse mt-10" />
    );
  }
  if (error) {
    return (
      <div className="m-auto">
        <p className="mt-10 text-center">{error}</p>
      </div>
    );
  }
  return (
    <div className="m-auto">
      <p className="mt-10 text-center">No data found for the selected date range</p>
    </div>
  );
};

export default TAECharts;
