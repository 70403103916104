import { collection, orderBy, query, where } from 'firebase/firestore';
import { useMemo } from 'react';
import { TargetType } from '../types/Target';
import { docToJSON, firestore } from '../utils/firebase';
import { usePagination } from './utils/usePagination';

interface UseTargetsOptions {
  targetTypes?: string[];
  month?: number;
  pageSize?: number;
  year?: number;
  regionIds?: string[];
  territoryIds?: string[];
}

const useTargets = ({
  targetTypes,
  month,
  pageSize = 100,
  year,
  regionIds,
  territoryIds,
}: UseTargetsOptions) => {
  const targetsQuery = useMemo(() => {
    let q = query(collection(firestore, 'targets'));
    if (month) {
      q = query(q, where('month', '==', month));
    }

    if (year) {
      q = query(q, where('year', '==', year));
    }

    if (regionIds && regionIds.length > 0) {
      q = query(q, where('cluster.region.id', 'in', regionIds));
    }

    if (territoryIds && territoryIds.length > 0) {
      q = query(q, where('cluster.territory.id', 'in', territoryIds));
    }

    if (targetTypes && targetTypes.length > 0) {
      q = query(q, where('targetType', 'in', targetTypes));
    }

    q = query(q, orderBy('cluster.name'));

    return q;
  }, [month, year, targetTypes, regionIds, territoryIds]);

  const { error, hasMore, load, loading, reset, values } = usePagination(targetsQuery, pageSize);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      targets: values ? values.map(v => docToJSON(v) as TargetType) : [],
    }),
    [error, hasMore, loading, values]
  );
};

export default useTargets;
