import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import CloseButton from '../../../../components/CloseButton';
import Input from '../../../../components/Form/Input';
import Cluster from '../components/Cluster';
import useOutlet from '../../../../common/hooks/useOutlet';
import updateOutlet from '../../../../common/db/outlets';

interface OutletDetailsProps {
  selectedOutletId: string;
  setSelectedOutletId: (id: string | undefined) => void;
}

interface OutletDetailsForm {
  name: string;
}

const OutletDetails: React.FC<OutletDetailsProps> = ({ selectedOutletId, setSelectedOutletId }) => {
  const { error, loading, outlet } = useOutlet(selectedOutletId);
  const { register, reset, handleSubmit } = useForm<OutletDetailsForm>();

  useEffect(() => {
    if (outlet) {
      reset({
        name: outlet.name,
      });
    }
  }, [outlet]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  const onSubmit = (data: OutletDetailsForm) => {
    updateOutlet(selectedOutletId, data);
  };

  const handleOnClose = () => setSelectedOutletId(undefined);

  return (
    <Card className="w-1/2 md:w-full flex flex-col">
      <CloseButton onClick={handleOnClose} className="max-w-min self-end" />
      {!outlet && !loading && !error ? (
        <div className="text-center flex justify-between">Select a outlet to view details</div>
      ) : (
        <>
          <div className="flex flex-col">
            <span
              className={`flex w-3 h-3 rounded-full ${
                outlet?.active ? 'bg-accent5' : 'bg-accent2'
              }`}
            />
            <h1 className="my-4 font-thin font-nunito">{outlet?.id}</h1>

            <form className="w-full flex justify-between flex-wrap mt-3">
              <Input
                type="text"
                name="name"
                register={register}
                className="flex-1/2 font-bold font-outfit text-2xl mb-2"
              />
            </form>

            <h2 className="font-bold mt-3">Retailer</h2>
            <p>
              {outlet?.retailer.name}{' '}
              <span className="font-thin text-sm">({outlet?.retailer?.id})</span>
            </p>
            <p>HasWhatsApp: {outlet?.retailer.hasWhatsApp ? 'true' : 'false'}</p>

            <h2 className="font-bold mt-3">Primary FSA</h2>
            <p>
              {outlet?.primaryFSA.name}{' '}
              <span className="font-thin text-sm">({outlet?.primaryFSA?.id})</span>
            </p>

            <h2 className="font-bold mt-3">Primary Distributor</h2>
            <p>
              {outlet?.primaryDistributor.name}{' '}
              <span className="font-thin text-sm">({outlet?.primaryDistributor?.id})</span>{' '}
            </p>

            <h2 className="font-bold mt-3">SOP</h2>
            <p>
              {outlet?.sop.name} <span className="font-thin text-sm">({outlet?.sop?.id})</span>
            </p>

            <h2 className="font-bold mt-3">FSM</h2>
            <p>
              {outlet?.fsm?.name} <span className="font-thin text-sm">({outlet?.fsm?.id})</span>
            </p>
            <p>HasWhatsApp: {outlet?.fsm?.hasWhatsApp ? 'true' : 'false'}</p>

            <h2 className="font-bold mt-3">User IDs</h2>
            <ul className="list-inside list-disc">
              {outlet?.userIds.map(userId => (
                <li key={userId}>{userId}</li>
              ))}

              <h2 className="font-bold mt-3">Other</h2>
              <p>Status: {outlet?.status}</p>
              {outlet?.cluster && <Cluster cluster={outlet.cluster} />}
            </ul>
          </div>
          <Button
            color="accent1"
            className="my-2 disabled:opacity-50"
            text="Save"
            disabled
            onClick={handleSubmit(onSubmit)}
          />
          <Button
            color="accent2"
            className="my-2 disabled:opacity-50"
            text="Deactivate"
            disabled
            onClick={handleOnClose}
          />
        </>
      )}
    </Card>
  );
};

export default OutletDetails;
