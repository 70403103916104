import { ProtectedRoute } from '../../components/Routing';
import Targets from './Targets';

const TargetsRoute = {
  path: 'targets',
  element: (
    <ProtectedRoute roles={['admin']}>
      <Targets />
    </ProtectedRoute>
  ),
};

export default TargetsRoute;
