import { ProtectedRoute } from '../../components/Routing';
import CostsOverview from './CostsOverview';

const CostsOverviewRoute = {
  path: 'costsOverview',
  element: (
    <ProtectedRoute roles={['costs']}>
      <CostsOverview />
    </ProtectedRoute>
  ),
};

export default CostsOverviewRoute;
