import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

interface CloseButtonProps {
  onClick: () => void;
  className?: string;
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClick, className }) => {
  const style = clsx('flex', className);

  return (
    <div className={style} data-testid="back-button">
      <button type="button" className="pl-3 pr-4 py-2" onClick={onClick}>
        <FontAwesomeIcon icon={faX} />
      </button>
    </div>
  );
};

export default CloseButton;
