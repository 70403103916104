import { ProtectedRoute } from '../../components/Routing';
import Groups from './Groups';

const GroupsRoute = {
  path: 'groups',
  element: (
    <ProtectedRoute roles={['admin']}>
      <Groups />
    </ProtectedRoute>
  ),
};

export default GroupsRoute;
