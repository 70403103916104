interface SwitchProps {
  isChecked: boolean;
  onClick: () => void;
}

const Switch: React.FC<SwitchProps> = ({ onClick, isChecked }) => (
  <label htmlFor="toggle-switch">
    <input
      type="checkbox"
      className="cursor-pointer h-7 w-14 rounded-full appearance-none bg-accent5 checked:bg-accent2 transition duration-200 relative"
      id="toggle-switch"
      onClick={onClick}
      checked={isChecked}
    />
  </label>
);

export default Switch;
