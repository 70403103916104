/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useMemo } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { StylesConfig } from 'react-select';
import { RoleType } from '../../../../common/types';
import { debounce } from '../../../../common/utils';
import Card from '../../../../components/Card';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';

interface SearchBarProps {
  onSearchChange: (searchText: string) => void;
  onRolesChange: (roles: RoleType[]) => void;
}

interface RoleOption {
  label: string;
  value: RoleType;
  default: boolean;
}

interface SearchBarForm {
  searchText: string;
  roles: RoleOption[];
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearchChange, onRolesChange }) => {
  const { register, getValues, control, watch, reset } = useForm<SearchBarForm>();

  const watchRoles = watch('roles');

  useEffect(() => {
    if (watchRoles) {
      onRolesChange(watchRoles.map(role => role.value));
    }
  }, [watchRoles, onRolesChange]);

  const rolesOptions: RoleOption[] = useMemo(
    () => [
      { label: 'Distributor', value: 'distributor', default: true },
      { label: 'FSA', value: 'fsa', default: true },
      { label: 'FSM', value: 'fsm', default: true },
      { label: 'SOP', value: 'sop', default: true },
      { label: 'TAE', value: 'tae', default: true },
      { label: 'Retailer', value: 'retailer', default: true },
      { label: 'Site Owner', value: 'siteOwner', default: true },
    ],
    []
  );

  useEffect(() => {
    reset({
      searchText: '',
      roles: rolesOptions.filter((role: RoleOption) => role.default),
    });
  }, [reset, rolesOptions]);

  const searchTextOptions: RegisterOptions = {
    onChange: debounce(() => {
      const searchText = getValues('searchText');
      if (searchText.length > 2) {
        onSearchChange(searchText);
      }
    }, 500),
  };

  const selectStyles: StylesConfig = {
    valueContainer: provided => ({
      ...provided,
      flexWrap: 'nowrap',
    }),
    input: provided => ({
      ...provided,
      overflow: 'hidden',
    }),
  };

  return (
    <Card className="fixed z-10 md:w-4/6 top-16 bg-white">
      <form onSubmit={event => event.preventDefault()} className="flex">
        <Input
          name="searchText"
          placeholder="Search user..."
          register={register}
          options={searchTextOptions}
          className="flex-3/4 mr-2"
        />
        <Select
          name="roles"
          options={rolesOptions}
          control={control}
          placeholder="Select roles..."
          rules={undefined}
          isMulti
          className="flex-1/4 ml-2"
          styles={selectStyles}
        />
      </form>
      <p>Searchable by User id/name/phone</p>
      <DevTool control={control} />
    </Card>
  );
};

export default SearchBar;
