import dayjs from 'dayjs';
import { AnalyticsType, FilterOptions, PerformanceData } from '../../../types/Analytics';
import {
  calculatePercentageChange,
  checkIfDataIsInFilter,
  convertTimestampToDate,
  getCompareId,
} from '../helperFunctions';

type Data = {
  id: string;
  name: string;
  registeredOutlets: number;
  whatsAppEnabledOutlets: number;
};

const computeWhatsAppEnabled = (analyticsData: AnalyticsType[], filter: FilterOptions) => {
  if (analyticsData.length > 0) {
    const dataPreviousPeriod: Data[] = [];
    const dataCurrentPeriod: Data[] = [];
    const headerDataPreviousPeriod: any = [];
    const headerDataCurrentPeriod: any = [];
    let registeredOutletsCurrentPeriod = 0;
    let registeredOutletsPreviousPeriod = 0;
    let whatsAppEnabledOutletsCurrentPeriod = 0;
    let whatsAppEnabledOutletsPreviousPeriod = 0;

    const currentPeriodStartDate = filter.dateRange1.startDate;
    const currentPeriodEndDate = dayjs(filter.dateRange1.endDate).add(1, 'day').toDate();
    const previousPeriodStartDate = filter.dateRange2.startDate;
    const previousPeriodEndDate = dayjs(filter.dateRange2.endDate).add(1, 'day').toDate();
    const clusterAdded: string[] = [];
    const clusterAddedPrevious: string[] = [];

    analyticsData.forEach(data => {
      const isInFilter = checkIfDataIsInFilter(data, filter);

      if (isInFilter) {
        const date = convertTimestampToDate(data.date);
        const compareId = getCompareId(data, filter);

        if (date >= currentPeriodStartDate && date <= currentPeriodEndDate) {
          // compute header data

          if (
            date.getDate() !== filter.dateRange1.endDate.getDate() ||
            date.getMonth() !== filter.dateRange1.endDate.getMonth()
          ) {
            return;
          }

          if (
            filter.groupBy.toLowerCase() !== 'fst' &&
            date.getDate() === filter.dateRange1.endDate.getDate() &&
            date.getMonth() === filter.dateRange1.endDate.getMonth()
          ) {
            registeredOutletsCurrentPeriod += data.registeredOutlets;
            whatsAppEnabledOutletsCurrentPeriod += data.whatsAppEnabledOutlets;
          }

          // if cementData is not empty, check if id exists
          let idExists = false;
          for (const element of dataCurrentPeriod) {
            if (element.id === compareId) {
              // if id exists, update entry
              if (
                filter.groupBy.toLowerCase() !== 'fst' &&
                !clusterAdded.includes(data.cluster.id) &&
                date.getDate() === filter.dateRange1.endDate.getDate() &&
                date.getMonth() === filter.dateRange1.endDate.getMonth()
              ) {
                element.registeredOutlets += data.registeredOutlets;
                element.whatsAppEnabledOutlets += data.whatsAppEnabledOutlets;
                clusterAdded.push(data.cluster.id);
              }
              idExists = true;
            }
          }
          if (!idExists) {
            const waEnabledData = {
              registeredOutlets: data.registeredOutlets,
              whatsAppEnabledOutlets: data.whatsAppEnabledOutlets,
            };
            // if id does not exist, push new entry
            if (filter.groupBy.toLowerCase() === 'region') {
              dataCurrentPeriod.push({
                id: data.cluster.region.id,
                name: data.cluster.region.name,
                ...waEnabledData,
              });
            }
            if (filter.groupBy.toLowerCase() === 'territory') {
              dataCurrentPeriod.push({
                id: data.cluster.territory.id,
                name: data.cluster.territory.name,
                ...waEnabledData,
              });
            }
            if (filter.groupBy.toLowerCase() === 'cluster') {
              dataCurrentPeriod.push({
                id: data.cluster.id,
                name: data.cluster.name,
                ...waEnabledData,
              });
            }
            if (filter.groupBy.toLowerCase() === 'fst') {
              whatsAppEnabledOutletsCurrentPeriod += data.whatsAppEnabledOutlets;
              registeredOutletsCurrentPeriod += data.registeredOutlets;
              dataCurrentPeriod.push({
                id: data.fsa.id,
                name: data.fsa.name,
                ...waEnabledData,
              });
            }
          }
        } else if (date >= previousPeriodStartDate && date <= previousPeriodEndDate) {
          if (
            date.getDate() !== filter.dateRange2.endDate.getDate() ||
            date.getMonth() !== filter.dateRange2.endDate.getMonth()
          ) {
            return;
          }

          // compute header data
          if (
            filter.groupBy.toLowerCase() !== 'fst' &&
            date.getDate() === filter.dateRange2.endDate.getDate() &&
            date.getMonth() === filter.dateRange2.endDate.getMonth()
          ) {
            registeredOutletsPreviousPeriod += data.registeredOutlets;
            whatsAppEnabledOutletsPreviousPeriod += data.whatsAppEnabledOutlets;
          }
          // if cementData is not empty, check if id exists
          let idExists = false;
          for (const element of dataPreviousPeriod) {
            if (element.id === compareId) {
              // if id exists, update entry
              if (
                filter.groupBy.toLowerCase() !== 'fst' &&
                !clusterAddedPrevious.includes(data.cluster.id) &&
                date.getDate() === filter.dateRange2.endDate.getDate() &&
                date.getMonth() === filter.dateRange2.endDate.getMonth()
              ) {
                element.registeredOutlets += data.registeredOutlets;
                element.whatsAppEnabledOutlets += data.whatsAppEnabledOutlets;
                clusterAddedPrevious.push(data.cluster.id);
              }
              idExists = true;
            }
          }
          if (!idExists) {
            const waEnabledData = {
              registeredOutlets: data.registeredOutlets,
              whatsAppEnabledOutlets: data.whatsAppEnabledOutlets,
            };

            if (filter.groupBy.toLowerCase() === 'region') {
              dataPreviousPeriod.push({
                id: data.cluster.region.id,
                name: data.cluster.region.name,
                ...waEnabledData,
              });
              clusterAddedPrevious.push(data.cluster.id);
            }
            if (filter.groupBy.toLowerCase() === 'territory') {
              dataPreviousPeriod.push({
                id: data.cluster.territory.id,
                name: data.cluster.territory.name,
                ...waEnabledData,
              });
              clusterAddedPrevious.push(data.cluster.id);
            }
            if (filter.groupBy.toLowerCase() === 'cluster') {
              dataPreviousPeriod.push({
                id: data.cluster.id,
                name: data.cluster.name,
                ...waEnabledData,
              });
              clusterAddedPrevious.push(data.cluster.id);
            }
            if (filter.groupBy.toLowerCase() === 'fst') {
              whatsAppEnabledOutletsPreviousPeriod += data.whatsAppEnabledOutlets;
              registeredOutletsPreviousPeriod += data.registeredOutlets;
              dataPreviousPeriod.push({
                id: data.fsa.id,
                name: data.fsa.name,
                ...waEnabledData,
              });
            }
          }
        }
      }
    });

    headerDataCurrentPeriod.push(
      {
        name: 'WhatsApp Enabled',
        value: whatsAppEnabledOutletsCurrentPeriod,
      },
      {
        name: 'Registered Outlets',
        value: registeredOutletsCurrentPeriod,
      },
      {
        name: 'ACH',
        value: `${
          Math.round(
            (whatsAppEnabledOutletsCurrentPeriod / registeredOutletsCurrentPeriod) * 100 * 100
          ) / 100
        }%`,
      }
    );

    headerDataPreviousPeriod.push(
      {
        name: 'WhatsApp Enabled',
        value: whatsAppEnabledOutletsPreviousPeriod,
      },
      {
        name: 'Registered Outlets',
        value: registeredOutletsPreviousPeriod,
      },
      {
        name: 'ACH',
        value: `${
          Math.round(
            (whatsAppEnabledOutletsPreviousPeriod / registeredOutletsPreviousPeriod) * 100 * 100
          ) / 100
        }%`,
      }
    );

    const performance: PerformanceData[] = [
      {
        name: 'Registered Outlets',
        value: calculatePercentageChange(
          registeredOutletsCurrentPeriod,
          registeredOutletsPreviousPeriod
        ),
      },
      {
        name: 'Wa Enabled Outlets',
        value: calculatePercentageChange(
          whatsAppEnabledOutletsCurrentPeriod,
          whatsAppEnabledOutletsPreviousPeriod
        ),
      },
    ];

    return [
      headerDataCurrentPeriod,
      dataCurrentPeriod,
      headerDataPreviousPeriod,
      dataPreviousPeriod,
      performance,
    ];
  }
};

export default computeWhatsAppEnabled;
