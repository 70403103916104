import { useMemo } from 'react';
import { RoleType } from '../../types';
import useAuth from './useAuth';

export type CheckAccessActionHook = (roles: RoleType[]) => boolean;

const useCheckAccess = (): CheckAccessActionHook => {
  const { roles } = useAuth();

  const checkAccess = (requiredRoles: RoleType[]): boolean => {
    if (!roles) return false;
    return roles.some(role => requiredRoles.includes(role));
  };

  const resArray: CheckAccessActionHook = checkAccess;
  return useMemo<CheckAccessActionHook>(() => resArray, [resArray]);
};

export default useCheckAccess;
