import { useState } from 'react';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { useAuth } from '../../../../common/hooks/auth';
import Logo from '../blocks/Logo';
import Menu from '../blocks/Menu';
import SignOut from '../blocks/SignOut';

const Sidebar: React.FC = () => {
  const { id } = useAuth();

  const [open, setOpen] = useState(true);

  return (
    <div
      data-testid="sidebar"
      className={` ${
        open ? 'w-72' : 'w-24'
      } px-2 flex flex-col border-r items-center bg-white h-auto top-0 left-0 bottom-0 transition-all duration-200 ease-in-out`}
    >
      <Logo open={open} />
      <FaArrowCircleLeft
        className={`cursor-pointer text-xl text-secondary mb-6 ${!open && 'rotate-180'}`}
        onClick={() => setOpen(!open)}
      />
      {id ? (
        <>
          <Menu open={open} />
          <SignOut open={open} />
        </>
      ) : null}
    </div>
  );
};

export default Sidebar;
