import { collection, query } from 'firebase/firestore';
import { useMemo } from 'react';
import { docToJSON, firestore } from '../utils/firebase';
import { usePagination } from './utils/usePagination';

const useGroups = ({
  pageSize = 100,
}: any) => {
  const targetsQuery = useMemo(() => {
    const q = query(collection(firestore, 'groups'));

    return q;
  }, []);

  const { error, hasMore, load, loading, reset, values } = usePagination(targetsQuery, pageSize);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      groups: values ? values.map(v => docToJSON(v) as { id: string, name: string; fsa: any }) : [],
    }),
    [error, hasMore, loading, values]
  );
};

export default useGroups;
