import dayjs from 'dayjs';
import { AnalyticsType, FilterOptions, PerformanceData } from '../../../types/Analytics';
import {
  calculatePercentageChange,
  checkIfDataIsInFilter,
  convertTimestampToDate,
  getCompareId,
} from '../helperFunctions';

type OutletData = {
  id: string;
  name: string;
  productiveOutlets: number;
  productiveOutletsPercentage: number;
  semiProductiveOutlets: number;
  semiProductiveOutletsPercentage: number;
  notActiveOutlets: number;
  notActiveOutletsPercentage: number;
};

const computeOutlets = (analyticsData: AnalyticsType[], filter: FilterOptions) => {
  const dataPreviousPeriod: OutletData[] = [];
  const dataCurrentPeriod: OutletData[] = [];
  const headerDataPreviousPeriod: any = [];
  const headerDataCurrentPeriod: any = [];

  let registeredOutletsCurrentPeriod = 0;
  let registeredOutletsPreviousPeriod = 0;
  let semiProductiveOutletsCurrentPeriod = 0;
  let semiProductiveOutletsPreviousPeriod = 0;
  let productiveOutletsCurrentPeriod = 0;
  let productiveOutletsPreviousPeriod = 0;
  let newOutletsCurrentPeriod = 0;
  let newOutletsPreviousPeriod = 0;

  const currentPeriodStartDate = filter.dateRange1.startDate;
  const currentPeriodEndDate = dayjs(filter.dateRange1.endDate).add(1, 'day').toDate();
  const previousPeriodStartDate = filter.dateRange2.startDate;
  const previousPeriodEndDate = dayjs(filter.dateRange2.endDate).add(1, 'day').toDate();

  const outletsMap: Map<string, any> = new Map();
  const outletsMapPrevious: Map<string, any> = new Map();

  analyticsData.forEach(data => {
    const isInFilter = checkIfDataIsInFilter(data, filter);

    if (isInFilter) {
      const date = convertTimestampToDate(data.date);
      const compareId = getCompareId(data, filter);

      if (date >= currentPeriodStartDate && date <= currentPeriodEndDate) {
        if (
          date.getDate() === filter.dateRange1.endDate.getDate() &&
          date.getMonth() === filter.dateRange1.endDate.getMonth() &&
          date.getFullYear() === filter.dateRange1.endDate.getFullYear()
        ) {
          registeredOutletsCurrentPeriod += data.registeredOutlets;
        }

        if (Array.isArray(data.outlets)) {
          data.outlets.forEach(outlet => {
            const tempOutlet = outletsMap.get(outlet.id);
            let newVolume = 0;
            if (tempOutlet) {
              const currentVolume = tempOutlet.volume;
              newVolume = outlet.volume + currentVolume;
            } else {
              newVolume = outlet.volume;
            }

            let name = '';
            if (filter.groupBy.toLowerCase() === 'fst') {
              name = data.fsa.name;
            } else if (filter.groupBy.toLowerCase() === 'cluster') {
              name = data.cluster.name;
            } else if (filter.groupBy.toLowerCase() === 'territory') {
              name = data.cluster.territory.name;
            } else if (filter.groupBy.toLowerCase() === 'region') {
              name = data.cluster.region.name;
            }

            outletsMap.set(outlet.id, {
              volume: newVolume,
              location: data.cluster.region.name,
              compareId,
              name,
            });
          });
          newOutletsCurrentPeriod += data.newOutlets;
        }
      } else if (date >= previousPeriodStartDate && date <= previousPeriodEndDate) {
        if (
          date.getDate() === filter.dateRange2.endDate.getDate() &&
          date.getMonth() === filter.dateRange2.endDate.getMonth() &&
          date.getFullYear() === filter.dateRange2.endDate.getFullYear()
        ) {
          registeredOutletsPreviousPeriod += data.registeredOutlets;
        }

        if (Array.isArray(data.outlets)) {
          data.outlets?.forEach(outlet => {
            const tempOutlet = outletsMapPrevious.get(outlet.id);
            let newVolume = 0;
            if (tempOutlet) {
              const currentVolume = tempOutlet.volume;
              newVolume = outlet.volume + currentVolume;
            } else {
              newVolume = outlet.volume;
            }

            let name = '';
            if (filter.groupBy.toLowerCase() === 'fst') {
              name = data.fsa.name;
            } else if (filter.groupBy.toLowerCase() === 'cluster') {
              name = data.cluster.name;
            } else if (filter.groupBy.toLowerCase() === 'territory') {
              name = data.cluster.territory.name;
            } else if (filter.groupBy.toLowerCase() === 'region') {
              name = data.cluster.region.name;
            }
            outletsMapPrevious.set(outlet.id, {
              volume: newVolume,
              location: data.cluster.region.name,
              compareId,
              name,
            });
          });
          newOutletsPreviousPeriod += data.newOutlets;
        }
      }
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [key, value] of outletsMap) {
    let idExists = false;
    for (const element of dataCurrentPeriod) {
      if (element.id === value.compareId) {
        switch (value.location.toLowerCase()) {
          case 'lagos':
          case 'west':
          case 'north':
            if (value.volume > 0) {
              if (value.volume <= 399) {
                semiProductiveOutletsCurrentPeriod++;
                element.semiProductiveOutlets++;
              } else if (value.volume > 399) {
                productiveOutletsCurrentPeriod++;
                element.productiveOutlets++;
              }
            } else {
              element.notActiveOutlets++;
            }
            break;
          case 'east':
            if (value.volume > 0) {
              if (value.volume <= 599) {
                semiProductiveOutletsCurrentPeriod++;
                element.semiProductiveOutlets++;
              } else if (value.volume > 599) {
                productiveOutletsCurrentPeriod++;
                element.productiveOutlets++;
              }
            } else {
              element.notActiveOutlets++;
            }
            break;
          default:
            console.log('default');
            break;
        }
        idExists = true;
      }
    }
    if (!idExists) {
      // not found in array, add new element
      let semiProductiveOutlets = 0;
      let productiveOutlets = 0;
      let notActiveOutlets = 0;

      switch (value.location.toLowerCase()) {
        case 'lagos':
        case 'west':
        case 'north':
          if (value.volume > 0) {
            if (value.volume <= 399) {
              semiProductiveOutletsCurrentPeriod++;
              semiProductiveOutlets++;
            } else if (value.volume > 399) {
              productiveOutletsCurrentPeriod++;
              productiveOutlets++;
            }
          } else {
            notActiveOutlets++;
          }
          break;
        case 'east':
          if (value.volume > 0) {
            if (value.volume <= 599) {
              semiProductiveOutletsCurrentPeriod++;
              semiProductiveOutlets++;
            } else if (value.volume > 599) {
              productiveOutletsCurrentPeriod++;
              productiveOutlets++;
            }
          } else {
            notActiveOutlets++;
          }
          break;
        default:
          console.log('default');
          break;
      }

      dataCurrentPeriod.push({
        id: value.compareId,
        name: value.name,
        semiProductiveOutlets,
        semiProductiveOutletsPercentage: 0,
        productiveOutlets,
        productiveOutletsPercentage: 0,
        notActiveOutlets,
        notActiveOutletsPercentage: 0,
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [key, value] of outletsMapPrevious) {
    let idExists = false;
    for (const element of dataPreviousPeriod) {
      if (element.id === value.compareId) {
        // found in array, update values
        switch (value.location.toLowerCase()) {
          case 'lagos':
          case 'west':
          case 'north':
            if (value.volume > 0) {
              if (value.volume <= 399) {
                semiProductiveOutletsPreviousPeriod++;
                element.semiProductiveOutlets++;
              } else if (value.volume > 399) {
                productiveOutletsPreviousPeriod++;
                element.productiveOutlets++;
              }
            } else {
              element.notActiveOutlets++;
            }
            break;
          case 'east':
            if (value.volume > 0) {
              if (value.volume <= 599) {
                semiProductiveOutletsPreviousPeriod++;
                element.semiProductiveOutlets++;
              } else if (value.volume > 599) {
                productiveOutletsPreviousPeriod++;
                element.productiveOutlets++;
              }
            } else {
              element.notActiveOutlets++;
            }
            break;
          default:
            console.log('default');
            break;
        }
        idExists = true;
      }
    }
    if (!idExists) {
      // not found in array, add new element
      let semiProductiveOutlets = 0;
      let productiveOutlets = 0;
      let notActiveOutlets = 0;

      switch (value.location.toLowerCase()) {
        case 'lagos':
        case 'west':
        case 'north':
          if (value.volume > 0) {
            if (value.volume <= 399) {
              semiProductiveOutletsPreviousPeriod++;
              semiProductiveOutlets++;
            } else if (value.volume > 399) {
              productiveOutletsPreviousPeriod++;
              productiveOutlets++;
            }
          } else {
            notActiveOutlets++;
          }
          break;
        case 'east':
          if (value.volume > 0) {
            if (value.volume <= 599) {
              semiProductiveOutletsPreviousPeriod++;
              semiProductiveOutlets++;
            } else if (value.volume > 599) {
              productiveOutletsPreviousPeriod++;
              productiveOutlets++;
            }
          } else {
            notActiveOutlets++;
          }
          break;
        default:
          console.log('default');
          break;
      }
      dataPreviousPeriod.push({
        id: value.compareId,
        name: value.name,
        semiProductiveOutlets,
        semiProductiveOutletsPercentage: 0,
        productiveOutlets,
        productiveOutletsPercentage: 0,
        notActiveOutlets,
        notActiveOutletsPercentage: 0,
      });
    }
  }

  headerDataCurrentPeriod.push(
    {
      name: 'Registered',
      value: registeredOutletsCurrentPeriod,
    },
    {
      name: 'Active',
      value: semiProductiveOutletsCurrentPeriod + productiveOutletsCurrentPeriod,
    },
    {
      name: 'Productive',
      value: productiveOutletsCurrentPeriod,
    },
    {
      name: 'New',
      value: newOutletsCurrentPeriod,
    }
  );

  headerDataPreviousPeriod.push(
    {
      name: 'Registered',
      value: registeredOutletsPreviousPeriod,
    },
    {
      name: 'Active',
      value: semiProductiveOutletsPreviousPeriod + productiveOutletsPreviousPeriod,
    },
    {
      name: 'Productive',
      value: productiveOutletsPreviousPeriod,
    },
    {
      name: 'New',
      value: newOutletsPreviousPeriod,
    }
  );

  const performance: PerformanceData[] = [
    {
      name: 'Registered',
      value: calculatePercentageChange(
        registeredOutletsCurrentPeriod,
        registeredOutletsPreviousPeriod
      ),
    },
    {
      name: 'Active',
      value: calculatePercentageChange(
        semiProductiveOutletsCurrentPeriod + productiveOutletsCurrentPeriod,
        semiProductiveOutletsPreviousPeriod + productiveOutletsPreviousPeriod
      ),
    },
    {
      name: 'Productive',
      value: calculatePercentageChange(
        productiveOutletsCurrentPeriod,
        productiveOutletsPreviousPeriod
      ),
    },
  ];

  // calculate percentage for current period
  for (const element of dataCurrentPeriod) {
    element.productiveOutletsPercentage =
      Math.round(
        (element.productiveOutlets /
          (element.productiveOutlets + element.semiProductiveOutlets + element.notActiveOutlets)) *
          100 *
          100
      ) / 100;
    element.semiProductiveOutletsPercentage =
      Math.round(
        (element.semiProductiveOutlets /
          (element.productiveOutlets + element.semiProductiveOutlets + element.notActiveOutlets)) *
          100 *
          100
      ) / 100;
    element.notActiveOutletsPercentage =
      Math.round(
        (element.notActiveOutlets /
          (element.productiveOutlets + element.semiProductiveOutlets + element.notActiveOutlets)) *
          100 *
          100
      ) / 100;

    if (Number.isNaN(element.productiveOutletsPercentage)) {
      element.productiveOutletsPercentage = 0;
    }
    if (Number.isNaN(element.semiProductiveOutletsPercentage)) {
      element.semiProductiveOutletsPercentage = 0;
    }
    if (Number.isNaN(element.notActiveOutletsPercentage)) {
      element.notActiveOutletsPercentage = 0;
    }
  }

  // calculate percentage for previous period
  for (const element of dataPreviousPeriod) {
    element.productiveOutletsPercentage =
      Math.round(
        (element.productiveOutlets /
          (element.productiveOutlets + element.semiProductiveOutlets + element.notActiveOutlets)) *
          100 *
          100
      ) / 100;
    element.semiProductiveOutletsPercentage =
      Math.round(
        (element.semiProductiveOutlets /
          (element.productiveOutlets + element.semiProductiveOutlets + element.notActiveOutlets)) *
          100 *
          100
      ) / 100;
    element.notActiveOutletsPercentage =
      Math.round(
        (element.notActiveOutlets /
          (element.productiveOutlets + element.semiProductiveOutlets + element.notActiveOutlets)) *
          100 *
          100
      ) / 100;

    if (Number.isNaN(element.productiveOutletsPercentage)) {
      element.productiveOutletsPercentage = 0;
    }
    if (Number.isNaN(element.semiProductiveOutletsPercentage)) {
      element.semiProductiveOutletsPercentage = 0;
    }
    if (Number.isNaN(element.notActiveOutletsPercentage)) {
      element.notActiveOutletsPercentage = 0;
    }
  }

  return [
    headerDataCurrentPeriod,
    dataCurrentPeriod,
    headerDataPreviousPeriod,
    dataPreviousPeriod,
    performance,
  ];
};

export default computeOutlets;
