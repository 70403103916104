import SitesList from '../blocks/SitesList';

interface LeftCenterProps {
  active: boolean | null;
  searchText?: string;
  setSelectedSiteId: (id: string) => void;
}

const LefCenter: React.FC<LeftCenterProps> = ({ active, searchText, setSelectedSiteId }) => (
  <div className="w-1/2 flex flex-col items-center">
    <SitesList searchText={searchText} active={active} setSelectedSiteId={setSelectedSiteId} />
  </div>
);

export default LefCenter;
