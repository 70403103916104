import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { useUser } from '../../../../common/hooks';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import CloseButton from '../../../../components/CloseButton';
import Input from '../../../../components/Form/Input';
import Cluster from '../components/Cluster';
import Reassign from './Reassign';
import updateUser from '../../../../common/db/users';
import Modal from '../../../../components/Modal';
import useSessions from '../../../../common/hooks/useSessions';
import { convertTimestampToDate } from '../../../../common/utils/analytics';

interface UserDetailsProps {
  selectedUserId: string;
  setSelectedUserId: (id: string | undefined) => void;
}

interface UserDetailsForm {
  name: string;
  phone: string;
}

const UserDetails: React.FC<UserDetailsProps> = ({ selectedUserId, setSelectedUserId }) => {
  const { error, loading, user } = useUser(selectedUserId);
  const {
    sessions,
    reset: resetSession,
    deleteSession,
  } = useSessions(selectedUserId, { pageSize: 100 });

  const { register, reset, handleSubmit } = useForm<UserDetailsForm>();
  const [showReassign, setShowReassign] = useState<boolean>(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState<boolean>(false);
  const [showSessions, setShowSessions] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      reset({
        phone: user.phone,
        name: user.name,
      });
    }
    if (user?.roles.includes('retailer')) {
      resetSession();
    }
  }, [user]);

  useEffect(() => {
    console.log(sessions);
  }, [sessions]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  const circleStyle = clsx('h-5 w-5 rounded-full', user?.active ? 'bg-accent5' : 'bg-accent2');

  const circleText = useMemo(() => (user?.active ? 'Active' : 'Inactive'), [user]);

  const onSubmit = (data: UserDetailsForm) => {
    if (user?.id) {
      updateUser(user.id, {
        name: data.name,
        phone: data.phone,
      });
      setSelectedUserId(undefined);
    }
  };

  const handleShowReassign = (open: boolean) => {
    setShowReassign(open);
  };

  const reassignButtonText = useMemo(() => {
    switch (user?.roles[0]) {
      case 'fsa':
        return 'Reassign Outlets to...';
      case 'tae':
        return 'Reassign Sites to...';
      default:
        return 'Reassign to...';
    }
  }, [user]);

  const handleOnClose = () => setSelectedUserId(undefined);

  return (
    <>
      <Card className="w-1/2 md:w-full flex flex-col">
        <div className="flex justify-between">
          <div className="w-10 h-10 flex justify-center items-center">
            <span title={circleText} className={circleStyle} />
          </div>
          <CloseButton onClick={handleOnClose} className="max-w-min self-end" />
        </div>
        {!user && !loading && !error ? (
          <div className="text-center flex justify-between">Select a user to view details</div>
        ) : (
          <>
            <div className="flex flex-col">
              <h1 className="mb-4 font-thin font-nunito">{user?.id}</h1>
              <form className="w-full flex justify-between flex-wrap">
                <Input
                  type="text"
                  name="name"
                  register={register}
                  className="flex-1/2 font-bold font-outfit text-2xl mb-2"
                />
                <Input type="text" name="phone" register={register} />
              </form>
              <h2 className="font-bold mt-3">Roles</h2>
              <p className="uppercase">{user?.roles.join(', ')}</p>
              {user?.sapId && (
                <>
                  <h2 className="font-bold mt-3">SAP ID</h2>
                  <p>{user?.sapId}</p>
                </>
              )}

              {user?.cluster && <Cluster cluster={user.cluster} />}
              {user?.clusters &&
                user.clusters.map(cluster => <Cluster key={cluster.id} cluster={cluster} />)}
            </div>
            <Button color="accent1" className="my-2" text="Save" onClick={handleSubmit(onSubmit)} />
            <div className="flex justify-between">
              <Button
                className="flex-grow mr-2"
                color="accent3"
                text="Change cluster"
                onClick={() => {
                  throw new Error('Not implemented');
                }}
              />
              <Button
                className="flex-grow mx-2"
                color="accent3"
                text={reassignButtonText}
                onClick={() => {
                  handleShowReassign(true);
                }}
              />
              <Button
                className="flex-grow ml-2"
                color="accent2"
                text="Deactivate"
                onClick={() => {
                  setShowDeactivateModal(true);
                }}
              />
            </div>
            {sessions && sessions.length > 0 && user?.roles.includes('retailer') && (
              <div className="flex justify-between my-2">
                <Button
                  className="flex-grow"
                  color="accent3"
                  text={`Show Sessions (${sessions?.length})`}
                  onClick={() => {
                    setShowSessions(true);
                  }}
                />
              </div>
            )}
          </>
        )}
        <Modal open={showDeactivateModal} setOpen={setShowDeactivateModal}>
          <p>Are you sure you want to deactivate this user?</p>
          <div className="flex justify-between">
            <Button
              className="flex-grow mr-2"
              color="accent2"
              text="Yes"
              onClick={() => {
                if (user?.id)
                  updateUser(user.id, {
                    active: false,
                  });
                setShowDeactivateModal(false);
              }}
            />
            <Button
              className="flex-grow ml-2"
              color="accent1"
              text="No"
              onClick={() => {
                setShowDeactivateModal(false);
              }}
            />
          </div>
        </Modal>
      </Card>
      <Reassign user={user} open={showReassign} setOpen={handleShowReassign} />
      <Modal open={showSessions} setOpen={setShowSessions}>
        <div className="w-full">
          <p className="font-bold">All Sessions ({sessions.length})</p>
          <div className="flex justify-between">
            <p className="font-semibold">Created At</p>
            <p>Status</p>
            <p>Finished At</p>
          </div>
          {sessions &&
            sessions.length > 0 &&
            sessions.map(session => (
              <div key={session.id} className="flex justify-between">
                <p>{convertTimestampToDate(session.createdAt).toLocaleString()}</p>
                <p>{session.status}</p>
                {session.finishedAt === null ? (
                  <Button
                    className="inline-flex text-sm font-normal"
                    color="accent3"
                    text="Delete Session"
                    onClick={() => {
                      if (session.id) {
                        deleteSession(session.id);
                        resetSession();
                      }
                    }}
                  />
                ) : (
                  <p>{convertTimestampToDate(session.finishedAt).toLocaleString()}</p>
                )}
              </div>
            ))}
        </div>
      </Modal>
    </>
  );
};

export default UserDetails;
