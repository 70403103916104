import { ProtectedRoute } from '../../components/Routing';
import Users from './Users';

const UsersRoute = {
  path: 'users',
  element: (
    <ProtectedRoute roles={['admin']}>
      <Users />
    </ProtectedRoute>
  ),
};

export default UsersRoute;
