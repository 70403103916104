/* eslint-disable jsx-a11y/label-has-associated-control */
import { collection, getDocs } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { FaFilter } from 'react-icons/fa';
import Select from 'react-select';
import dayjs from 'dayjs';
import { firestore } from '../../common/utils/firebase';
import Modal from '../Modal';
import useStore from '../../common/hooks/useStore';

const Filter: React.FC = () => {
  // TODO: save state of filter when page is refreshed or in local storage
  const [state, dispatch] = useStore();

  const [open, setOpen] = useState(false);

  const groupByOptions = [
    { value: 'Region', label: 'Region' },
    { value: 'Territory', label: 'Territory' },
    { value: 'Cluster', label: 'Cluster' },
    { value: 'FST', label: 'FST' },
  ];

  const [filteredGroupBy, setFilteredGroupBy] = useState(groupByOptions[0].value);

  type LocationWithParent = {
    name: string;
    parent: string;
  };

  const [locationsData, setLocationsData] = useState({
    clusters: new Map<string, LocationWithParent>(),
    regions: new Map<string, string>(),
    territories: new Map<string, LocationWithParent>(),
  });

  const [selectedDateRange, setSelectedDateRange] = useState({
    currentPeriod: {
      startDate: dayjs().startOf('month').toDate(),
      endDate: dayjs().toDate(),
      key: 'currentPeriod',
    },
    previousPeriod: {
      startDate: dayjs().subtract(1, 'month').startOf('month').toDate(),
      endDate: dayjs().subtract(1, 'month').toDate(),
      key: 'previousPeriod',
    },
  });

  const [lastPeriodOption, setLastPeriodOption] = useState('period-before');

  const [territoryOptions, setTerritoryOptions] = useState([{}]);
  const [regionOptions, setRegionOptions] = useState([{}]);
  const [clusterOptions, setClusterOptions] = useState([{}]);

  const [filteredRegionOptions, setFilteredRegionOptions]: any = useState([]);
  const [filteredTerritoryOptions, setFilteredTerritoryOptions]: any = useState([]);
  const [filteredClusterOptions, setFilteredClusterOptions]: any = useState([]);

  const [filteredRegionsText, setFilteredRegionsText] = useState('All');
  const [filteredTerritoriesText, setFilteredTerritoriesText] = useState('All');
  const [filteredClustersText, setFilteredClustersText] = useState('All');

  function updateRegionFilterBasedOnSelection(
    selectedOptions: any,
    currentFilter: Map<string, string>
  ) {
    currentFilter.clear();
    const newTerritories: any[] = [];
    selectedOptions.forEach((option: any) => {
      currentFilter.set(option.key, option.value);
      // update territory options based on selected region
      locationsData.territories.forEach((territory, key) => {
        if (option.key === territory.parent) {
          newTerritories.push({
            value: territory.name,
            label: territory.name,
            key: key.toString(),
          });
        }
      });
    });
    setTerritoryOptions(newTerritories);
    setFilteredRegionOptions(selectedOptions);
    if (selectedOptions.length === 0) {
      setFilteredGroupBy('Region');
      setFilteredTerritoryOptions([]);
      dispatch('SET_FILTERED_TERRITORIES', new Map<string, string>());
      setFilteredClusterOptions([]);
      dispatch('SET_FILTERED_CLUSTERS', new Map<string, string>());
    } else if (selectedOptions.length > 0) {
      setFilteredGroupBy('Territory');
    }

    dispatch('SET_FILTERED_REGIONS', currentFilter);
  }

  function updateTerritoryFilterBasedOnSelection(
    selectedOptions: any,
    currentFilter: Map<string, string>
  ) {
    currentFilter.clear();
    const newClusters: any[] = [];
    selectedOptions.forEach((option: any) => {
      currentFilter.set(option.key, option.value);
      // update cluster options based on selected territory
      locationsData.clusters.forEach((cluster, key) => {
        if (option.key === cluster.parent) {
          newClusters.push({
            value: cluster.name,
            label: cluster.name,
            key: key.toString(),
          });
        }
      });
    });
    setClusterOptions(newClusters);
    setFilteredTerritoryOptions(selectedOptions);
    if (selectedOptions.length === 0) {
      setFilteredGroupBy('Territory');
      setFilteredClusterOptions([]);
      dispatch('SET_FILTERED_CLUSTERS', new Map<string, string>());
    } else if (selectedOptions.length > 0) {
      setFilteredGroupBy('Cluster');
    }

    dispatch('SET_FILTERED_TERRITORIES', currentFilter);
  }

  function updateClusterFilterBasedOnSelection(
    selectedOptions: any,
    currentFilter: Map<string, string>
  ) {
    currentFilter.clear();
    selectedOptions.forEach((option: any) => {
      currentFilter.set(option.key, option.value);
    });
    setFilteredClusterOptions(selectedOptions);
    if (selectedOptions.length === 0) {
      setFilteredGroupBy('Cluster');
      setFilteredClusterOptions([]);
    } else if (selectedOptions.length > 0) {
      setFilteredGroupBy('FST');
    }
    dispatch('SET_FILTERED_CLUSTERS', currentFilter);
  }

  function setOptionsFromFilterVariable() {
    const newRegions: any[] = [];
    locationsData.regions.forEach((region, key) => {
      newRegions.push({ value: region, label: region, key: key.toString() });
    });

    const newTerritories: any[] = [];
    locationsData.territories.forEach((territory, key) => {
      newTerritories.push({
        value: territory,
        label: territory,
        key: key.toString(),
      });
    });

    const newClusters: any[] = [];
    locationsData.clusters.forEach((cluster, key) => {
      newClusters.push({ value: cluster, label: cluster, key: key.toString() });
    });

    setRegionOptions(newRegions);
    setTerritoryOptions(newTerritories);
    setClusterOptions(newClusters);
  }

  useEffect(() => {
    const docRef = collection(firestore, 'clusters');

    const newLocationsData = {
      clusters: new Map<string, LocationWithParent>(),
      regions: new Map<string, string>(),
      territories: new Map<string, LocationWithParent>(),
    };

    newLocationsData.regions = new Map<string, string>();
    newLocationsData.territories = new Map<string, LocationWithParent>();
    newLocationsData.clusters = new Map<string, LocationWithParent>();

    const getLocationData = async () => {
      const docSnap = await getDocs(docRef);
      docSnap.forEach(doc => {
        const data = doc.data();
        if (
          data.region.id === 'ABy89onIhjw83RGqYYzO' ||
          data.region.id === 'btrX2khDWIegvT3XCICR' ||
          data.region.id === 'tueVqSWqCmx2wt7Hkcpm' ||
          data.region.id === 'y5lG5IpWSORvj18oaOOI'
        ) {
          const { id } = doc;
          newLocationsData.regions.set(data.region.id, data.region.name);
          newLocationsData.territories.set(data.territory.id, {
            name: data.territory.name,
            parent: data.region.id,
          });
          newLocationsData.clusters.set(id, {
            name: data.name,
            parent: data.territory.id,
          });
        }
      });
    };
    getLocationData().then(() => {
      setLocationsData({
        regions: newLocationsData.regions,
        territories: newLocationsData.territories,
        clusters: newLocationsData.clusters,
      });
    });
  }, []);

  useEffect(() => {
    setOptionsFromFilterVariable();

    const { filteredRegions } = state;
    const { filteredTerritories } = state;
    const { filteredClusters } = state;

    dispatch('SET_FILTER', {
      ...state.filter,
      regions: filteredRegions,
      territories: filteredTerritories,
      clusters: filteredClusters,
    });
  }, [locationsData]);

  function setFilteredOptions(
    filteredRegions: Map<string, string>,
    filteredTerritories: Map<string, string>,
    filteredClusters: Map<string, string>
  ) {
    const myFilteredRegions: any[] = [];
    const myFilteredTerritories: any[] = [];
    const myFilteredClusters: any[] = [];
    filteredRegions.forEach((region, key) => {
      myFilteredRegions.push({ value: region, label: region, key: key.toString() });
    });
    filteredTerritories.forEach((territory, key) => {
      myFilteredTerritories.push({
        value: territory,
        label: territory,
        key: key.toString(),
      });
    });
    filteredClusters.forEach((cluster, key) => {
      myFilteredClusters.push({ value: cluster, label: cluster, key: key.toString() });
    });

    if (myFilteredRegions.length !== locationsData.regions.size && myFilteredRegions.length !== 0) {
      setFilteredRegionOptions(myFilteredRegions);
    }
    if (
      myFilteredTerritories.length !== locationsData.territories.size &&
      myFilteredTerritories.length !== 0
    ) {
      setFilteredTerritoryOptions(myFilteredTerritories);
    }
    if (
      myFilteredClusters.length !== locationsData.clusters.size &&
      myFilteredClusters.length !== 0
    ) {
      setFilteredClusterOptions(myFilteredClusters);
    }
  }

  function setDateRange(item: any): void {
    if (item.currentPeriod) {
      const range = item.currentPeriod;
      const startDate = new Date(range.startDate);
      const endDate = new Date(range.endDate);

      // get amount of months in range
      let months = dayjs(endDate).diff(dayjs(startDate), 'month');
      months += 1;

      if (lastPeriodOption === 'period-before') {
        setSelectedDateRange(() => ({
          ...selectedDateRange,
          currentPeriod: {
            startDate,
            endDate,
            key: 'currentPeriod',
          },
          previousPeriod: {
            startDate: dayjs(startDate).subtract(months, 'months').toDate(),
            endDate: dayjs(endDate).subtract(months, 'months').toDate(),
            key: 'previousPeriod',
          },
        }));
      } else if (lastPeriodOption === 'last-year') {
        setSelectedDateRange(() => ({
          ...selectedDateRange,
          currentPeriod: {
            startDate,
            endDate,
            key: 'currentPeriod',
          },
          previousPeriod: {
            startDate: dayjs(startDate).subtract(1, 'year').toDate(),
            endDate: dayjs(endDate).subtract(1, 'year').toDate(),
            key: 'previousPeriod',
          },
        }));
      }
    } else if (item.previousPeriod) {
      const range = item.previousPeriod;
      const startDate = new Date(range.startDate);
      const endDate = new Date(range.endDate);
      setSelectedDateRange(() => ({
        ...selectedDateRange,
        previousPeriod: {
          startDate,
          endDate,
          key: 'previousPeriod',
        },
      }));
    }
  }

  function createFilterText(map: Map<string, string>) {
    let text = '';
    map.forEach(region => {
      text += `${region}, `;
    });
    text = text.substring(0, text.length - 2);
    return text;
  }

  function setDataFilter(): void {
    if (open) {
      setOpen(false);
    }

    setFilteredOptions(state.filteredRegions, state.filteredTerritories, state.filteredClusters);

    if (state.filteredRegions.size > 0 && state.filteredRegions.size < locationsData.regions.size) {
      const text = createFilterText(state.filteredRegions);
      setFilteredRegionsText(text);
    } else {
      setFilteredRegionsText('All');
    }

    if (
      state.filteredTerritories.size > 0 &&
      state.filteredTerritories.size < locationsData.territories.size
    ) {
      const text = createFilterText(state.filteredTerritories);
      setFilteredTerritoriesText(text);
    } else {
      setFilteredTerritoriesText('All');
    }

    if (
      state.filteredClusters.size > 0 &&
      state.filteredClusters.size < locationsData.clusters.size
    ) {
      const text = createFilterText(state.filteredClusters);
      setFilteredClustersText(text);
    } else {
      setFilteredClustersText('All');
    }

    dispatch('SET_FILTER', {
      dateRange1: {
        startDate: selectedDateRange.currentPeriod.startDate,
        endDate: selectedDateRange.currentPeriod.endDate,
      },
      dateRange2: {
        startDate: selectedDateRange.previousPeriod.startDate,
        endDate: selectedDateRange.previousPeriod.endDate,
      },
      territories: state.filteredTerritories,
      regions: state.filteredRegions,
      clusters: state.filteredClusters,
      groupBy: filteredGroupBy || 'region',
    });
  }

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="rounded-md w-full bg-secondary shadow text-primary py-2 font-outfit font-medium text-sm flex items-center justify-center gap-2 mb-5"
      >
        Filter Data
        <FaFilter />
      </button>

      <div className="grid grid-cols-1 md:grid-cols-6 gap-1 text-sm">
        <div className="p-5 shadow rounded-md bg-white md:col-span-2">
          <b>Date Range</b>
          <br />
          {`${state.filter.dateRange1.startDate.toDateString()} - ${state.filter.dateRange1.endDate.toDateString()}`}
          <br />
          {`${state.filter.dateRange2.startDate.toDateString()} - ${state.filter.dateRange2.endDate.toDateString()}`}
        </div>
        <div className="p-5 shadow rounded-md bg-white">
          <b>Group by</b>
          <br />
          {state.filter.groupBy}
        </div>
        <div className="p-5 shadow rounded-md bg-white">
          <b>Regions</b>
          <br />
          {filteredRegionsText}
        </div>
        <div className="p-5 shadow rounded-md bg-white">
          <b>Territories</b>
          <br />
          {filteredTerritoriesText}
        </div>
        <div className="p-5 shadow rounded-md bg-white">
          <b>Cluster</b>
          <br />
          {filteredClustersText}
        </div>
      </div>

      <Modal open={open} setOpen={setOpen} block>
        <h1 className="font-outfit font-medium text-xl mb-5">Filter</h1>

        <label className="block text-sm font-medium text-gray-700" htmlFor="dateRangePicker">
          Date Range
        </label>
        <div className="mb-5">
          <div className="text-center">
            <DateRangePicker
              onChange={item => setDateRange(item)}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={[selectedDateRange.currentPeriod, selectedDateRange.previousPeriod]}
              direction="horizontal"
              minDate={new Date('2022-05-01')}
              maxDate={new Date()}
              staticRanges={[]}
              inputRanges={[]}
            />
          </div>
          <label className="block text-sm font-medium text-gray-700">
            Previous period calculation method
          </label>
          <div className="flex justify-center gap-5 mt-5">
            <div className="flex items-center gap-2">
              <input
                type="radio"
                name="period"
                id="period-before"
                value="period-before"
                checked={lastPeriodOption === 'period-before'}
                onChange={() => {
                  setLastPeriodOption('period-before');
                }}
              />
              <label htmlFor="period-before">Period Before Current Period</label>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="radio"
                name="period"
                id="last-year"
                value="last-year"
                checked={lastPeriodOption === 'last-year'}
                onChange={() => {
                  setLastPeriodOption('last-year');
                }}
              />
              <label htmlFor="last-year">Last Year</label>
            </div>
          </div>
        </div>

        <label className="block text-sm font-medium text-gray-700">Regions</label>
        <div className="mb-5">
          <Select
            defaultValue={filteredRegionOptions}
            options={regionOptions}
            isMulti
            onChange={selectedOptions => {
              updateRegionFilterBasedOnSelection(selectedOptions, state.filteredRegions);
            }}
          />
        </div>

        {filteredRegionOptions && filteredRegionOptions.length > 0 && (
          <>
            <label className="block text-sm font-medium text-gray-700">Territories</label>
            <div className="mb-5">
              <Select
                defaultValue={filteredTerritoryOptions}
                options={territoryOptions}
                isMulti
                onChange={selectedOptions => {
                  updateTerritoryFilterBasedOnSelection(selectedOptions, state.filteredTerritories);
                }}
              />
            </div>
          </>
        )}

        {filteredTerritoryOptions && filteredTerritoryOptions.length > 0 && (
          <>
            <label className="block text-sm font-medium text-gray-700">Clusters</label>
            <div className="mb-5">
              <Select
                defaultValue={filteredClusterOptions}
                options={clusterOptions}
                isMulti
                onChange={selectedOptions => {
                  updateClusterFilterBasedOnSelection(selectedOptions, state.filteredClusters);
                }}
              />
            </div>
          </>
        )}

        <button
          type="button"
          className="rounded-md w-full bg-secondary text-primary py-2 font-outfit font-medium text-sm"
          onClick={setDataFilter}
        >
          <span>Set Filter</span>
        </button>
      </Modal>
    </>
  );
};

export default Filter;
