import { ProtectedRoute } from '../../components/Routing';
import Home from './Home';

const HomeRoute = {
  path: 'home',
  element: (
    <ProtectedRoute>
      <Home />
    </ProtectedRoute>
  ),
};

export default HomeRoute;
