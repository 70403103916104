import clsx from 'clsx';
import { useMemo } from 'react';
import { TypesenseUserType } from '../../../../common/types';
import Card from '../../../../components/Card';

interface UserItemProps {
  user: TypesenseUserType;
  onClick: (id: string) => void;
}

const UserItem: React.FC<UserItemProps> = ({ user, onClick }) => {
  const circleStyle = clsx('h-5 w-5 rounded-full', user.active ? 'bg-accent5' : 'bg-accent2');
  const circleText = useMemo(() => (user.active ? 'Active' : 'Inactive'), [user]);
  return (
    <Card
      onClick={() => onClick(user.id)}
      className="md:w-full border-none shadow-none flex justify-between hover:bg-accent1 hover:text-primary hover:cursor-pointer"
    >
      <div className="h-full w-10 flex items-center">
        <span title={circleText} className={circleStyle} />
      </div>
      <div className="flex flex-grow justify-between flex-wrap">
        <h1 className="flex-1/2 font-bold font-outfit text-2xl">{user.name}</h1>
        <h2 className="flex-1/2 text-right font-medium text-lg uppercase">
          {user?.roles.join(', ')}
        </h2>
        <h3 className="flex-1/2 font-thin">{user.phone}</h3>
        <h3 className="flex-1/2 font-thin text-right">{user['cluster.name']}</h3>
      </div>
    </Card>
  );
};

export default UserItem;
