import dayjs from 'dayjs';
import { AnalyticsType, FilterOptions, PerformanceData } from '../../../types/Analytics';
import {
  calculatePercentageChange,
  checkIfDataIsInFilter,
  convertTimestampToDate,
  getCompareId,
} from '../helperFunctions';

type ChatbotData = {
  id: string;
  name: string;
  fulfilled: {
    chatbot: number;
    chatbotPercentage: number;
    fsa: number;
    fsaPercentage: number;
  };
};

const computeChatbotAdoption = (analyticsData: AnalyticsType[], filter: FilterOptions) => {
  if (analyticsData.length > 0) {
    const dataPreviousPeriod: ChatbotData[] = [];
    const dataCurrentPeriod: ChatbotData[] = [];
    const headerDataPreviousPeriod: any = [];
    const headerDataCurrentPeriod: any = [];
    let chatbotSalesCurrentPeriod: number = 0;
    let chatbotSalesPreviousPeriod: number = 0;
    let fsaSalesCurrentPeriod: number = 0;
    let fsaSalesPreviousPeriod: number = 0;

    const currentPeriodStartDate = filter.dateRange1.startDate;
    const currentPeriodEndDate = dayjs(filter.dateRange1.endDate).add(1, 'day').toDate();
    const previousPeriodStartDate = filter.dateRange2.startDate;
    const previousPeriodEndDate = dayjs(filter.dateRange2.endDate).add(1, 'day').toDate();

    analyticsData.forEach(data => {
      const isInFilter = checkIfDataIsInFilter(data, filter);

      if (isInFilter) {
        const date = convertTimestampToDate(data.date);
        const compareId = getCompareId(data, filter);

        if (date >= currentPeriodStartDate && date <= currentPeriodEndDate) {
          // compute header data
          chatbotSalesCurrentPeriod += data.volumeFulfilled.chatbot.classic
            ? data.volumeFulfilled.chatbot.classic
            : 0;
          chatbotSalesCurrentPeriod += data.volumeFulfilled.chatbot.supaset
            ? data.volumeFulfilled.chatbot.supaset
            : 0;
          chatbotSalesCurrentPeriod += data.volumeFulfilled.chatbot.supafixc0
            ? data.volumeFulfilled.chatbot.supafixc0
            : 0;
          chatbotSalesCurrentPeriod += data.volumeFulfilled.chatbot.supafixc1
            ? data.volumeFulfilled.chatbot.supafixc1
            : 0;
          fsaSalesCurrentPeriod += data.volumeFulfilled.app.classic
            ? data.volumeFulfilled.app.classic
            : 0;
          fsaSalesCurrentPeriod += data.volumeFulfilled.app.supaset
            ? data.volumeFulfilled.app.supaset
            : 0;
          fsaSalesCurrentPeriod += data.volumeFulfilled.app.supafixc0
            ? data.volumeFulfilled.app.supafixc0
            : 0;
          fsaSalesCurrentPeriod += data.volumeFulfilled.app.supafixc1
            ? data.volumeFulfilled.app.supafixc1
            : 0;

          // if cementData is not empty, check if id exists
          let idExists = false;
          for (let i = 0; i < dataCurrentPeriod.length; i++) {
            if (dataCurrentPeriod[i].id === compareId) {
              // if id exists, update entry
              dataCurrentPeriod[i].fulfilled.chatbot += data.volumeFulfilled.chatbot.classic
                ? data.volumeFulfilled.chatbot.classic
                : 0;
              dataCurrentPeriod[i].fulfilled.chatbot += data.volumeFulfilled.chatbot.supaset
                ? data.volumeFulfilled.chatbot.supaset
                : 0;
              dataCurrentPeriod[i].fulfilled.chatbot += data.volumeFulfilled.chatbot.supafixc0
                ? data.volumeFulfilled.chatbot.supafixc0
                : 0;
              dataCurrentPeriod[i].fulfilled.chatbot += data.volumeFulfilled.chatbot.supafixc1
                ? data.volumeFulfilled.chatbot.supafixc1
                : 0;
              dataCurrentPeriod[i].fulfilled.fsa += data.volumeFulfilled.app.classic
                ? data.volumeFulfilled.app.classic
                : 0;
              dataCurrentPeriod[i].fulfilled.fsa += data.volumeFulfilled.app.supaset
                ? data.volumeFulfilled.app.supaset
                : 0;
              dataCurrentPeriod[i].fulfilled.fsa += data.volumeFulfilled.app.supafixc0
                ? data.volumeFulfilled.app.supafixc0
                : 0;
              dataCurrentPeriod[i].fulfilled.fsa += data.volumeFulfilled.app.supafixc1
                ? data.volumeFulfilled.app.supafixc1
                : 0;
              idExists = true;
            }
          }
          if (!idExists) {
            let chatbot = 0;
            let fsa = 0;
            chatbot += data.volumeFulfilled.chatbot.classic
              ? data.volumeFulfilled.chatbot.classic
              : 0;
            chatbot += data.volumeFulfilled.chatbot.supaset
              ? data.volumeFulfilled.chatbot.supaset
              : 0;
            chatbot += data.volumeFulfilled.chatbot.supafixc0
              ? data.volumeFulfilled.chatbot.supafixc0
              : 0;
            chatbot += data.volumeFulfilled.chatbot.supafixc1
              ? data.volumeFulfilled.chatbot.supafixc1
              : 0;
            fsa += data.volumeFulfilled.app.classic ? data.volumeFulfilled.app.classic : 0;
            fsa += data.volumeFulfilled.app.supaset ? data.volumeFulfilled.app.supaset : 0;
            fsa += data.volumeFulfilled.app.supafixc0 ? data.volumeFulfilled.app.supafixc0 : 0;
            fsa += data.volumeFulfilled.app.supafixc1 ? data.volumeFulfilled.app.supafixc1 : 0;

            const fulfilled = {
              chatbot,
              fsa,
              chatbotPercentage: 0,
              fsaPercentage: 0,
            };
            // if id does not exist, push new entry
            if (filter.groupBy.toLowerCase() === 'region') {
              dataCurrentPeriod.push({
                id: data.cluster.region.id,
                name: data.cluster.region.name,
                fulfilled,
              });
            }
            if (filter.groupBy.toLowerCase() === 'territory') {
              dataCurrentPeriod.push({
                id: data.cluster.territory.id,
                name: data.cluster.territory.name,
                fulfilled,
              });
            }
            if (filter.groupBy.toLowerCase() === 'cluster') {
              dataCurrentPeriod.push({
                id: data.cluster.id,
                name: data.cluster.name,
                fulfilled,
              });
            }
            if (filter.groupBy.toLowerCase() === 'fst') {
              dataCurrentPeriod.push({
                id: data.fsa.id,
                name: data.fsa.name,
                fulfilled,
              });
            }
          }
        } else if (date >= previousPeriodStartDate && date <= previousPeriodEndDate) {
          // compute header data
          chatbotSalesPreviousPeriod += data.volumeFulfilled.chatbot.classic
            ? data.volumeFulfilled.chatbot.classic
            : 0;
          chatbotSalesPreviousPeriod += data.volumeFulfilled.chatbot.supaset
            ? data.volumeFulfilled.chatbot.supaset
            : 0;
          chatbotSalesPreviousPeriod += data.volumeFulfilled.chatbot.supafixc0
            ? data.volumeFulfilled.chatbot.supafixc0
            : 0;
          chatbotSalesPreviousPeriod += data.volumeFulfilled.chatbot.supafixc1
            ? data.volumeFulfilled.chatbot.supafixc1
            : 0;
          fsaSalesPreviousPeriod += data.volumeFulfilled.app.classic
            ? data.volumeFulfilled.app.classic
            : 0;
          fsaSalesPreviousPeriod += data.volumeFulfilled.app.supaset
            ? data.volumeFulfilled.app.supaset
            : 0;
          fsaSalesPreviousPeriod += data.volumeFulfilled.app.supafixc0
            ? data.volumeFulfilled.app.supafixc0
            : 0;
          fsaSalesPreviousPeriod += data.volumeFulfilled.app.supafixc1
            ? data.volumeFulfilled.app.supafixc1
            : 0;

          // if cementData is not empty, check if id exists
          let idExists = false;
          for (let i = 0; i < dataPreviousPeriod.length; i++) {
            if (dataPreviousPeriod[i].id === compareId) {
              // if id exists, update entry
              dataPreviousPeriod[i].fulfilled.chatbot += data.volumeFulfilled.chatbot.classic
                ? data.volumeFulfilled.chatbot.classic
                : 0;
              dataPreviousPeriod[i].fulfilled.chatbot += data.volumeFulfilled.chatbot.supaset
                ? data.volumeFulfilled.chatbot.supaset
                : 0;
              dataPreviousPeriod[i].fulfilled.chatbot += data.volumeFulfilled.chatbot.supafixc0
                ? data.volumeFulfilled.chatbot.supafixc0
                : 0;
              dataPreviousPeriod[i].fulfilled.chatbot += data.volumeFulfilled.chatbot.supafixc1
                ? data.volumeFulfilled.chatbot.supafixc1
                : 0;
              dataPreviousPeriod[i].fulfilled.fsa += data.volumeFulfilled.app.classic
                ? data.volumeFulfilled.app.classic
                : 0;
              dataPreviousPeriod[i].fulfilled.fsa += data.volumeFulfilled.app.supaset
                ? data.volumeFulfilled.app.supaset
                : 0;
              dataPreviousPeriod[i].fulfilled.fsa += data.volumeFulfilled.app.supafixc0
                ? data.volumeFulfilled.app.supafixc0
                : 0;
              dataPreviousPeriod[i].fulfilled.fsa += data.volumeFulfilled.app.supafixc1
                ? data.volumeFulfilled.app.supafixc1
                : 0;
              idExists = true;
            }
          }
          if (!idExists) {
            // if id does not exist, push new entry
            let chatbot = 0;
            let fsa = 0;
            chatbot += data.volumeFulfilled.chatbot.classic
              ? data.volumeFulfilled.chatbot.classic
              : 0;
            chatbot += data.volumeFulfilled.chatbot.supaset
              ? data.volumeFulfilled.chatbot.supaset
              : 0;
            chatbot += data.volumeFulfilled.chatbot.supafixc0
              ? data.volumeFulfilled.chatbot.supafixc0
              : 0;
            chatbot += data.volumeFulfilled.chatbot.supafixc1
              ? data.volumeFulfilled.chatbot.supafixc1
              : 0;
            fsa += data.volumeFulfilled.app.classic ? data.volumeFulfilled.app.classic : 0;
            fsa += data.volumeFulfilled.app.supaset ? data.volumeFulfilled.app.supaset : 0;
            fsa += data.volumeFulfilled.app.supafixc0 ? data.volumeFulfilled.app.supafixc0 : 0;
            fsa += data.volumeFulfilled.app.supafixc1 ? data.volumeFulfilled.app.supafixc1 : 0;

            const fulfilled = {
              chatbot,
              fsa,
              chatbotPercentage: 0,
              fsaPercentage: 0,
            };

            if (filter.groupBy.toLowerCase() === 'region') {
              dataPreviousPeriod.push({
                id: data.cluster.region.id,
                name: data.cluster.region.name,
                fulfilled,
              });
            }
            if (filter.groupBy.toLowerCase() === 'territory') {
              dataPreviousPeriod.push({
                id: data.cluster.territory.id,
                name: data.cluster.territory.name,
                fulfilled,
              });
            }
            if (filter.groupBy.toLowerCase() === 'cluster') {
              dataPreviousPeriod.push({
                id: data.cluster.id,
                name: data.cluster.name,
                fulfilled,
              });
            }
            if (filter.groupBy.toLowerCase() === 'fst') {
              dataPreviousPeriod.push({
                id: data.fsa.id,
                name: data.fsa.name,
                fulfilled,
              });
            }
          }
        }
      }
    });

    headerDataCurrentPeriod.push(
      {
        name: 'Chatbot Sales',
        value: Math.round(chatbotSalesCurrentPeriod),
      },
      {
        name: 'FSA Sales',
        value: Math.round(fsaSalesCurrentPeriod),
      },
      {
        name: 'Chatbot Adoption in %',
        value: `${
          Math.round(
            (chatbotSalesCurrentPeriod / (chatbotSalesCurrentPeriod + fsaSalesCurrentPeriod)) *
              100 *
              100
          ) / 100
        }%`,
      }
    );

    headerDataPreviousPeriod.push(
      {
        name: 'Chatbot Sales',
        value: Math.round(chatbotSalesPreviousPeriod),
      },
      {
        name: 'FSA Sales',
        value: Math.round(fsaSalesPreviousPeriod),
      },
      {
        name: 'Chatbot Adoption in %',
        value: `${
          Math.round(
            (chatbotSalesPreviousPeriod / (chatbotSalesPreviousPeriod + fsaSalesPreviousPeriod)) *
              100 *
              100
          ) / 100
        }%`,
      }
    );

    const performance: PerformanceData[] = [
      {
        name: 'Chatbot Sales',
        value: calculatePercentageChange(chatbotSalesCurrentPeriod, chatbotSalesPreviousPeriod),
      },
      {
        name: 'FSA Sales',
        value: calculatePercentageChange(fsaSalesCurrentPeriod, fsaSalesPreviousPeriod),
      },
    ];

    // calculate percentage of fulfilled chatbot and fsa sales
    for (let i = 0; i < dataCurrentPeriod.length; i++) {
      dataCurrentPeriod[i].fulfilled.chatbotPercentage =
        Math.round(
          (dataCurrentPeriod[i].fulfilled.chatbot /
            (dataCurrentPeriod[i].fulfilled.chatbot + dataCurrentPeriod[i].fulfilled.fsa)) *
            100 *
            100
        ) / 100;
      dataCurrentPeriod[i].fulfilled.fsaPercentage =
        Math.round(
          (dataCurrentPeriod[i].fulfilled.fsa /
            (dataCurrentPeriod[i].fulfilled.chatbot + dataCurrentPeriod[i].fulfilled.fsa)) *
            100 *
            100
        ) / 100;

      if (Number.isNaN(dataCurrentPeriod[i].fulfilled.chatbotPercentage)) {
        dataCurrentPeriod[i].fulfilled.chatbotPercentage = 0;
      }
      if (Number.isNaN(dataCurrentPeriod[i].fulfilled.fsaPercentage)) {
        dataCurrentPeriod[i].fulfilled.fsaPercentage = 0;
      }
    }

    for (let i = 0; i < dataPreviousPeriod.length; i++) {
      dataPreviousPeriod[i].fulfilled.chatbotPercentage =
        Math.round(
          (dataPreviousPeriod[i].fulfilled.chatbot /
            (dataPreviousPeriod[i].fulfilled.chatbot + dataPreviousPeriod[i].fulfilled.fsa)) *
            100 *
            100
        ) / 100;
      dataPreviousPeriod[i].fulfilled.fsaPercentage =
        Math.round(
          (dataPreviousPeriod[i].fulfilled.fsa /
            (dataPreviousPeriod[i].fulfilled.chatbot + dataPreviousPeriod[i].fulfilled.fsa)) *
            100 *
            100
        ) / 100;

      if (Number.isNaN(dataPreviousPeriod[i].fulfilled.chatbotPercentage)) {
        dataPreviousPeriod[i].fulfilled.chatbotPercentage = 0;
      }
      if (Number.isNaN(dataPreviousPeriod[i].fulfilled.fsaPercentage)) {
        dataPreviousPeriod[i].fulfilled.fsaPercentage = 0;
      }
    }

    // if is NaN, set to 0

    return [
      headerDataCurrentPeriod,
      dataCurrentPeriod,
      headerDataPreviousPeriod,
      dataPreviousPeriod,
      performance,
    ];
  }
};

export default computeChatbotAdoption;
