import { useMemo } from 'react';
import { SearchParams } from 'typesense/lib/Typesense/Documents';
import { RoleType, TypesenseUserType } from '../types';
import { useAuth } from './auth';
import { usePaginationTypesense } from './utils/usePaginationTypesense';

interface UseUsersOptions {
  searchText?: string;
  pageSize?: number;
  roles?: RoleType[];
}

const useUsers = ({ searchText, pageSize = 10, roles }: UseUsersOptions) => {
  const { typesenseKey } = useAuth();

  const usersQuery: SearchParams | undefined = useMemo(() => {
    if (!searchText) return undefined;

    const query = { q: searchText, query_by: 'name,phone', filter_by: '' };

    if (roles?.length) query.filter_by = `roles: [${roles}]`;

    return query;
  }, [searchText, roles]);

  const { error, hasMore, load, loading, reset, values } =
    usePaginationTypesense<TypesenseUserType>(usersQuery, pageSize, 'users', typesenseKey);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      users: values ? values.map(v => ({ ...v.document, id: v.document.id })) : [],
    }),
    [error, hasMore, loading, values]
  );
};

export default useUsers;
