import { auth } from '../../utils/firebase';

/**
 * A hook that signs out the current user.
 * @returns sign out function
 */
const useSignOut = () => {
  const signOut = async () => {
    await auth.signOut();
  };

  return signOut;
};

export default useSignOut;
