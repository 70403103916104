import { useEffect, useState } from 'react';
import Card from '../../../../components/Card';
import ReassignGroups from '../blocks/ReassignGroups';
import Button from '../../../../components/Button';

const GroupItem = (props: any) => {
  const [groupName, setGroupName] = useState('');
  const [groupFSA, setGroupFSA] = useState<any>();
  const [showReassign, setShowReassign] = useState<boolean>(false);

  const { group } = props;

  useEffect(() => {
    setGroupName(group.name);
    setGroupFSA(group.fsa);
  }, [props]);

  const handleShowReassign = (open: boolean) => {
    setShowReassign(open);
  };

  return (
    <Card className="md:w-full border-none shadow-none flex justify-between flex-wrap">
      <div className="flex justify-between flex-wrap flex-3/4">
        <h1 className="flex-1/2 font-semibold font-outfit text-2xl">{groupName}</h1>
        <h2 className="flex-1/2 text-right font-medium text-lg uppercase">
          <span className="font-thin">FSA: </span>
          {groupFSA?.name}
        </h2>
        <ReassignGroups group={group} open={showReassign} setOpen={handleShowReassign} />
        <Button
          className="flex-grow mx-2"
          color="accent3"
          text="Change FSA"
          onClick={() => {
            handleShowReassign(true);
          }}
        />
      </div>
    </Card>
  );
};

export default GroupItem;
