import React, { useCallback, useEffect, useRef } from 'react';

interface ListProps {
  children: React.ReactNode;
  hasMore?: boolean;
  load?: () => void;
  loading?: boolean;
  className?: string;
}

const List: React.FC<ListProps> = ({ children, hasMore, load, loading, className }) => {
  const loader = useRef(null);

  const handleObserver = useCallback(
    (entires: IntersectionObserverEntry[]) => {
      const target = entires[0];
      if (load && target.isIntersecting && hasMore && !loading) {
        load();
      }
    },
    [hasMore, loading, load]
  );

  useEffect(() => {
    if (!load) return;
    const { current } = loader;

    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (current) observer.observe(current);
    return () => {
      if (current) observer.unobserve(current);
    };
  }, [handleObserver, load]);

  return (
    <ul
      className={`grid grid-col-12 w-full md:min-w-min md:w-1/2 auto-rows-min mx-2 my-4 px-6 py-4 overflow-auto ${className}`}
    >
      {children}
      {hasMore && (
        <li
          ref={loader}
          key="loadmore"
          className="flex justify-center items-center w-full mx-2 h-18 list-none py-2"
        />
      )}
    </ul>
  );
};

export default List;
