/**
 * Function to debounce a function call
 * @param func Function to be executed
 * @param wait Time in milliseconds to wait before executing the function
 * @returns Function
 */
export function debounce<F extends (...args: any[]) => void>(func: F, wait = 100) {
  let timeoutID: any = null;
  return function debounced(this: any, ...args: Parameters<F>) {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => func.apply(this, args), wait);
  } as F;
}

/**
 * Function to debounce an async function call
 * @param func Function to be executed
 * @param wait Time in milliseconds to wait before executing the function
 * @returns Promise
 */
export function asyncDebounce<F extends (...args: any[]) => Promise<any>>(func: F, wait?: number) {
  const debounced = debounce((resolve: any, reject: any, args: Parameters<F>) => {
    func(...args)
      .then(resolve)
      .catch(reject);
  }, wait);

  return (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args);
    }) as ReturnType<F>;
}
