import { Query, collection, getDocs, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useAuth } from '../../common/hooks/auth';

import { firestore } from '../../common/utils/firebase';

interface CostsOverviewProps {}
const CostsOverview: React.FC<CostsOverviewProps> = () => {
  const { user } = useAuth();

  const [costs, setCosts] = useState([]);

  useEffect(() => {
    const q: Query = query(collection(firestore, 'costs'));
    const getCosts = async () => {
      const querySnapshot = await getDocs(q);
      const costsArray: any = [];
      querySnapshot.forEach(doc => {
        const docData = doc.data();
        costsArray.push({
          ...docData,
        });
      });
      setCosts(costsArray);
    };
    getCosts().catch(err => console.log(err));
  }, []);

  return (
    <>
      <h1 className="w-full font-outfit font-medium text-2xl my-5">Costs overview</h1>
      <div className="overflow-y-auto overflow-x-hidden">
        {user && (
          <>
            {costs.map((cost: any) => (
              <div className="flex flex-col bg-white rounded-lg shadow-lg p-5 my-5">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col">
                    <h1 className="font-outfit font-medium text-xl mb-5">
                      {cost.month} - {cost.year}
                    </h1>
                    <table className="table-fixed border-separate border-spacing-2 border border-slate-500 border-collapse">
                      <thead>
                        <th>Admin users</th>
                        <th>WhatsApp users</th>
                        <th>System users</th>
                        <th>WhatsApp inactive</th>
                        <th>Inactive</th>
                      </thead>
                      <tr>
                        <td>{cost.adminUsers}</td>
                        <td>{cost.outletsWithWhatsAppOrderPlaced}</td>
                        <td>{cost.systemUsers}</td>
                        <td>{cost.whatsAppInactive}</td>
                        <td>{cost.inactive}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default CostsOverview;
