import { ProtectedRoute } from '../../components/Routing';
import Outlets from './Outlets';

const OutletsRoute = {
  path: 'outlets',
  element: (
    <ProtectedRoute roles={['admin']}>
      <Outlets />
    </ProtectedRoute>
  ),
};

export default OutletsRoute;
