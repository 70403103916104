import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useUser } from '../../../../common/hooks';
import { RoleType } from '../../../../common/types';
import { debounce } from '../../../../common/utils';
import { changeGroupFSA } from '../../../../common/utils/firebase';
import Button from '../../../../components/Button';
import Input from '../../../../components/Form/Input';
import Loader from '../../../../components/Loader';
import Modal from '../../../../components/Modal';
import Cluster from '../../../Users/ui/components/Cluster';

interface ReassignProps {
  group: any | null | undefined;
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface ReassignForm {
  userId: string;
}

const ReassignGroups: React.FC<ReassignProps> = ({ group, open, setOpen }) => {
  const { register, getValues } = useForm<ReassignForm>();
  const [userId, setUserId] = useState<string>();
  const [running, setRunning] = useState<boolean>(false);
  const { loading, user: newUser, error } = useUser(userId);
  const { loading: oldUserLoading, user: oldUser, error: oldUserError } = useUser(group?.fsa.id);

  const userIdOptions: RegisterOptions = {
    onChange: debounce(() => {
      const newUserId = getValues('userId');
      if (newUserId.length === 20) {
        setUserId(newUserId);
      } else {
        setUserId(undefined);
      }
    }, 500),
  };

  const isSameRole = useMemo(() => {
    if (oldUser && newUser) {
      return oldUser.roles.some(role => newUser.roles.includes(role));
    }
    return false;
  }, [oldUser, newUser]);

  const canReassign = useMemo(() => {
    const reassignRoles = ['fsa', 'tae'];

    return oldUser?.roles.some(role => reassignRoles.includes(role));
  }, [oldUser]);

  const errorText = useMemo(() => {
    if (!isSameRole) {
      return 'Cannot reassign to user with different role!';
    }
    return undefined;
  }, [isSameRole]);

  const roleColor = useMemo(() => clsx(!isSameRole && 'text-red-500'), [isSameRole]);

  const title = useMemo(() => {
    switch (oldUser?.roles[0]) {
      case 'fsa':
        return 'Change Group FSA to:';
      default:
        return 'Cannot Reassign!';
    }
  }, [oldUser]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (oldUserError) {
      toast.error(oldUserError.message);
    }
  }, [oldUserError]);

  const handleReassign = () => {
    setRunning(true);
    if (oldUser?.roles[0] === 'fsa') {
      changeGroupFSA({ groupId: group?.id, fsaId: newUser?.id })
        .then(() => {
          toast.success('Reassigned successfully!');
        })
        .catch(err => {
          toast.error(err?.message);
        })
        .finally(() => {
          setRunning(false);
        });
    } else {
      setRunning(false);
    }
  };

  return (
    <>
      <Loader show={running} />
      <Modal open={open} setOpen={setOpen}>
        <h1 className="font-bold font-outfit text-2xl my-2">{title}</h1>
        <form onSubmit={event => event.preventDefault()}>
          <Input
            name="userId"
            placeholder="Enter user id..."
            register={register}
            options={userIdOptions}
            className="my-2"
          />
        </form>
        <div>
          {userId && newUser && (
            <div className="my-2">
              <div className="font-medium text-lg">{newUser.name}</div>
              <div className="font-thin">{newUser.phone}</div>
              <div className={roleColor}>
                {newUser.roles.map((role: RoleType) => role.toUpperCase())}
              </div>
              {newUser?.cluster && <Cluster cluster={newUser.cluster} />}
              {newUser?.clusters &&
                newUser.clusters.map(cluster => <Cluster key={cluster.id} cluster={cluster} />)}
              {isSameRole && canReassign && (
                <Button
                  onClick={handleReassign}
                  text="Reassign"
                  color="accent2"
                  className="w-full mt-4"
                />
              )}
              {errorText && (
                <p className="font-bold text-white text-center bg-accent2 rounded-sm">
                  {errorText}
                </p>
              )}
            </div>
          )}
          {userId && !loading && !oldUserLoading && !newUser && <div>User not found</div>}
        </div>
      </Modal>
    </>
  );
};

export default ReassignGroups;
