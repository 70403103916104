/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';

interface CardProps {
  children?: JSX.Element | JSX.Element[] | string;
  className?: string;
  [x: string]: any;
}

const Card: React.FC<CardProps> = ({ children, className, ...rest }) => {
  const style = clsx(
    'w-full bg-white md:min-w-min md:w-1/2 my-1 px-3 py-3 rounded-sm shadow-md',
    className
  );

  return (
    <section className={style} data-testid="card" {...rest}>
      {children}
    </section>
  );
};

export default Card;
