import dayjs from 'dayjs';
import { AnalyticsType, FilterOptions } from '../../../types/Analytics';
import { checkIfDataIsInFilter, convertTimestampToDate, getCompareId } from '../helperFunctions';

type Data = {
  id: string;
  name: string;
  classicPriceSum: number;
  classicAmountOfPrices: number;
  classicAveragePrice: number;
  supasetPriceSum: number;
  supasetAmountOfPrices: number;
  supasetAveragePrice: number;
  supafixc0PriceSum: number;
  supafixc0AmountOfPrices: number;
  supafixc0AveragePrice: number;
  supafixc1PriceSum: number;
  supafixc1AmountOfPrices: number;
  supafixc1AveragePrice: number;
};

const computeAverageBuyingPrice = (analyticsData: AnalyticsType[], filter: FilterOptions) => {
  if (analyticsData.length > 0) {
    const dataPreviousPeriod: Data[] = [];
    const dataCurrentPeriod: Data[] = [];

    const currentPeriodStartDate = filter.dateRange1.startDate;
    const currentPeriodEndDate = dayjs(filter.dateRange1.endDate).add(1, 'day').toDate();
    const previousPeriodStartDate = filter.dateRange2.startDate;
    const previousPeriodEndDate = dayjs(filter.dateRange2.endDate).add(1, 'day').toDate();

    analyticsData.forEach(data => {
      const isInFilter = checkIfDataIsInFilter(data, filter);

      if (isInFilter) {
        const date = convertTimestampToDate(data.date);
        const compareId = getCompareId(data, filter);

        if (date >= currentPeriodStartDate && date <= currentPeriodEndDate) {
          // if data is not empty, check if id exists
          let idExists = false;
          for (const element of dataCurrentPeriod) {
            if (element.id === compareId) {
              // if id exists, update entry
              element.classicPriceSum += data.buyingPrices.sumOfPrices.classic;
              element.classicAmountOfPrices += data.buyingPrices.numberOfPrices.classic;
              element.supasetPriceSum += data.buyingPrices.sumOfPrices.supaset;
              element.supasetAmountOfPrices += data.buyingPrices.numberOfPrices.supaset;
              element.supafixc0PriceSum += data.buyingPrices.sumOfPrices.supafixc0;
              element.supafixc0AmountOfPrices += data.buyingPrices.numberOfPrices.supafixc0;
              element.supafixc1PriceSum += data.buyingPrices.sumOfPrices.supafixc1;
              element.supafixc1AmountOfPrices += data.buyingPrices.numberOfPrices.supafixc1;
              idExists = true;
            }
          }
          if (!idExists) {
            const priceData = {
              classicPriceSum: data.buyingPrices.sumOfPrices.classic,
              classicAmountOfPrices: data.buyingPrices.numberOfPrices.classic,
              supasetPriceSum: data.buyingPrices.sumOfPrices.supaset,
              supasetAmountOfPrices: data.buyingPrices.numberOfPrices.supaset,
              supafixc0PriceSum: data.buyingPrices.sumOfPrices.supafixc0,
              supafixc0AmountOfPrices: data.buyingPrices.numberOfPrices.supafixc0,
              supafixc1PriceSum: data.buyingPrices.sumOfPrices.supafixc1,
              supafixc1AmountOfPrices: data.buyingPrices.numberOfPrices.supafixc1,
              classicAveragePrice: 0,
              supasetAveragePrice: 0,
              supafixc0AveragePrice: 0,
              supafixc1AveragePrice: 0,
            };

            // if id does not exist, push new entry
            if (filter.groupBy.toLowerCase() === 'region') {
              dataCurrentPeriod.push({
                id: data.cluster.region.id,
                name: data.cluster.region.name,
                ...priceData,
              });
            }
            if (filter.groupBy.toLowerCase() === 'territory') {
              dataCurrentPeriod.push({
                id: data.cluster.territory.id,
                name: data.cluster.territory.name,
                ...priceData,
              });
            }
            if (filter.groupBy.toLowerCase() === 'cluster') {
              dataCurrentPeriod.push({
                id: data.cluster.id,
                name: data.cluster.name,
                ...priceData,
              });
            }
            if (filter.groupBy.toLowerCase() === 'fst') {
              dataCurrentPeriod.push({
                id: data.fsa.id,
                name: data.fsa.name,
                ...priceData,
              });
            }
          }
        } else if (date >= previousPeriodStartDate && date <= previousPeriodEndDate) {
          // if cementData is not empty, check if id exists
          let idExists = false;
          for (const element of dataPreviousPeriod) {
            if (element.id === compareId) {
              // if id exists, update entry
              element.classicPriceSum += data.buyingPrices.sumOfPrices.classic;
              element.classicAmountOfPrices += data.buyingPrices.numberOfPrices.classic;
              element.supasetPriceSum += data.buyingPrices.sumOfPrices.supaset;
              element.supasetAmountOfPrices += data.buyingPrices.numberOfPrices.supaset;
              element.supafixc0PriceSum += data.buyingPrices.sumOfPrices.supafixc0;
              element.supafixc0AmountOfPrices += data.buyingPrices.numberOfPrices.supafixc0;
              element.supafixc1PriceSum += data.buyingPrices.sumOfPrices.supafixc1;
              element.supafixc1AmountOfPrices += data.buyingPrices.numberOfPrices.supafixc1;
              idExists = true;
            }
          }

          if (!idExists) {
            const priceData = {
              classicPriceSum: data.buyingPrices.sumOfPrices.classic,
              classicAmountOfPrices: data.buyingPrices.numberOfPrices.classic,
              supasetPriceSum: data.buyingPrices.sumOfPrices.supaset,
              supasetAmountOfPrices: data.buyingPrices.numberOfPrices.supaset,
              supafixc0PriceSum: data.buyingPrices.sumOfPrices.supafixc0,
              supafixc0AmountOfPrices: data.buyingPrices.numberOfPrices.supafixc0,
              supafixc1PriceSum: data.buyingPrices.sumOfPrices.supafixc1,
              supafixc1AmountOfPrices: data.buyingPrices.numberOfPrices.supafixc1,
              classicAveragePrice: 0,
              supasetAveragePrice: 0,
              supafixc0AveragePrice: 0,
              supafixc1AveragePrice: 0,
            };

            // if id does not exist, push new entry
            if (filter.groupBy.toLowerCase() === 'region') {
              dataPreviousPeriod.push({
                id: data.cluster.region.id,
                name: data.cluster.region.name,
                ...priceData,
              });
            }
            if (filter.groupBy.toLowerCase() === 'territory') {
              dataPreviousPeriod.push({
                id: data.cluster.territory.id,
                name: data.cluster.territory.name,
                ...priceData,
              });
            }
            if (filter.groupBy.toLowerCase() === 'cluster') {
              dataPreviousPeriod.push({
                id: data.cluster.id,
                name: data.cluster.name,
                ...priceData,
              });
            }
            if (filter.groupBy.toLowerCase() === 'fst') {
              dataPreviousPeriod.push({
                id: data.fsa.id,
                name: data.fsa.name,
                ...priceData,
              });
            }
          }
        }
      }
    });

    // calculate average price
    const dataCurrentPeriodWithAvgPrice = dataCurrentPeriod.map(data => {
      const classicAveragePrice = Number.isNaN(data.classicPriceSum / data.classicAmountOfPrices)
        ? 0
        : Math.round(data.classicPriceSum / data.classicAmountOfPrices);
      const supasetAveragePrice = Number.isNaN(data.supasetPriceSum / data.supasetAmountOfPrices)
        ? 0
        : Math.round(data.supasetPriceSum / data.supasetAmountOfPrices);
      const supafixc0AveragePrice = Number.isNaN(
        data.supafixc0PriceSum / data.supafixc0AmountOfPrices
      )
        ? 0
        : Math.round(data.supafixc0PriceSum / data.supafixc0AmountOfPrices);
      const supafixc1AveragePrice = Number.isNaN(
        data.supafixc1PriceSum / data.supafixc1AmountOfPrices
      )
        ? 0
        : Math.round(data.supafixc1PriceSum / data.supafixc1AmountOfPrices);
      return {
        ...data,
        classicAveragePrice,
        supasetAveragePrice,
        supafixc0AveragePrice,
        supafixc1AveragePrice,
      };
    });

    const dataPreviousPeriodWithAvgPrice = dataPreviousPeriod.map(data => {
      const classicAveragePrice = Number.isNaN(data.classicPriceSum / data.classicAmountOfPrices)
        ? 0
        : Math.round(data.classicPriceSum / data.classicAmountOfPrices);
      const supasetAveragePrice = Number.isNaN(data.supasetPriceSum / data.supasetAmountOfPrices)
        ? 0
        : Math.round(data.supasetPriceSum / data.supasetAmountOfPrices);
      const supafixc0AveragePrice = Number.isNaN(
        data.supafixc0PriceSum / data.supafixc0AmountOfPrices
      )
        ? 0
        : Math.round(data.supafixc0PriceSum / data.supafixc0AmountOfPrices);
      const supafixc1AveragePrice = Number.isNaN(
        data.supafixc1PriceSum / data.supafixc1AmountOfPrices
      )
        ? 0
        : Math.round(data.supafixc1PriceSum / data.supafixc1AmountOfPrices);
      return {
        ...data,
        classicAveragePrice,
        supasetAveragePrice,
        supafixc0AveragePrice,
        supafixc1AveragePrice,
      };
    });

    return [dataCurrentPeriodWithAvgPrice, dataPreviousPeriodWithAvgPrice];
  }
};

export default computeAverageBuyingPrice;
