import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../common/hooks/auth';
import { useSignInWithGoogle } from '../../common/hooks/auth/useSignInWithGoogle';
import { auth } from '../../common/utils/firebase';
import Button from '../../components/Button';

interface LoginProps {}

const Enter: React.FC<LoginProps> = () => {
  const { user } = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [signInWithGoogle, loading, error] = useSignInWithGoogle(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      toast.error(error?.message || 'Something went wrong');
    }
  }, [error]);

  useEffect(() => {
    if (user !== undefined && user !== null) navigate('/', { replace: true });
  }, [user, navigate]);
  return (
    <div className="flex justify-center">
      <Button color="accent1" onClick={signInWithGoogle}>
        Sign in with Google
      </Button>
    </div>
  );
};

export default Enter;
