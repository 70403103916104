/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useMemo, useState } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import ReactSelect, { StylesConfig } from 'react-select';
import { debounce } from '../../../../common/utils';
import Card from '../../../../components/Card';
import Input from '../../../../components/Form/Input';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import { reassignSites } from '../../../../common/utils/firebase';

interface SearchBarProps {
  onSearchChange: (searchText: string) => void;
  onActiveChange: (active: boolean | null) => void;
}

interface ActiveOption {
  label: string;
  value: string;
  default: boolean;
}

interface SearchBarForm {
  searchText: string;
  active: ActiveOption[];
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearchChange, onActiveChange }) => {
  const { register, getValues, control, reset } = useForm<SearchBarForm>();
  const [openReassignModal, setOpenReassignModal] = useState<boolean>(false);
  const [reassignData, setReassignData] = useState<string>('');
  const [error, setError] = useState<string>();

  const activeOptions: ActiveOption[] = useMemo(
    () => [
      { label: 'Active', value: 'true', default: false },
      { label: 'Inactive', value: 'false', default: false },
    ],
    []
  );

  useEffect(() => {
    reset({
      searchText: '',
      active: activeOptions.filter(option => option.default),
    });
  }, [reset, activeOptions]);

  const searchTextOptions: RegisterOptions = {
    onChange: debounce(() => {
      const searchText = getValues('searchText');
      if (searchText.length > 2) {
        onSearchChange(searchText);
      }
    }, 500),
  };

  const selectStyles: StylesConfig = {
    valueContainer: provided => ({
      ...provided,
      flexWrap: 'nowrap',
    }),
    input: provided => ({
      ...provided,
      overflow: 'hidden',
    }),
  };

  const handleReassign = () => {
    try {
      const data = JSON.parse(reassignData);
      reassignSites(data)
        .then(() => {
          setOpenReassignModal(false);
          setReassignData('');
          setError('');
        })
        .catch(err => {
          setError(err.message);
        });
    } catch (err) {
      setError('Invalid JSON');
    }
  };

  return (
    <Card className="fixed z-10 md:w-4/6 top-10 bg-white">
      <form onSubmit={event => event.preventDefault()} className="flex">
        <Input
          name="searchText"
          placeholder="Search site..."
          register={register}
          options={searchTextOptions}
          className="flex-3/4 mr-2"
        />
        <ReactSelect
          name="active"
          options={activeOptions}
          placeholder="status"
          className="flex-1/4 ml-2"
          styles={selectStyles}
          onChange={value => {
            /* eslint-disable no-unneeded-ternary */
            /* eslint-disable no-nested-ternary */
            if (value === null) {
              onActiveChange(null);
            } else {
              const { value: activeValue } = value as ActiveOption;
              const active = value !== null ? (activeValue === 'true' ? true : false) : null;
              onActiveChange(active);
            }
          }}
          isClearable
        />
        <Button
          type="button"
          className="flex-1/4 ml-2"
          onClick={() => {
            setOpenReassignModal(true);
          }}
        >
          Reassign Sites
        </Button>
      </form>
      <p>Searchable by Site name, siteOwner id/name/phone, primaryTAE id/name, cluster name</p>
      <Modal open={openReassignModal} setOpen={setOpenReassignModal}>
        <div>
          <h1 className="font-bold">Reassign Sites</h1>
          The outlet backend function takes the following data: <br />
          <pre>
            {`
            [
              {
                "document_id": "",
                "primaryTAE_id": "", (optional)
              }
            ]
            `}
          </pre>
          <textarea
            className="w-full h-64 border"
            placeholder="Enter data here"
            onChange={event => {
              setReassignData(event.target.value);
            }}
          />
          {error && <p className="text-red-500">{error}</p>}
          <Button
            className="bg-accent1 text-white"
            onClick={() => {
              handleReassign();
            }}
          >
            Reassign
          </Button>
        </div>
      </Modal>
      <DevTool control={control} />
    </Card>
  );
};

export default SearchBar;
