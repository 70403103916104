import { Auth, onAuthStateChanged, User } from 'firebase/auth';
import { useEffect, useMemo } from 'react';
import { LoadingHook, useLoadingValue } from '../utils';

export type AuthStateHook = LoadingHook<User | null, Error>;

/**
 * A hook that returns the current user and handles the loading state.
 * @param auth The firebase auth instance.
 */
const useAuthState = (auth: Auth): AuthStateHook => {
  const { error, loading, setError, setValue, value } = useLoadingValue<User | null, Error>(
    () => auth.currentUser
  );

  useEffect(() => onAuthStateChanged(auth, setValue, setError), [auth]);

  const resArray: AuthStateHook = [value, loading, error];
  return useMemo<AuthStateHook>(() => resArray, resArray);
};

export default useAuthState;
