import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { List } from '../../../../components/List';
import useSites from '../../../../common/hooks/useSites';
import SiteItem from '../components/SiteItem';

interface SitesListProps {
  active: boolean | null;
  searchText?: string;
  setSelectedSiteId: (id: string) => void;
}

const SitesList: React.FC<SitesListProps> = ({ searchText, setSelectedSiteId, active }) => {
  const { error, hasMore, load, loading, sites } = useSites({
    searchText,
    active,
  });

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <List
      load={load}
      hasMore={hasMore}
      loading={loading}
      className="overflow-y-scroll no-scrollbar px-4 pt-48 w-full md:w-full"
    >
      {sites &&
        sites.map(site => <SiteItem key={site.id} site={site} onClick={setSelectedSiteId} />)}
    </List>
  );
};

export default SitesList;
