import React, { useMemo } from 'react';
import CsvDownloader from 'react-csv-downloader';
import useStore from '../../common/hooks/useStore';
import { AnalyticsType, FilterOptions } from '../../common/types/Analytics';
import { computeAverageBuyingPrice } from '../../common/utils/analytics';
import Header from './Header';
import csvLogo from '../../common/assets/images/export-csv.png';

interface AverageBuyingPriceChartProps {
  data: AnalyticsType[];
  filter: FilterOptions;
}

const AverageBuyingPriceChart: React.FC<AverageBuyingPriceChartProps> = ({ data, filter }) => {
  const [state] = useStore();

  const [chartDataCurrentPeriod, chartDataPreviousPeriod]: any = useMemo(
    () => computeAverageBuyingPrice(data, filter),
    [state.recompute]
  );

  const startDate = filter.dateRange1.startDate.toLocaleDateString();
  const endDate = filter.dateRange1.endDate.toLocaleDateString();
  const startDateLastPeriod = filter.dateRange2.startDate.toLocaleDateString();
  const endDateLastPeriod = filter.dateRange2.endDate.toLocaleDateString();

  const downloadCSV = (dataToDownload: any) => {
    const csvData = dataToDownload.map((item: any) => ({
      name: item.name,
      'classic average buying price': item.classicAveragePrice,
      'supaset average buying price': item.supasetAveragePrice,
      'supafix c0 average buying price': item.supafixc0AveragePrice,
      'supafix c1 average buying price': item.supafixc1AveragePrice,
    }));
    return csvData;
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-12 p-5">
      <div className="flex-col">
        <div className="flex justify-end mb-1">
          <CsvDownloader
            datas={downloadCSV(chartDataCurrentPeriod)}
            filename={`averageBuyingPriceChartData_${startDate}_to_${endDate}`}
            extension=".csv"
            separator=","
          >
            <img src={csvLogo} alt="csvDownload" width="30" />
          </CsvDownloader>
        </div>
        <Header name="Current Period" dateRange={`(${startDate} - ${endDate})`} />

        <div>
          {/* for each data in chartDataCurrentPeriod display products and prices */}
          <table className="w-full text-justify border">
            <thead className="border">
              <tr className="font-medium font-outfit text-right">
                <th aria-label="empty" />
                <th>Classic</th>
                <th>Supaset</th>
                <th>Supafix C0</th>
                <th>Supafix C1</th>
              </tr>
            </thead>
            <tbody className="border">
              {chartDataCurrentPeriod.map((dataCurrentPeriod: any) => (
                <tr key={dataCurrentPeriod.id} className="border text-right">
                  <td className="font-medium font-outfit text-left">{dataCurrentPeriod.name}</td>
                  <td>{dataCurrentPeriod.classicAveragePrice}</td>
                  <td>{dataCurrentPeriod.supasetAveragePrice}</td>
                  <td>{dataCurrentPeriod.supafixc0AveragePrice}</td>
                  <td>{dataCurrentPeriod.supafixc1AveragePrice}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex-col">
        <div className="flex justify-end mb-1">
          <CsvDownloader
            datas={downloadCSV(chartDataPreviousPeriod)}
            filename={`averageBuyingPriceChartData_${startDateLastPeriod}_to_${endDateLastPeriod}`}
            extension=".csv"
            separator=","
          >
            <img src={csvLogo} alt="csvDownload" width="30" />
          </CsvDownloader>
        </div>
        <Header name="Last Period" dateRange={`(${startDateLastPeriod} - ${endDateLastPeriod})`} />

        <div>
          {/* for each data in chartDataCurrentPeriod display products and prices */}
          <table className="w-full text-justify border">
            <thead className="border">
              <tr className="font-medium font-outfit text-right">
                <th aria-label="empty" />
                <th>Classic</th>
                <th>Supaset</th>
                <th>Supafix C0</th>
                <th>Supafix C1</th>
              </tr>
            </thead>
            <tbody className="border">
              {chartDataPreviousPeriod.map((dataPreviousPeriod: any) => (
                <tr key={dataPreviousPeriod.id} className="border text-right">
                  <td className="font-medium font-outfit text-left">{dataPreviousPeriod.name}</td>
                  <td>{dataPreviousPeriod.classicAveragePrice}</td>
                  <td>{dataPreviousPeriod.supasetAveragePrice}</td>
                  <td>{dataPreviousPeriod.supafixc0AveragePrice}</td>
                  <td>{dataPreviousPeriod.supafixc1AveragePrice}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AverageBuyingPriceChart;
