import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { List } from '../../../../components/List';
import useOutlets from '../../../../common/hooks/useOutlets';
import OutletItem from '../components/OutletItem';

interface OutletsListProps {
  active: boolean | null;
  searchText?: string;
  setSelectedOutletId: (id: string) => void;
}

const OutletsList: React.FC<OutletsListProps> = ({ searchText, setSelectedOutletId, active }) => {
  const { error, hasMore, load, loading, outlets } = useOutlets({
    searchText,
    active,
  });

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <List
      load={load}
      hasMore={hasMore}
      loading={loading}
      className="overflow-y-scroll no-scrollbar px-4 pt-48 w-full md:w-full"
    >
      {outlets &&
        outlets.map(outlet => (
          <OutletItem key={outlet.id} outlet={outlet} onClick={setSelectedOutletId} />
        ))}
    </List>
  );
};

export default OutletsList;
