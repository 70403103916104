/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';
import { ErrorOption, FieldValues, Path, RegisterOptions, UseFormReturn } from 'react-hook-form';
import { InputType } from '../../common/types/Input';

interface InputProps<T extends FieldValues>
  extends Partial<Pick<UseFormReturn<T>, 'register' | 'formState'>> {
  name: Path<T>;
  type?: InputType;
  options?: RegisterOptions;
  className?: string;
  rules?: any;
  placeholder?: string;
  value?: string;
  error?: ErrorOption;
  disabled?: boolean;
}

const Input = <T extends FieldValues>({
  name,
  type = 'text',
  options,
  register,
  className,
  rules,
  placeholder,
  value,
  error,
  disabled,
  ...rest
}: InputProps<T>) => {
  const style = clsx(
    'border-b border-secondary w-full disabled:opacity-50 focus:border-b-2 focus:outline-none',
    className,
    error && 'border-b-accent3'
  );

  const registerGuard = (registerName: Path<T>, registerOptions: any) => {
    if (register) {
      return register(registerName, registerOptions);
    }
  };

  return (
    <input
      disabled={disabled}
      className={style}
      type={type}
      {...registerGuard(name, options)}
      {...rest}
      value={value}
      placeholder={placeholder}
    />
  );
};

export default Input;
