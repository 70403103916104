import { RefObject, useEffect } from 'react';

interface UseOnClickOutsideFunction {
  (ref: RefObject<HTMLElement>, handler: (event: MouseEvent) => void, block?: boolean): void;
}

/**
 * Hook that alerts clicks outside of the passed ref
 * @param ref React ref
 * @param handler Function to call when click is outside of ref
 * @param block Block click event
 */
const useOnClickOutside: UseOnClickOutsideFunction = (ref, handler, block) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (block || !ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler, block]);
};

export default useOnClickOutside;
