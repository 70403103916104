import { ProtectedRoute } from '../../components/Routing';
import Dashboard from './Dashboard';

const DashboardRoute = {
  path: 'dashboard',
  element: (
    <ProtectedRoute roles={['analytics']}>
      <Dashboard />
    </ProtectedRoute>
  ),
};

export default DashboardRoute;
