import { Link, useRouteError } from 'react-router-dom';
import logoSmall from '../common/assets/images/loadingLogo.png';

const ErrorElement: React.FC = () => {
  const error: any = useRouteError();
  return (
    <div className="h-screen w-screen bg-gray-100 flex items-center">
      <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
        <div className="max-w-md">
          <img alt="logo" src={logoSmall} className="h-10 my-8" />
          <div className="text-5xl font-dark font-outfit font-bold">
            Error
            <strong> {error.status} </strong>
          </div>
          <br />
          <p className="mb-8 font-nunito">{error.message}</p>
          <Link
            to="/"
            className="px-4 inline py-2 text-sm font-medium leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue bg-accent1 pointer"
          >
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorElement;
