import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../utils/firebase';
import { SiteChanges } from '../types';

const updateSite = async (siteId: string, data: SiteChanges) => {
  const ref = doc(firestore, 'sites', siteId);
  await updateDoc(ref, data);
};

export default updateSite;
