import dayjs from 'dayjs';
import { AnalyticsType, FilterOptions, TAEAnalyticsType } from '../../types/Analytics';

export function convertTimestampToDate(timestamp: any) {
  return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
}

export function checkIfDataIsInFilter(
  data: AnalyticsType | TAEAnalyticsType,
  filter: FilterOptions
): boolean {
  const endDatePlusOne: Date = dayjs(filter.dateRange1.endDate)
    .add(1, 'day')
    .startOf('day')
    .toDate();
  const previousEndDatePlusOne: Date = dayjs(filter.dateRange2.endDate)
    .add(1, 'day')
    .startOf('day')
    .toDate();

  const myDate = convertTimestampToDate(data.date);

  // check if data is in filtered date range
  if (
    (myDate >= filter.dateRange1.startDate && myDate < endDatePlusOne) ||
    (myDate >= filter.dateRange2.startDate && myDate < previousEndDatePlusOne)
  ) {
    if (filter.regions.size === 0 || filter.regions.has(data.cluster.region.id)) {
      if (filter.territories.size === 0 || filter.territories.has(data.cluster.territory.id)) {
        if (filter.clusters.size === 0 || filter.clusters.has(data.cluster.id)) {
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  }
  return false;
}

export function calculatePercentageChange(currentPeriod: number, previousPeriod: number): number {
  const result = ((currentPeriod - previousPeriod) / previousPeriod) * 100;
  const resultRounded = Math.round(result * 100) / 100;
  if (Number.isNaN(resultRounded)) {
    return 0;
  }
  return resultRounded;
}

export const getFirstDayOfMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getCompareId = (data: AnalyticsType, filter: FilterOptions) => {
  if (filter.groupBy.toLowerCase() === 'region') {
    return data.cluster.region.id;
  }
  if (filter.groupBy.toLowerCase() === 'territory') {
    return data.cluster.territory.id;
  }
  if (filter.groupBy.toLowerCase() === 'cluster') {
    return data.cluster.id;
  }
  if (filter.groupBy.toLowerCase() === 'fst') {
    return data.fsa.id;
  }
};

export const getTaeCompareId = (data: TAEAnalyticsType, filter: FilterOptions) => {
  if (filter.groupBy.toLowerCase() === 'region') {
    return data.cluster.region.id;
  }
  if (filter.groupBy.toLowerCase() === 'territory') {
    return data.cluster.territory.id;
  }
  if (filter.groupBy.toLowerCase() === 'cluster') {
    return data.cluster.id;
  }
  if (filter.groupBy.toLowerCase() === 'fst') {
    return data.tae.id;
  }
};

export const bagsToTons = <F>(products: any): F => {
  const tons: any = {};

  Object.keys(products).forEach(key => {
    switch (key) {
      case 'classic':
      case 'supaset':
        tons[key] = products[key] * 0.05;
        break;
      case 'supafixc0':
      case 'supafixc1':
        tons[key] = products[key] * 0.02;
        break;
      default:
        tons[key] = products[key] * 0.05;
    }
  });
  return tons as F;
};

export const renderLabelWithPercentage = (props: any) => `${props}%`;

export const renderLabelRoundedOnTwoDecimalPlaces = (props: any) =>
  `${Math.round(props * 100) / 100}`;
