import { collection, orderBy, query, where } from 'firebase/firestore';
import { useMemo, useEffect } from 'react';
import { firestore } from '../utils/firebase';
import { usePagination } from './utils/usePagination';
import { SessionType } from '../types/Session';
import deleteSessionById from '../db/sessions';

interface UseSessionsOptions {
  pageSize?: number;
}

const useSessions = (userId: string, { pageSize = 100 }: UseSessionsOptions) => {
  const sessionsQuery = useMemo(() => {
    console.log('useSessions', userId);
    let q = query(collection(firestore, 'sessions'));
    q = query(q, where('userId', '==', userId));
    q = query(q, orderBy('createdAt', 'desc'));

    console.log('useSessions', q);

    return q;
  }, [userId]);

  const { error, hasMore, load, loading, reset, values } = usePagination(sessionsQuery, pageSize);

  useEffect(() => {
    console.log('error', error);
  }, [error]);

  const deleteSession = async (sessionId: string) => {
    if (!sessionId) return;
    try {
      console.log('deleteSession', sessionId);
      await deleteSessionById(sessionId);
    } catch (err) {
      console.error(err);
    }
  };

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      deleteSession,
      sessions: values
        ? values.map(doc => {
            const data = doc.data() as SessionType;
            return {
              ...data,
              id: doc.id,
            };
          })
        : [],
    }),
    [error, hasMore, loading, values, reset]
  );
};

export default useSessions;
