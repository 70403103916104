import { doc, FirestoreError, onSnapshot } from 'firebase/firestore';
import { useEffect, useMemo } from 'react';
import { firestore, docToJSON } from '../utils/firebase';
import { useLoadingValue } from './utils';
import { SiteChanges, SiteType } from '../types/Site';
import updateSite from '../db/sites';

const useSite = (siteId: string) => {
  const { error, loading, setLoading, setError, setValue, value } = useLoadingValue<
    SiteType | null,
    FirestoreError
  >();

  useEffect(() => {
    setLoading(true);
    const ref = doc(firestore, 'sites', siteId);
    const unsubscribe = onSnapshot(
      ref,
      snapshot => {
        setValue(docToJSON(snapshot));
        setLoading(false);
      },
      setError
    );

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [siteId]);

  const update = async (changes: SiteChanges) => {
    if (!siteId) return;
    try {
      setLoading(true);
      await updateSite(siteId, changes);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err instanceof FirestoreError) setError(err);
    }
  };

  return useMemo(
    () => ({
      site: value,
      error,
      loading,
      update,
    }),
    [value, loading, error]
  );
};

export default useSite;
