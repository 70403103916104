import { faSpinner, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React from 'react';
import ReactDOM from 'react-dom';

interface LoaderProps {
  show: boolean;
  transparent?: boolean;
  text?: string;
  onStop?: () => void;
}

const Loader: React.FC<LoaderProps> = ({ show, transparent = true, text, onStop }) => {
  const background = clsx(
    'fixed inset-0',
    transparent && 'bg-secondary bg-opacity-30 transition-opacity',
    !transparent && 'bg-primary'
  );

  return show
    ? ReactDOM.createPortal(
        <div
          className="fixed z-50 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          data-testid="loader"
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className={background} aria-hidden="true" data-testid="loader-bg" />
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin h-5 w-5" />
            {(text || onStop) && (
              <div className="fixed z-[60] inset-0 top-1/2 mt-4 overflow-y-auto font-bold flex flex-col">
                {text && <p>{text}</p>}
                {onStop && (
                  <button type="button" onClick={onStop} className="mt-5">
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                )}
              </div>
            )}
          </div>
        </div>,
        document.body
      )
    : null;
};

export default Loader;
