import { deleteDoc, doc } from 'firebase/firestore';
import { firestore } from '../utils/firebase';

const deleteSessionById = async (sessionId: string) => {
  const ref = doc(firestore, 'sessions', sessionId);
  await deleteDoc(ref).catch(err => {
    console.error(err);
    throw err;
  });
};

export default deleteSessionById;
