import dayjs from 'dayjs';
import { initStore } from '../hooks/useStore';
import { FilterOptions } from '../types/Analytics';

const configureFilterStore = () => {
  const actions = {
    SET_FILTER: (curState: any, filter: FilterOptions) => ({ ...curState, filter }),
    SET_FILTERED_REGIONS: (curState: any, filteredRegions: Map<string, string>) => ({
      ...curState,
      filteredRegions,
    }),
    SET_FILTERED_TERRITORIES: (curState: any, filteredTerritories: Map<string, string>) => ({
      ...curState,
      filteredTerritories,
    }),
    SET_FILTERED_CLUSTERS: (curState: any, filteredClusters: Map<string, string>) => ({
      ...curState,
      filteredClusters,
    }),
    SET_SELECTED_TAB_INDEX: (curState: any, selectedTabIndex: number) => ({
      ...curState,
      selectedTabIndex,
    }),
  };
  initStore(actions, {
    filter: {
      // set initial filter
      dateRange1: {
        startDate: dayjs().startOf('month').toDate(),
        endDate: dayjs().toDate(),
      },
      dateRange2: {
        startDate: dayjs().subtract(1, 'month').startOf('month').toDate(),
        endDate: dayjs().subtract(1, 'month').toDate(),
      },
      territories: new Map<string, string>(),
      regions: new Map<string, string>(),
      clusters: new Map<string, string>(),
      groupBy: 'Region',
    },
    filteredRegions: new Map<string, string>(),
    filteredTerritories: new Map<string, string>(),
    filteredClusters: new Map<string, string>(),
    selectedTabIndex: 0,
  });
};

export default configureFilterStore;
