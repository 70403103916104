import UserDetails from '../blocks/UserDetails';

interface RightProps {
  selectedUserId: string;
  setSelectedUserId: (id: string | undefined) => void;
}

const Right: React.FC<RightProps> = ({ selectedUserId, setSelectedUserId }) => (
  <div className="w-1/2 px-8 flex items-center">
    <UserDetails selectedUserId={selectedUserId} setSelectedUserId={setSelectedUserId} />
  </div>
);

export default Right;
