import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate('/home', { replace: true });
  };

  return (
    <div className="flex justify-center items-center flex-col py-16 w-full">
      <h1 className="text-xl text-secondary font-bold">Page not found!</h1>
      <p>We cannot find the page you&aposre looking for.</p>
      <Button onClick={onClickHandler} className="mt-4">
        Go to Home
      </Button>
    </div>
  );
};

export default NotFound;
