/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable import/prefer-default-export */
import { FirebaseError } from 'firebase/app';
import {
  DocumentData,
  getDocs,
  limit,
  query,
  Query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  startAfter,
} from 'firebase/firestore';
import { useCallback, useEffect, useMemo, useReducer } from 'react';

type ReducerState<T extends DocumentData> = {
  error?: FirebaseError;
  loading: boolean;
  values: any[] | undefined;
  hasMore: boolean;
  lastDoc?: QueryDocumentSnapshot;
  query?: Query<T>;
};

type ErrorAction = { type: 'error'; error: FirebaseError };
type ResetAction = { type: 'reset' };
type ValuesAction = {
  type: 'first' | 'more';
  values: any[] | undefined;
  hasMore: boolean;
  lastDoc: QueryDocumentSnapshot;
};
type LoadingAction = { type: 'loading'; loading: boolean };
type QueryAction<T extends DocumentData> = {
  type: 'query';
  query: Query<T> | undefined;
};

type ReducerAction<T extends DocumentData> =
  | ErrorAction
  | ResetAction
  | ValuesAction
  | LoadingAction
  | QueryAction<T>;

type PaginationHook = {
  error: FirebaseError | undefined;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  values: any[] | undefined;
};

const defaultState = () => ({ loading: true, hasMore: false, values: [] });

const reducer =
  <T extends DocumentData>() =>
  (state: ReducerState<T>, action: ReducerAction<T>): ReducerState<T> => {
    switch (action.type) {
      case 'error':
        return {
          ...state,
          error: action.error,
          loading: false,
          values: undefined,
          hasMore: false,
          lastDoc: undefined,
        };
      case 'reset':
        return defaultState();
      case 'first': {
        const values = action.values ? [...action.values] : [];

        return {
          ...state,
          error: undefined,
          hasMore: action.hasMore,
          loading: false,
          values,
          lastDoc: action.lastDoc,
        };
      }
      case 'more': {
        const stateValues = state.values ? [...state.values] : [];
        const values = action.values ? [...action.values] : [];

        return {
          ...state,
          error: undefined,
          hasMore: action.hasMore,
          loading: false,
          values: [...stateValues, ...values],
          lastDoc: action.lastDoc,
        };
      }
      case 'loading': {
        return {
          ...state,
          error: undefined,
          loading: action.loading,
        };
      }
      case 'query': {
        return {
          ...state,
          query: action.query,
        };
      }
      default:
        return state;
    }
  };

export const usePagination = <T extends DocumentData>(
  q: Query<T>,
  pageSize: number
): PaginationHook => {
  const [state, dispatch] = useReducer(reducer<T>(), defaultState());

  useEffect(() => {
    reset();
  }, [q, pageSize]);

  useEffect(() => {
    if (!state.query) {
      return;
    }
    getDocs(state.query)
      .then(snap => setFirst(snap, pageSize))
      .catch(setError);
  }, [state.query, pageSize]);

  const reset = () => {
    dispatch({ type: 'reset' });
    if (!q) {
      return;
    }
    let newQuery = q;
    if (pageSize > 0) {
      newQuery = query(q, limit(pageSize));
    }
    dispatch({ type: 'query', query: newQuery });
  };

  const setFirst = (snap: QuerySnapshot, setFirstPageSize: number): void => {
    dispatch({
      type: 'first',
      values: snap?.docs ? snap.docs : [],
      lastDoc: snap?.docs[snap.size - 1],
      hasMore: snap?.size !== 0 && snap?.size === setFirstPageSize,
    });
  };

  const setMore = (snap: QuerySnapshot, setMorePageSize: number): void => {
    dispatch({
      type: 'more',
      values: snap?.docs ? snap.docs : [],
      lastDoc: snap?.docs[snap.size - 1],
      hasMore: snap?.size !== 0 && snap?.size === setMorePageSize,
    });
  };

  const setError = (error: FirebaseError): void => {
    dispatch({ type: 'error', error });
  };

  const load = useCallback(() => {
    dispatch({ type: 'loading', loading: true });
    if (!state.query || !state.lastDoc || !state.hasMore) {
      dispatch({ type: 'loading', loading: false });
      return;
    }
    const refMore = query(state.query, startAfter(state.lastDoc));
    getDocs(refMore)
      .then(snap => setMore(snap, pageSize))
      .catch(setError);
  }, [state.hasMore, state.lastDoc, pageSize, state.query]);

  return useMemo(
    () => ({
      error: state.error,
      hasMore: state.hasMore,
      load,
      loading: state.loading,
      reset,
      values: state.values,
    }),
    [state.error, state.hasMore, state.loading, state.values]
  );
};
