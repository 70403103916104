import dayjs from 'dayjs';
import { FilterOptions, PerformanceData, TAEAnalyticsType } from '../../../types/Analytics';
import {
  calculatePercentageChange,
  checkIfDataIsInFilter,
  convertTimestampToDate,
  getTaeCompareId,
} from '../helperFunctions';

type Data = {
  id: string;
  name: string;
  target: number;
  numberOfLeads: number;
};

const computeLeads = (analyticsData: TAEAnalyticsType[], filter: FilterOptions) => {
  if (analyticsData.length > 0) {
    const dataPreviousPeriod: Data[] = [];
    const dataCurrentPeriod: Data[] = [];
    const headerDataPreviousPeriod: any = [];
    const headerDataCurrentPeriod: any = [];
    let targetCurrentPeriod: number = 0;
    let targetPreviousPeriod: number = 0;
    let actualCurrentPeriod: number = 0;
    let actualPreviousPeriod: number = 0;

    const currentPeriodStartDate = filter.dateRange1.startDate;
    const currentPeriodEndDate = dayjs(filter.dateRange1.endDate).add(1, 'day').toDate();
    const previousPeriodStartDate = filter.dateRange2.startDate;
    const previousPeriodEndDate = dayjs(filter.dateRange2.endDate).add(1, 'day').toDate();

    const clusterAdded: string[] = [];
    const clusterAddedPrevious: string[] = [];

    analyticsData.forEach(data => {
      if (data.leadsCreated === undefined || data.leadsFulfilled === undefined) {
        return;
      }

      const isInFilter = checkIfDataIsInFilter(data, filter);

      if (isInFilter) {
        const date = convertTimestampToDate(data.date);
        const compareId = getTaeCompareId(data, filter);

        if (date >= currentPeriodStartDate && date <= currentPeriodEndDate) {
          actualCurrentPeriod += data.numberOfLeads ? data.numberOfLeads : 0;

          if (
            filter.groupBy.toLowerCase() !== 'fst' &&
            !clusterAdded.includes(data.cluster.id + dayjs(data.date.toDate()).format('MM'))
          ) {
            targetCurrentPeriod += data.target.leadAmountTarget ? data.target.leadAmountTarget : 0;
          }

          // if cementData is not empty, check if id exists
          let idExists = false;
          for (const element of dataCurrentPeriod) {
            if (element.id === compareId) {
              // if id exists, update entry
              element.numberOfLeads += data.numberOfLeads ? data.numberOfLeads : 0;
              if (
                filter.groupBy.toLowerCase() !== 'fst' &&
                !clusterAdded.includes(data.cluster.id + dayjs(data.date.toDate()).format('MM'))
              ) {
                element.target += data.target.leadAmountTarget ? data.target.leadAmountTarget : 0;
                clusterAdded.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
              }
              idExists = true;
            }
          }
          if (!idExists) {
            // if id does not exist, push new entry
            if (filter.groupBy.toLowerCase() === 'region') {
              dataCurrentPeriod.push({
                id: data.cluster.region.id,
                name: data.cluster.region.name,
                target: data.target.leadAmountTarget ? data.target.leadAmountTarget : 0,
                numberOfLeads: data.numberOfLeads ? data.numberOfLeads : 0,
              });
              clusterAdded.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
            }
            if (filter.groupBy.toLowerCase() === 'territory') {
              dataCurrentPeriod.push({
                id: data.cluster.territory.id,
                name: data.cluster.territory.name,
                target: data.target.leadAmountTarget ? data.target.leadAmountTarget : 0,
                numberOfLeads: data.numberOfLeads ? data.numberOfLeads : 0,
              });
              clusterAdded.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
            }
            if (filter.groupBy.toLowerCase() === 'cluster') {
              dataCurrentPeriod.push({
                id: data.cluster.id,
                name: data.cluster.name,
                target: data.target.leadAmountTarget ? data.target.leadAmountTarget : 0,
                numberOfLeads: data.numberOfLeads ? data.numberOfLeads : 0,
              });
              clusterAdded.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
            }
            if (filter.groupBy.toLowerCase() === 'fst') {
              targetCurrentPeriod += data.target.leadAmountTarget
                ? data.target.leadAmountTarget
                : 0;
              dataCurrentPeriod.push({
                id: data.tae.id,
                name: data.tae.name,
                target: data.target.leadAmountTarget ? data.target.leadAmountTarget : 0,
                numberOfLeads: data.numberOfLeads ? data.numberOfLeads : 0,
              });
            }
          }
        } else if (date >= previousPeriodStartDate && date <= previousPeriodEndDate) {
          // compute header data
          actualPreviousPeriod += data.numberOfLeads ? data.numberOfLeads : 0;

          if (
            filter.groupBy.toLowerCase() !== 'fst' &&
            !clusterAddedPrevious.includes(data.cluster.id + dayjs(data.date.toDate()).format('MM'))
          ) {
            targetPreviousPeriod += data.target.leadAmountTarget ? data.target.leadAmountTarget : 0;
          }

          // if cementData is not empty, check if id exists
          let idExists = false;
          for (const element of dataPreviousPeriod) {
            if (element.id === compareId) {
              // if id exists, update entry
              element.numberOfLeads += data.numberOfLeads ? data.numberOfLeads : 0;
              if (
                filter.groupBy.toLowerCase() !== 'fst' &&
                !clusterAddedPrevious.includes(
                  data.cluster.id + dayjs(data.date.toDate()).format('MM')
                )
              ) {
                element.target += data.target.leadAmountTarget ? data.target.leadAmountTarget : 0;
                clusterAddedPrevious.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
              }
              idExists = true;
            }
          }
          if (!idExists) {
            // if id does not exist, push new entry
            if (filter.groupBy.toLowerCase() === 'region') {
              dataPreviousPeriod.push({
                id: data.cluster.region.id,
                name: data.cluster.region.name,
                target: data.target.leadAmountTarget ? data.target.leadAmountTarget : 0,
                numberOfLeads: data.numberOfLeads ? data.numberOfLeads : 0,
              });
              clusterAddedPrevious.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
            }
            if (filter.groupBy.toLowerCase() === 'territory') {
              dataPreviousPeriod.push({
                id: data.cluster.territory.id,
                name: data.cluster.territory.name,
                target: data.target.leadAmountTarget ? data.target.leadAmountTarget : 0,
                numberOfLeads: data.numberOfLeads ? data.numberOfLeads : 0,
              });
              clusterAddedPrevious.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
            }
            if (filter.groupBy.toLowerCase() === 'cluster') {
              dataPreviousPeriod.push({
                id: data.cluster.id,
                name: data.cluster.name,
                target: data.target.leadAmountTarget ? data.target.leadAmountTarget : 0,
                numberOfLeads: data.numberOfLeads ? data.numberOfLeads : 0,
              });
              clusterAddedPrevious.push(data.cluster.id + dayjs(data.date.toDate()).format('MM'));
            }
            if (filter.groupBy.toLowerCase() === 'fst') {
              dataPreviousPeriod.push({
                id: data.tae.id,
                name: data.tae.name,
                target: data.target.leadAmountTarget ? data.target.leadAmountTarget : 0,
                numberOfLeads: data.numberOfLeads ? data.numberOfLeads : 0,
              });
              targetPreviousPeriod += data.target.leadAmountTarget
                ? data.target.leadAmountTarget
                : 0;
            }
          }
        }
      }
    });

    headerDataCurrentPeriod.push(
      {
        name: 'Target',
        value: targetCurrentPeriod,
      },
      {
        name: 'Actual',
        value: actualCurrentPeriod,
      },
      {
        name: 'ACH',
        value: `${Math.round((actualCurrentPeriod / targetCurrentPeriod) * 100 * 100) / 100}%`,
      }
    );

    headerDataPreviousPeriod.push(
      {
        name: 'Target',
        value: targetPreviousPeriod,
      },
      {
        name: 'Actual',
        value: actualPreviousPeriod,
      },
      {
        name: 'ACH',
        value: `${Math.round((actualPreviousPeriod / targetPreviousPeriod) * 100 * 100) / 100}%`,
      }
    );

    const performance: PerformanceData[] = [
      {
        name: 'Target',
        value: calculatePercentageChange(targetCurrentPeriod, targetPreviousPeriod),
      },
      {
        name: 'Actual',
        value: calculatePercentageChange(actualCurrentPeriod, actualPreviousPeriod),
      },
    ];

    return [
      headerDataCurrentPeriod,
      dataCurrentPeriod,
      headerDataPreviousPeriod,
      dataPreviousPeriod,
      performance,
    ];
  }
};

export default computeLeads;
