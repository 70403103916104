/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable import/prefer-default-export */
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Client } from 'typesense';
import {
  DocumentSchema,
  SearchParams,
  SearchResponse,
  SearchResponseHit,
} from 'typesense/lib/Typesense/Documents';

type ReducerState<T extends DocumentSchema> = {
  error?: string | undefined;
  loading: boolean;
  values: SearchResponseHit<T>[] | undefined;
  hasMore: boolean;
  lastDoc?: number;
  query?: SearchParams | undefined;
};

type ErrorAction = { type: 'error'; error: string };
type ResetAction = { type: 'reset' };
type ValuesAction<T extends DocumentSchema> = {
  type: 'first' | 'more';
  values: SearchResponseHit<T>[] | undefined;
  hasMore: boolean;
  lastDoc: number;
};
type LoadingAction = { type: 'loading'; loading: boolean };
type QueryAction = {
  type: 'query';
  query: SearchParams | undefined;
};

type ReducerAction<T extends DocumentSchema> =
  | ErrorAction
  | ResetAction
  | ValuesAction<T>
  | LoadingAction
  | QueryAction;

type PaginationHook<T extends DocumentSchema> = {
  error: string | undefined;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  values: SearchResponseHit<T>[] | undefined;
};

const defaultState = () => ({ loading: true, hasMore: false, values: [] });

const reducer =
  <T extends DocumentSchema>() =>
  (state: ReducerState<T>, action: ReducerAction<T>): ReducerState<T> => {
    switch (action.type) {
      case 'error':
        return {
          ...state,
          error: action.error,
          loading: false,
          values: undefined,
          hasMore: false,
          lastDoc: undefined,
        };
      case 'reset':
        return defaultState();
      case 'first': {
        const values = action.values ? [...action.values] : [];

        return {
          ...state,
          error: undefined,
          hasMore: action.hasMore,
          loading: false,
          values,
          lastDoc: action.lastDoc,
        };
      }
      case 'more': {
        const stateValues = state.values ? [...state.values] : [];
        const values = action.values ? [...action.values] : [];

        return {
          ...state,
          error: undefined,
          hasMore: action.hasMore,
          loading: false,
          values: [...stateValues, ...values],
          lastDoc: action.lastDoc,
        };
      }
      case 'loading': {
        return {
          ...state,
          error: undefined,
          loading: action.loading,
        };
      }
      case 'query': {
        return {
          ...state,
          query: action.query,
        };
      }
      default:
        return state;
    }
  };

export const usePaginationTypesense = <T extends DocumentSchema>(
  q: SearchParams | undefined,
  pageSize: number,
  collection: string,
  typesenseKey: string | null | undefined
): PaginationHook<T> => {
  const [state, dispatch] = useReducer(reducer<T>(), defaultState());
  const [client, setClient] = useState<Client>();

  useEffect(() => {
    if (typesenseKey) {
      setClient(
        new Client({
          nodes: [
            {
              host: process.env.REACT_APP_TYPESENSE_HOST!,
              port: +process.env.REACT_APP_TYPESENSE_PORT!,
              protocol: 'https',
            },
          ],
          apiKey: typesenseKey,
          connectionTimeoutSeconds: 60,
        })
      );
    }
  }, [typesenseKey]);

  useEffect(() => {
    reset();
  }, [q, pageSize]);

  useEffect(() => {
    if (!state.query || !client) {
      return;
    }
    client
      .collections<T>(collection)
      .documents()
      .search(state.query)
      .then(result => setFirst(result, pageSize))
      .catch(setError);
  }, [state.query, pageSize, collection]);

  const reset = () => {
    dispatch({ type: 'reset' });
    if (!q) {
      return;
    }
    let newQuery = q;
    if (pageSize > 0) {
      newQuery = {
        q: q.q,
        query_by: q.query_by,
        filter_by: q.filter_by,
        page: 1,
      };
    }
    dispatch({ type: 'query', query: newQuery });
  };

  const setMore = (result: SearchResponse<T>, setMorePageSize: number): void => {
    dispatch({
      type: 'more',
      values: result.hits,
      lastDoc: setMorePageSize * (result.page - 1) + result.hits!.length,
      hasMore: !(result.found - pageSize * (result.page - 1) + result.hits!.length <= 0),
    });
  };

  const setFirst = (result: SearchResponse<T>, setFirstPageSize: number): void => {
    dispatch({
      type: 'first',
      values: result.hits,
      lastDoc: setFirstPageSize * (result.page - 1) + result.hits!.length,
      hasMore: !(result.found - setFirstPageSize * (result.page - 1) + result.hits!.length <= 0),
    });
  };

  const setError = (error: string): void => {
    dispatch({ type: 'error', error });
  };

  const load = useCallback(() => {
    dispatch({ type: 'loading', loading: true });
    if (!state.query || !state.lastDoc || !state.hasMore || !client) {
      dispatch({ type: 'loading', loading: false });
      return;
    }

    client
      .collections<T>(collection)
      .documents()
      .search({
        ...state.query,
        page: state.query?.page ? state.query.page + 1 : 2,
      })
      .then(result => setMore(result, pageSize))
      .catch(setError);
  }, [state.hasMore, state.lastDoc, pageSize, state.query, collection]);

  return useMemo(
    () => ({
      error: state.error,
      hasMore: state.hasMore,
      load,
      loading: state.loading,
      reset,
      values: state.values,
    }),
    [state.error, state.hasMore, state.loading, state.values, load]
  );
};
