interface HeaderProps {
  name: string;
  headerData?: any[];
  dateRange: string;
}

const Header: React.FC<HeaderProps> = ({ name, headerData, dateRange }) => (
  <>
    <div className="text-center font-outfit font-medium bg-secondary text-primary py-2">
      <span className="text-xl">
        {name} {dateRange}
      </span>
    </div>
    <div>
      <table className="table-auto min-w-full border-secondary border mb-5">
        <thead className="border-b leading-8">
          <tr>
            {headerData?.map(data => (
              <th key={data.name}>{data.name}</th>
            ))}
          </tr>
        </thead>
        <tbody className="text-center leading-8 font-nunito font-semibold">
          <tr>
            {headerData?.map(data => (
              <td key={data.name}>{data.value}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  </>
);

export default Header;
