import { useEffect } from 'react';
import { toast } from 'react-toastify';
import useGroups from '../../../../common/hooks/useGroups';
import { List } from '../../../../components/List';
import GroupItem from '../components/GroupItem';

const GroupsList = () => {
  const { error, hasMore, load, loading, groups } = useGroups({ pageSize: 100 });

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    console.log('GroupsList', groups);
  }, [groups]);

  return (
    <div className="flex flex-col w-full pt-24">
      <List
        load={load}
        hasMore={hasMore}
        loading={loading}
        className="overflow-y-scroll no-scrollbar w-full md:w-full"
      >
        {groups && groups.map(group => <GroupItem key={group.id} group={group as any} />)}
      </List>
    </div>
  );
};

export default GroupsList;
