import clsx from 'clsx';
import { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import ReactSelect, { ActionMeta, StylesConfig } from 'react-select';

export interface SelectOption {
  label: string;
  value: number | string;
}

interface SelectProps {
  id?: string;
  label?: string;
  name: string;
  options: SelectOption[];
  defaultValue?: SelectOption[];
  control: Control<any, object>;
  placeholder: string;
  rules: any;
  isMulti?: boolean;
  clearable?: boolean;
  customOnChange?: ((newValue: any, actionMeta: ActionMeta<any>) => void) | undefined;
  className?: string;
  styles?: StylesConfig;
}

const Select: React.FC<SelectProps> = ({
  id,
  label,
  name,
  options,
  defaultValue,
  control,
  placeholder,
  rules,
  isMulti = false,
  clearable = false,
  customOnChange,
  className,
  styles,
}) => {
  const selectOptions = useMemo(
    () => [...(clearable ? [{ label: 'Clear', value: '' }] : []), ...options],
    [clearable, options]
  );

  const containerStyles = useMemo(() => clsx('', className), [className]);

  return (
    <div className={containerStyles}>
      {label && <label htmlFor={name}>{label}</label>}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { value, onChange, onBlur } }) => (
          <ReactSelect
            id={id}
            options={selectOptions}
            placeholder={placeholder}
            isMulti={isMulti}
            onChange={(newValue, actionMeta) => {
              onChange(newValue);
              if (customOnChange) {
                customOnChange(newValue, actionMeta);
              }
            }}
            onBlur={onBlur}
            value={value}
            defaultValue={defaultValue}
            styles={styles}
          />
        )}
      />
    </div>
  );
};

export default Select;
