import { useState, useEffect, useMemo } from 'react';
import { collection, query, where } from 'firebase/firestore';
import dayjs from 'dayjs';
import { firestore } from '../utils/firebase';
import { bagsToTons } from '../utils/analytics/helperFunctions';
import getAnalyticsData from '../utils/analytics/getAnalyticsData';

interface UseAnalyticsOptions {
  dateRange1: {
    startDate: Date;
    endDate: Date;
  };
  dateRange2: {
    startDate: Date;
    endDate: Date;
  };
  groupBy: 'region' | 'territory' | 'cluster' | 'fst';
  type: 'fsa' | 'tae';
  regions?: Map<string, string>;
  territories?: Map<string, string>;
  clusters?: Map<string, string>;
}

const useAnalytics = <T>({
  type,
  dateRange1,
  dateRange2,
  groupBy,
  regions,
  territories,
  clusters,
}: UseAnalyticsOptions) => {
  const [analyticsData, setAnalyticsData] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();

  const dateRange1EndDatePlusOne = dayjs(dateRange1.endDate).add(1, 'day').startOf('day').toDate();
  const dateRange2EndDatePlusOne = dayjs(dateRange2.endDate).add(1, 'day').startOf('day').toDate();

  const docRef = collection(firestore, 'analytics');

  const docType = useMemo(
    () =>
      groupBy.toLowerCase() === 'region' ||
      groupBy.toLowerCase() === 'territory' ||
      groupBy.toLowerCase() === 'cluster'
        ? 'cluster'
        : type,
    [groupBy, type]
  );
  useEffect(() => {
    setLoading(true);

    let regionsArray: string[] = [];
    let territoriesArray: string[] = [];
    let clustersArray: string[] = [];
    if (regions) {
      regionsArray = Array.from(regions.values());
    }
    if (territories) {
      territoriesArray = Array.from(territories.values());
    }
    if (clusters) {
      clustersArray = Array.from(clusters.values());
    }

    let analyticsQuery = query(
      docRef,
      where('type', '==', docType),
      where('date', '>=', dateRange1.startDate),
      where('date', '<', dateRange1EndDatePlusOne)
    );

    let analyticsQueryLastPeriod = query(
      docRef,
      where('type', '==', docType),
      where('date', '>=', dateRange2.startDate),
      where('date', '<', dateRange2EndDatePlusOne)
    );

    if (clustersArray.length > 0) {
      analyticsQuery = query(analyticsQuery, where('cluster.name', 'in', clustersArray));
      analyticsQueryLastPeriod = query(
        analyticsQueryLastPeriod,
        where('cluster.name', 'in', clustersArray)
      );
    } else if (territoriesArray.length > 0) {
      analyticsQuery = query(
        analyticsQuery,
        where('cluster.territory.name', 'in', territoriesArray)
      );
      analyticsQueryLastPeriod = query(
        analyticsQueryLastPeriod,
        where('cluster.territory.name', 'in', territoriesArray)
      );
    } else if (regionsArray.length > 0) {
      analyticsQuery = query(analyticsQuery, where('cluster.region.name', 'in', regionsArray));
      analyticsQueryLastPeriod = query(
        analyticsQueryLastPeriod,
        where('cluster.region.name', 'in', regionsArray)
      );
    }

    getAnalyticsData(analyticsQuery, analyticsQueryLastPeriod)
      .then(data => {
        if (data.length === 0) {
          throw new Error('No data found for the selected date range');
        }
        // for each document in data
        const tonsData = data.map(doc => {
          const tonsDoc = { ...doc };
          if (type === 'fsa') {
            tonsDoc.volumeFulfilled.app = bagsToTons(doc.volumeFulfilled.app);
            tonsDoc.volumeFulfilled.chatbot = bagsToTons(doc.volumeFulfilled.chatbot);
            tonsDoc.volumeFulfilled.distributor = bagsToTons(doc.volumeFulfilled.distributor);
          }
          if (type === 'tae') {
            tonsDoc.leadsCreated = bagsToTons(doc.leadsCreated);
            tonsDoc.leadsFulfilled = bagsToTons(doc.leadsFulfilled);
          }
          return tonsDoc;
        });
        setAnalyticsData(tonsData);
        setLoading(false);
      })
      .catch(err => {
        console.log('err', err);
        setError(err);
        setLoading(false);
      });
  }, [type, dateRange1, dateRange2, docType]);

  return useMemo(
    () => ({
      data: analyticsData,
      error,
      loading,
    }),
    [analyticsData, error, loading]
  );
};

export default useAnalytics;
