import { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useOnClickOutside } from '../common/hooks';

interface ModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  children?: JSX.Element | JSX.Element[] | string;
  block?: boolean;
}

const Modal: React.FC<ModalProps> = ({ open, setOpen, children, block = false }: ModalProps) => {
  const node = useRef<HTMLDivElement>(null);

  useOnClickOutside(node, () => setOpen(false), block);

  return open
    ? ReactDOM.createPortal(
        <div
          className="fixed z-40 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          data-testid="modal"
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            />
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <div
              ref={node}
              className="w-full md:min-w-min md:w-1/2 inline-block align-bottom bg-white rounded-md px-6 py-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            >
              <button
                type="button"
                name="close"
                onClick={() => setOpen(false)}
                className="absolute top-4 right-4 font-bold bg-secondary text-white rounded-full w-6 h-6 flex items-center justify-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              {children}
            </div>
          </div>
        </div>,
        document.body
      )
    : null;
};

export default Modal;
