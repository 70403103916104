import { collection, FirestoreError, onSnapshot } from 'firebase/firestore';
import { useEffect, useMemo } from 'react';
import { firestore, docToJSON } from '../utils/firebase';
import { useLoadingValue } from './utils';
import { ClusterType } from '../types/Cluster';

const useClusters = () => {
  const { error, loading, setLoading, setError, setValue, value } = useLoadingValue<
    ClusterType[] | null,
    FirestoreError
  >();

  useEffect(() => {
    setLoading(true);
    const ref = collection(firestore, 'clusters');
    const unsubscribe = onSnapshot(
      ref,
      snapshot => {
        setValue(snapshot.docs.map(doc => docToJSON(doc) as ClusterType));
        setLoading(false);
      },
      setError
    );

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return useMemo(
    () => ({
      clusters: value,
      error,
      loading,
    }),
    [value, loading, error]
  );
};

export default useClusters;
