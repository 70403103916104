import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSignOut } from '../../common/hooks/auth';
import { useUser } from '../../common/hooks';
import ConfirmTOS from '../../components/Form/ConfirmTOS';

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const { update, user, error } = useUser();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (user && !user.appTOSAccepted) {
      setShowConfirmation(true);
    }
  }, [user]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  const handleOnConfirm = () => {
    setShowConfirmation(false);
    update({ appTOSAccepted: true });
  };

  const handleOnCancel = async () => {
    setShowConfirmation(false);
    await signOut();
    navigate('/denied', { replace: true });
  };

  return (
    <div className="flex justify-center items-center flex-col py-16 w-full">
      {user && (
        <>
          <ConfirmTOS
            title="By using this application you consent to the collection and processing of your personal data in accordance with the Lafarge Africa privacy policy. "
            link="https://www.lafarge.com.ng/privacy-policy"
            open={showConfirmation}
            onConfirm={handleOnConfirm}
            onCancel={handleOnCancel}
            confirmText="Accept"
            cancelText="Logout"
          />
          <h1 className="text-xl text-secondary">
            Welcome back <span className="font-bold">{user.name}</span>
          </h1>
        </>
      )}
    </div>
  );
};

export default Home;
